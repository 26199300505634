import React from 'react';

export const TableIncomesSellSearch = ({data, handleAddProduct}) => {

    const dataActiveTrue = data.filter((e)=> e.is_active === true)

    return (
        <>
            {
                dataActiveTrue && dataActiveTrue?.map((dataR) => {
                    return (
                        <tr key={dataR.id} >
                            <th 
                                onClick={(e)=>{
                                    handleAddProduct(e, dataR)
                                }}
                                style={{cursor: 'pointer'}}
                            >
                                {dataR.name}
                            </th>
                        </tr>
                    )
                })
            }
        </>
    )
}
