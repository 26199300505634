import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Button from '../component/Button';
import { toast } from 'react-toastify';
import Logo from '../assets/img/logo_lg.png'
import LogoTXT from '../component/LogoTXT';

export default function CreateAccount() {
    const history = useHistory();
    const [wait, setWait] = useState(false)
    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({
        first_name: {
            bol: false,
            msg: ''
        },
        last_name: {
            bol: false,
            msg: ''
        },
        email: {
            bol: false,
            msg: ''
        },
        password: {
            bol: false,
            msg: ''
        }
    });

    const [secret, setSecret] = useState(true);

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).map(x => {
            if (x === "email" && state[x]) {
                if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(state[x])) {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                } else {
                    return err[x] = {
                        bol: true,
                        msg: 'Verifique el formato de su email'
                    }
                }
            }

            if(x === "password" && state[x]){
                // if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/i.test(state[x])) {
                if (state[x].length >= 6) {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                } else {
                    return err[x] = {
                        bol: true,
                        msg: 'Las contraseñas deben contener al menos 6 caracteres.'
                    }
                }
            }

            if (!state[x]) {
                return err[x] = {
                    bol: true,
                    msg: 'Este campo no puede estar vacío'
                }
            } else {
                setWait(true)
                return err[x] = {
                    bol: false,
                    msg: ''
                }
            }
        })

        setErrors(err)

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            const data = {
                cnpj: "11312312/3123-12",
                company: "Start7",
                email: state.email.trim(),
                confirm_email: state.email.trim(),
                password: state.password.trim(),
                confirm_password: state.password.trim(),
                first_name: state.first_name.trim(),
                last_name: state.last_name.trim(),
                username: state.email.trim()
            }

            fetch(
                `${process.env.REACT_APP_URL_API}/api/auth/register/`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(resp => resp.json())
                .then(json => {
                    if (Array.isArray(json.email)) {
                        setWait(false)
                        toast.error(json.email[0])
                    } else {
                        if(json.password) {
                            setWait(false)
                            toast.error(json.password[0], {
                                className: "Toastify__toast--error"
                            });
                        } else {
                            toast.success("Cuenta creada correctamente", {
                                className: "Toastify__toast--success"
                            });
                        }
                        fetch(`${process.env.REACT_APP_URL_API}/api/auth/login/`,
                            {
                                method: 'POST',
                                body: JSON.stringify({
                                    username: state.email,
                                    password: state.password
                                }),
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(resp => resp.json())
                            .then(json => {
                                setWait(false)
                                if (json.id) {
                                    localStorage.setItem("flor_emprendedora", json.token);
                                    if(localStorage.flor_emprendedora) {
                                        setTimeout(()=> {
                                            return history.push("/dashboard")
                                        }, 1000)
                                    }
                                }}
                            )
                    }
                })
        }
    }

    return (
        <>
            <div className="container brand-container">
                <Row>
                    <Col sm="12" className="d-flex width-75" style={{ margin: "auto" }}>
                        <div className="card" style={{ margin: "auto", borderRadius: ".5rem" }}>
                            <div className="card-body flex-space-around">
                                <div className="d-flex justify-content-evenly mb-1 align-items-center">
                                    {/* <img src={Logo} alt="logo" className="login-logo" /> */}
                                    <LogoTXT />
                                </div>
                                <div>
                                    <h4 className="card-title mb-1 text-sm-start fs-4">La aventura comienza aquí</h4>
                                    <p className="card-text mb-2 text-sm-start fs-6">¡Haga que su aplicación de administración sea fácil y divertida!</p>
                                </div>
                                <form noValidate onSubmit={e => onSubmit(e)} style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                    <div className="form-group">
                                        <label htmlFor="create-user-first_name" className="form-label fs-6">Nombre</label>
                                        <Button
                                            type={'text'}
                                            name={'first_name'}
                                            style={errors.first_name.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                            value={state.first_name}
                                            setValue={setState}
                                            state={state}
                                        />
                                        <div className={errors.first_name.bol ? "invalid-input" : "valid-input"}>
                                            {errors.first_name.msg}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="create-user-last_name" className="form-label fs-6">Apellido</label>
                                        <Button
                                            type={'text'}
                                            name={'last_name'}
                                            style={errors.last_name.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                            value={state.last_name}
                                            setValue={setState}
                                            state={state}
                                        />
                                        <div className={errors.last_name.bol ? "invalid-input" : "valid-input"}>
                                            {errors.last_name.msg}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="create-user-email" className="form-label fs-6">Correo electrónico</label>
                                        <Button
                                            type={'email'}
                                            name={'email'}
                                            style={errors.email.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                            value={state.email.toLocaleLowerCase()}
                                            setValue={setState}
                                            state={state}
                                        />
                                        <div className={errors.email.bol ? "invalid-input" : "valid-input"}>
                                            {errors.email.msg}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="login-password" className="form-label br-btn-tl br-btn-bl fs-6">Contraseña</label>
                                        </div>
                                        <div className="input-group-merge input-group">
                                            <Button
                                                type={secret ? 'password' : 'text'}
                                                name={'password'}
                                                style={
                                                    errors.password.bol ?
                                                        "form-control p-form btn-height-3 border-radius-btn-top-left border-radius-btn-btm-left border-right-none error-input" :
                                                        "form-control p-form btn-height-3 border-radius-btn-top-left border-radius-btn-btm-left border-right-none"
                                                }
                                                value={state.password}
                                                setValue={setState}
                                                state={state}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="cursor-pointer input-group-text border-left-none border-radius-btn-btm-right border-radius-btn-top-right border-left-none"
                                                    style={{ height: "100%", background: "transparent" }}
                                                    onClick={() => setSecret(!secret)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                        <circle cx="12" cy="12" r="3"></circle>
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className={errors.password.bol ? "invalid-input" : "valid-input"}>
                                                {errors.password.msg}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !wait
                                            ?<button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t">Agregar cuenta</button>
                                            :(
                                                <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t" disabled>
                                                    <div class="spinner-border text-light" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </button>
                                            )
                                    }
                                    <hr />
                                    <p className="ta-center mt-1">
                                        <span
                                            className={
                                                window.innerWidth < 992 ? "mr-25 fs-6" : "mr-25 fs-6"
                                            }
                                        >
                                            ¿Ya tienes una cuenta?
                                        </span>
                                        <Link to="/login">
                                            <span
                                                className={
                                                    window.innerWidth < 992 ? "color-primary fs-6" : "color-primary"
                                                }
                                            >
                                                Inicia sesión en su lugar
                                            </span>
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div >
        </>
    )
}