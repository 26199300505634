import React from 'react';
import { Col, Row, Alert } from 'reactstrap'
import { useSelector } from 'react-redux';


export default function FormSetting({ state, setState, disabled, onSubmit, setError, error }) {
    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    return (
        <>
            {/* <div className="card mb-1">
                <div className="card-body" style={{ padding: "1rem 2.5rem" }}>
                    <div className="card-title" style={{ margin: "0" }}>
                        <Col sm="12">
                            <h2 style={{ margin: "0" }}>
                                Configuración
                            </h2>
                        </Col>
                    </div>
                </div>
            </div> */}
            <div className="card mb-1">
                <div className="card-body">
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className="form-label fs-6">Márgen de recargo minorista</label>
                                <input
                                    type={'number'}
                                    name={'margin_surcharge'}
                                    className={error.margin_wholesaler_surcharge.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state?.margin_wholesaler_surcharge}
                                    onChange={e => {
                                        setState({
                                            ...state,
                                            margin_wholesaler_surcharge: e.target.value
                                        })
                                    }}
                                />
                                <div
                                    className={error.margin_wholesaler_surcharge.bol ?
                                        "invalid-input" :
                                        "valid-input"
                                    }
                                >
                                    {error.margin_wholesaler_surcharge.msg}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {!userIsPayment?.plan?.is_free ?
            <div className="card mb-1">
                <div className="card-body">
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className="form-label fs-6">Venta mayoristas</label>
                                <select
                                    name={'wholesale'}
                                    className={error.wholesale.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state?.wholesale}
                                    onChange={e => {
                                        setState({
                                            ...state,
                                            [e.target.name]: e.target.value
                                        })
                                    }}
                                >
                                    <option default value={undefined}>Seleccione una opción</option>
                                    <option value={true}>Sí</option>
                                    <option value={false}>No</option>
                                </select>
                                <div
                                    className={error.wholesale.bol ?
                                        "invalid-input" :
                                        "valid-input"
                                    }
                                >
                                    {error.wholesale.msg}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            : null}

            {!userIsPayment?.plan?.is_free ?
            <>
            {
                (state && (state.wholesale === "false" || state.wholesale === "Seleccione una opción" || state.wholesale === undefined))?null:(
                    <div className="card mb-1">
                        <div className="card-body">
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label className="form-label fs-6">Márgen de recargo mayorista</label>
                                        <input
                                            type={'number'}
                                            name={'margin_surcharge'}
                                            className={error.margin_surcharge.bol ?
                                                "form-control p-form  br-btn-t error-input" :
                                                "form-control p-form  br-btn-t"
                                            }
                                            value={state?.margin_surcharge}
                                            onChange={e => {
                                                setState({
                                                    ...state,
                                                    margin_surcharge: e.target.value
                                                })
                                            }}
                                        />
                                        <div
                                            className={error.margin_surcharge.bol ?
                                                "invalid-input" :
                                                "valid-input"
                                            }
                                        >
                                            {error.margin_surcharge.msg}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
            </>
            : null}
            <div className="card mb-1">
                <div className="card-body">

                    {userIsPayment?.plan?.is_free && state?.supplies == 'true' ?
                    <Row>
                        <Col>
                            <Alert color='danger' className='background-primary-50 text-white'>
                            La lista de insumos y proceso de producción se encuentra disponible en el plan PRO. Podés ir a Configuración para pasar de plan y empezar a ordenar tu números con toda la ayuda de la Calcu
                            </Alert>
                        </Col>
                    </Row>
                    : null}
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className="form-label fs-6">¿Produces tus productos?</label>
                                <select
                                    name={'supplies'}
                                    className={error.supplies.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state?.supplies}
                                    onChange={e => {
                                        setState({
                                            ...state,
                                            [e.target.name]: e.target.value
                                        })
                                    }}
                                >
                                    <option default value={undefined}>Seleccione una opción</option>
                                    <option value={true}>Sí</option>
                                    <option value={false}>No</option>
                                </select>
                                <div
                                    className={error.supplies.bol ?
                                        "invalid-input" :
                                        "valid-input"
                                    }
                                >
                                    {error.supplies.msg}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="card mb-1">
                <div className="card-body">
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className="form-label fs-6">Cantidad de sueldos adicionales</label>
                                <input
                                    type={'number'}
                                    name={'margin_surcharge'}
                                    className={error.additional_salaries.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state?.additional_salaries}
                                    onChange={e => {
                                        setState({
                                            ...state,
                                            additional_salaries: e.target.value
                                        })
                                    }}
                                />
                                <div
                                    className={error.additional_salaries.bol ?
                                        "invalid-input" :
                                        "valid-input"
                                    }
                                >
                                    {error.additional_salaries.msg}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="card mb-1">
                <div className="card-body">
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className="form-label fs-6">Impuesto (%)</label>
                                <input
                                    type={'number'}
                                    name={'margin_surcharge'}
                                    className={error.tax.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state?.tax}
                                    onChange={e => {
                                        setState({
                                            ...state,
                                            tax: parseFloat(e.target.value)
                                        })
                                    }}
                                />
                                <div
                                    className={error.tax.bol ?
                                        "invalid-input" :
                                        "valid-input"
                                    }
                                >
                                    {error.tax.msg}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Row className='mb-3 pt-3'>
                <Col></Col>
                <Col className='d-flex justify-content-end'>
                    {
                        !disabled ? (
                            <button
                                type="submit"
                                className="btn background-secondary  br-btn-t color-white"
                                onClick={e => {
                                    e.preventDefault()
                                    onSubmit(e)
                                }}
                            >
                                Guardar
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="btn background-secondary  br-btn-t color-white"
                                disabled={true}
                            >
                                <span className="spinner-border spinner-border-sm">
                                    <span className="sr-only"></span>
                                </span>
                            </button>
                        )
                    }
                </Col>
            </Row>
        </>
    )
}