import React, { useEffect, useState } from 'react';
import FormVendors from '../component/FormVendors';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import ModalComponent from '../component/Modal';
import Loading from '../component/Loading';

export default function EditVendor() {
    const { id } = useParams();
    const [state, setState] = useState();
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        material: {
            bol: false,
            msg: ''
        },
        address: {
            bol: false,
            msg: ''
        },
        neighborhood: {
            bol: false,
            msg: ''
        },
        city: {
            bol: false,
            msg: ''
        },
        telephone: {
            bol: false,
            msg: ''
        },
        method_shipping: {
            bol: false,
            msg: ''
        },
        method_payment: {
            bol: false,
            msg: ''
        },
        method_order: {
            bol: false,
            msg: ''
        }
    })

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).map(x => {
            if (x === "name") {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            const obj = {
                name: state.name,
                material: state.material,
                address: state.address,
                neighborhood: state.neighborhood,
                city: state.city,
                telephone: state.telephone,
                method_shipping: state.method_shipping,
                method_payment: state.method_payment,
                method_order: state.method_order
            }

            fetch(`${process.env.REACT_APP_URL_API}/api/providers/${id}/`, {
                'method': "PUT",
                'body': JSON.stringify(obj),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        setState(json)
                        toast.success('Proveedor modificado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                        history.push('/vendors');
                    } else {
                        toast.success(json.name, {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/${id}/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setState(json)
            })
    }, [id])

    const handleDelete = (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then((res) => {
                if(res.status === 204) {
                    toast.success('Proveedor eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                    history.push('/vendors')
                } else {
                    toast.success('No se puede eliminar este proveedor', {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }

    return (
        !state ? <Loading /> : (
            <>
                <ModalComponent
                    open={modal}
                    title={"Eliminar proveedor"}
                    body={"¿Esta segura de eliminar este proveedor?"}
                    setModal={setModal}
                    onDelete={handleDelete}
                />
                <FormVendors
                    title={"Editar proveedor"}
                    state={state}
                    setState={setState}
                    errors={errors}
                    back={'/vendors'}
                    onSubmit={onSubmit}
                    id={id}
                    setModal={setModal}
                />
            </>
        )
    )
}