import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from './Button';
import { useHistory, useParams } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add';
import { gastosPersonales, gastosComerciales, gastosTotales } from '../utils/functions'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import { Tooltip, InputGroup, Input as InputBT, Button as ButtonBT, Alert } from 'reactstrap';

export default function FormStep({ isStep, setIsStep, title, staticTitle, state, setState, errors, nextStep, currentStep, previousStep, setErrors, setModal, addInputPersonales, setGastosComerciales, addInputComerciales, save, labels, setCustomField, setModalDeleteCustomField }) {
    const history = useHistory();
    const { id } = useParams();

    const [help, setHelp] = React.useState('');
    const [editLabel, setEditLabel] = React.useState('');

    useEffect(() => { }, [addInputPersonales])

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).map(x => {
            if ((currentStep === 6 && (x === "horas_trabajo_dia" || x === "dias_trabajo")) || (currentStep === 7 && x === "sueldo_final")) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                }
            }
        })

        console.log('error', err)

        setErrors(err)

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            nextStep()
        }
    }

    const GASTOS_TOTALES = (gastosPersonales(state) + gastosComerciales(state))
    
    console.log('GASTOS_TOTALES', gastosPersonales(state), gastosComerciales(state))

    return (
        <div className="d-flex justify-content-center align-items-center margin-bottom-1">
            <Row style={{ width: "100%" }}>
                <Col sm="12" className="d-flex">
                    <div className="w-100">
                        {
                            false ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 color-primary mb-1 " style={{ height: "10%", }}>{'Bienvenida'}</h2>
                                        </Col>
                                        <Row>
                                            <Col sm="12" md="12" className="mb-1">
                                                <Col sm="12" style={{ minHeight: "100px", margin: "2rem" }}>
                                                    <p className="brand-text text-sm-start ta-center fs-3" style={{ height: "10%", marginBottom: "1.5rem" }}>Para comenzar debemos calcular tu sueldo emprendedor. ¿Preferis hacerlo ahora o luego?</p>
                                                </Col>

                                                {isStep ?
                                                <Row style={{ margin: "1rem 0" }}>
                                                    <Col>
                                                        <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={() => { history.push('/vendors') }}>Omitir</button>
                                                    </Col>
                                                    <Col className="d-flex justify-content-flex-end">
                                                        <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); nextStep() }}>Continuar</button>
                                                    </Col>
                                                </Row>
                                                : null}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ) : null
                        }

                        {parseInt(id) !== 1 ? <Alert color="info">Esto no es para calcular el sueldo de tus empleados, sino en el caso de tener un socio/a o compartir el emprendimiento. </Alert> : null}
                        
                        {state.finished && !isStep ?
                        <Row>
                            <Col lg="12" xl="6" className="mb-1">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <div className="margin-1">
                                            <span className="b-avatar-custom">
                                                <span className="fs-4">Sueldo final decidido</span>
                                            </span>
                                        </div>
                                        <div className="truncate">
                                            <h2 className="mb-25 font-weight-bolder">$ {parseFloat(state.sueldo_final).toFixed(2)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="12" xl="6" className="mb-1">
                                <div className="card text-center h-100">
                                    <div className="card-body">
                                        <div className="margin-1">
                                            <span className="b-avatar-custom">
                                                <span className="fs-4">Valor de tu hora de trabajo</span>
                                            </span>
                                        </div>
                                        <div className="truncate">
                                            <h2 className="mb-25 font-weight-bolder">$ {(state.sueldo_final / (state.dias_trabajo * state.horas_trabajo_dia * 4)).toFixed(2)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : null}
                        
                        {
                            !isStep || currentStep === 1 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>

                                    {state.finished ?
                                        <button 
                                            type="button" 
                                            className="btn btn-primary btn-height-3 br-btn-t mb-4"
                                            onClick={() => setIsStep(!isStep)}
                                        >
                                            {!isStep ? 'Ir al asistente para volver a calcular' : 'Ir al formato de lista'}
                                        </button>
                                    : null}

                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 color-primary mb-1" style={{ height: "10%" }}>{'Gastos Personales'}</h2>
                                            <h3 className="brand-text text-sm-start ta-center fs-6 color-primary mb-1">General</h3>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Alquiler</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_alquiler'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_alquiler}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Expensas</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_expensas'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_expensas}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Luz</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_luz'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_luz}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Gas</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_gas'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_gas}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Agua</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_agua'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_agua}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Cable</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_cable'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_cable}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Internet</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_internet'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_internet}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Celular</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_celular'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_celular}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Abl</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_abl'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_abl}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Medicina Prepaga</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_medicina_prepaga'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_medicina_prepaga}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Sesiones de terapia</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_sesiones_de_terapia'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_sesiones_de_terapia}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Gastos bancarios</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'gastos_personales_gastos_bancarios'}
                                                            style="form-control p-form btn-height-3 br-btn-t"
                                                            value={state.gastos_personales_gastos_bancarios}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col></Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <span style={{ fontSize: "small" }}>* Campo informativo</span>
                                                </Col>
                                            </Row>

                                            {isStep ?
                                            <Row>
                                                <Col>
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                                                </Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); nextStep() }}>Continuar</button>
                                                </Col>
                                            </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        }

                        {    
                            !isStep || currentStep === 2 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 color-primary mb-1" style={{ height: "10%", marginBottom: "1rem !important" }}>{'Transporte'}</h2>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Row>
                                                <Col sm="12">
                                                    <h2 className="brand-text text-sm-start ta-center fs-4" style={{ height: "10%", marginBottom: "1.5rem" }}>Transporte Privado</h2>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Patente</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'transporte_privado_patente'}
                                                            style={errors.transporte_privado_patente.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.transporte_privado_patente}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.transporte_privado_patente.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.transporte_privado_patente.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Seguro</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'transporte_privado_seguro'}
                                                            style={errors.transporte_privado_seguro.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.transporte_privado_seguro}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.transporte_privado_seguro.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.transporte_privado_seguro.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Combustible</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'transporte_privado_combustible'}
                                                            style={errors.transporte_privado_combustible.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.transporte_privado_combustible}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.transporte_privado_combustible.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.transporte_privado_combustible.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12">
                                                    <h2 className="brand-text text-sm-start ta-center fs-4 mb-1" style={{ height: "10%", margin: "1.5rem 0" }}>Transporte Público</h2>
                                                </Col>
                                                <Col sm="12" md="12">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Transporte Público</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'transporte_publico'}
                                                            style={errors.transporte_publico.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.transporte_publico}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.transporte_publico.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.transporte_publico.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col></Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <span style={{ fontSize: "small" }}>* Campo informativo</span>
                                                </Col>
                                            </Row>
                                            {isStep ?
                                            <Row>
                                                <Col>
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                                                </Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); nextStep() }}>Continuar</button>
                                                </Col>
                                            </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        }
                        
                        {
                            !isStep || currentStep === 3 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 color-primary " style={{ height: "10%", marginBottom: "1.5rem" }}>{'Educación'}</h2>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        {editLabel === 'educacion_escuela_infantil' ?
                                                            <InputGroup className='mb-1'>
                                                                <InputBT
                                                                    value={state.educacion_escuela_infantil_label}
                                                                    onChange={(e) => setState({ ...state, educacion_escuela_infantil_label: e.target.value })}
                                                                />
                                                                <ButtonBT 
                                                                    color='secondary'
                                                                    onClick={() => {
                                                                        setEditLabel('')
                                                                        if(state.educacion_escuela_infantil_label === '') {
                                                                            setState({ ...state, educacion_escuela_infantil_label: labels.educacion_escuela_infantil_label })
                                                                        }
                                                                    }}
                                                                >
                                                                    <CloseOutlined style={{ fontSize: "large", cursor: "pointer" }} />
                                                                </ButtonBT>
                                                        </InputGroup>
                                                        :
                                                            <label className="form-label fs-6">
                                                                {state.educacion_escuela_infantil_label !== '' ? state.educacion_escuela_infantil_label : labels.educacion_escuela_infantil_label } 
                                                                <EditOutlined 
                                                                    style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                    onClick={() => setEditLabel('educacion_escuela_infantil')}
                                                                />
                                                            </label>
                                                        }

                                                        <Button
                                                            type={'number'}
                                                            name={'educacion_escuela_infantil'}
                                                            style={errors.educacion_escuela_infantil.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.educacion_escuela_infantil}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.educacion_escuela_infantil.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.educacion_escuela_infantil.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        {editLabel === 'educacion_actividad_extracurricular1' ?
                                                            <InputGroup className='mb-1'>
                                                                <InputBT
                                                                    value={state.educacion_actividad_extracurricular1_label}
                                                                    onChange={(e) => setState({ ...state, educacion_actividad_extracurricular1_label: e.target.value })}
                                                                />
                                                                <ButtonBT 
                                                                    color='secondary'
                                                                    onClick={() => {
                                                                        setEditLabel('')
                                                                        if(state.educacion_actividad_extracurricular1_label === '') {
                                                                            setState({ ...state, educacion_actividad_extracurricular1_label: labels.educacion_actividad_extracurricular1_label })
                                                                        }
                                                                    }}
                                                                >
                                                                    <CloseOutlined style={{ fontSize: "large", cursor: "pointer" }} />
                                                                </ButtonBT>
                                                        </InputGroup>
                                                        :
                                                            <label className="form-label fs-6">
                                                                {state.educacion_actividad_extracurricular1_label !== '' ? state.educacion_actividad_extracurricular1_label : labels.educacion_actividad_extracurricular1_label } 
                                                                <EditOutlined 
                                                                    style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                    onClick={() => setEditLabel('educacion_actividad_extracurricular1')}
                                                                />
                                                            </label>
                                                        }

                                                        <Button
                                                            type={'number'}
                                                            name={'educacion_actividad_extracurricular1'}
                                                            style={errors.educacion_actividad_extracurricular1.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.educacion_actividad_extracurricular1}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.educacion_actividad_extracurricular1.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.educacion_actividad_extracurricular1.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        {editLabel === 'educacion_actividad_extracurricular2' ?
                                                            <InputGroup className='mb-1'>
                                                                <InputBT
                                                                    value={state.educacion_actividad_extracurricular2_label}
                                                                    onChange={(e) => setState({ ...state, educacion_actividad_extracurricular2_label: e.target.value })}
                                                                />
                                                                <ButtonBT 
                                                                    color='secondary'
                                                                    onClick={() => {
                                                                        setEditLabel('')
                                                                        if(state.educacion_actividad_extracurricular2_label === '') {
                                                                            setState({ ...state, educacion_actividad_extracurricular2_label: labels.educacion_actividad_extracurricular2_label })
                                                                        }
                                                                    }}
                                                                >
                                                                    <CloseOutlined style={{ fontSize: "large", cursor: "pointer" }} />
                                                                </ButtonBT>
                                                        </InputGroup>
                                                        :
                                                            <label className="form-label fs-6">
                                                                {state.educacion_actividad_extracurricular2_label !== '' ? state.educacion_actividad_extracurricular2_label : labels.educacion_actividad_extracurricular2_label } 
                                                                <EditOutlined 
                                                                    style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                    onClick={() => setEditLabel('educacion_actividad_extracurricular2')}
                                                                />
                                                            </label>
                                                        }

                                                        <Button
                                                            type={'number'}
                                                            name={'educacion_actividad_extracurricular2'}
                                                            style={errors.educacion_actividad_extracurricular2.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.educacion_actividad_extracurricular2}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.educacion_actividad_extracurricular2.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.educacion_actividad_extracurricular2.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        {editLabel === 'educacion_cursos' ?
                                                            <InputGroup className='mb-1'>
                                                                <InputBT
                                                                    value={state.educacion_cursos_label}
                                                                    onChange={(e) => setState({ ...state, educacion_cursos_label: e.target.value })}
                                                                />
                                                                <ButtonBT 
                                                                    color='secondary'
                                                                    onClick={() => {
                                                                        setEditLabel('')
                                                                        if(state.educacion_cursos_label === '') {
                                                                            setState({ ...state, educacion_cursos_label: labels.educacion_cursos_label })
                                                                        }
                                                                    }}
                                                                >
                                                                    <CloseOutlined style={{ fontSize: "large", cursor: "pointer" }} />
                                                                </ButtonBT>
                                                        </InputGroup>
                                                        :
                                                            <label className="form-label fs-6">
                                                                {state.educacion_cursos_label !== '' ? state.educacion_cursos_label : labels.educacion_cursos_label } 
                                                                <EditOutlined 
                                                                    style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                    onClick={() => setEditLabel('educacion_cursos')}
                                                                />
                                                            </label>
                                                        }

                                                        <Button
                                                            type={'number'}
                                                            name={'educacion_cursos'}
                                                            style={errors.educacion_cursos.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.educacion_cursos}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.educacion_cursos.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.educacion_cursos.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                {Object.keys(state).map(field => {
                                                    let group = 'educacion'
                                                    let field_label = field + '_label'
                                                    let label_default = 'Opción ' + field.split('_option_')[1]
                                                    let label = state[field_label] ? state[field_label] : 'Opción ' + field.split('_option_')[1]
                                                    return (
                                                        field.includes(group + '_option_') && !field.includes('_label') ?
                                                            <Col sm="12" md="6">
                                                                <div className="form-group">
                                                                    {
                                                                        editLabel === field_label ?
                                                                            <InputGroup className='mb-1'>
                                                                                <InputBT
                                                                                    value={state[field_label]}
                                                                                    onChange={(e) => setState({ ...state, [field_label]: e.target.value })}
                                                                                />
                                                                                <ButtonBT 
                                                                                    color='secondary'
                                                                                    onClick={() => {
                                                                                        setEditLabel('')
                                                                                        if(state[field_label] === '') {
                                                                                            setState({ ...state, [field_label]: label_default })
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CloseOutlined style={{ fontSize: "large", cursor: "pointer" }} />
                                                                                </ButtonBT>
                                                                        </InputGroup>
                                                                        :
                                                                        <label className="form-label fs-6">
                                                                            {label}
                                                                            <EditOutlined 
                                                                                style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                                onClick={() => setEditLabel(field_label)}
                                                                            />
                                                                            <DeleteOutlined 
                                                                                style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                                onClick={() => setModalDeleteCustomField(field)}
                                                                            />
                                                                        </label>
                                                                    }
                                                                    <Button
                                                                        type={'number'}
                                                                        name={field}
                                                                        style={"form-control p-form btn-height-3 br-btn-t"}
                                                                        value={state[field]}
                                                                        setValue={setState}
                                                                        state={state}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        : null
                                                    )
                                                })}
                                            </Row>

                                            <Row>
                                                <Col sm="12">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6" style={{ visibility: "hidden" }}>?</label>
                                                        <button type="button" style={{ width: "50% !important", margin: "auto", display: "block" }} className="btn btn-primary btn-height-3 br-btn-t" onClick={() => setCustomField('educacion')}>
                                                            <AddIcon />
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                            <Row className="mb-1">
                                                <Col></Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <span style={{ fontSize: "small" }}>* Campo informativo</span>
                                                </Col>
                                            </Row>

                                            {isStep ?
                                            <Row>
                                                <Col>
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                                                </Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); nextStep() }}>Continuar</button>
                                                </Col>
                                            </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            // !isStep || currentStep === 4 ? (
                            //     <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                            //         <Col sm="12">
                            //             <h2 className="brand-text text-sm-start ta-center fs-1 color-primary " style={{ height: "10%", marginBottom: "1.5rem" }}>{'Gastos comerciales'}</h2>
                            //         </Col>
                            //         <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                            //             <Row>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Alquiler</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_alquiler'}
                            //                             style={errors.gastos_comerciales_alquiler.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_alquiler}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_alquiler.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_alquiler.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Expensas</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_expensas'}
                            //                             style={errors.gastos_comerciales_expensas.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_expensas}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_expensas.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_expensas.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Luz</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_luz'}
                            //                             style={errors.gastos_comerciales_luz.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_luz}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_luz.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_luz.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Gas</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_gas'}
                            //                             style={errors.gastos_comerciales_gas.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_gas}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_gas.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_gas.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Agua</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_agua'}
                            //                             style={errors.gastos_comerciales_agua.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_agua}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_agua.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_agua.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Cable</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_cable'}
                            //                             style={errors.gastos_comerciales_cable.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_cable}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_cable.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_cable.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Internet</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_internet'}
                            //                             style={errors.gastos_comerciales_internet.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_internet}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_internet.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_internet.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Celular</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_celular'}
                            //                             style={errors.gastos_comerciales_celular.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_celular}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_celular.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_celular.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Abl</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_abl'}
                            //                             style={errors.gastos_comerciales_abl.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_abl}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_abl.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_abl.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Deuda</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_deuda'}
                            //                             style={errors.gastos_comerciales_deuda.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_deuda}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_deuda.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_deuda.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Reinversion</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_reinversion'}
                            //                             style={errors.gastos_comerciales_reinversion.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_reinversion}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_reinversion.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_reinversion.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Sueldo de empleados</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_sueldo_empleados'}
                            //                             style={errors.gastos_comerciales_sueldo_empleados.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_sueldo_empleados}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_sueldo_empleados.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_sueldo_empleados.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Tienda online</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_tienda_online'}
                            //                             style={errors.gastos_comerciales_tienda_online.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_tienda_online}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_tienda_online.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_tienda_online.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //                 <Col sm="12" md="6">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6">Publicidad</label>
                            //                         <Button
                            //                             type={'number'}
                            //                             name={'gastos_comerciales_publicidad'}
                            //                             style={errors.gastos_comerciales_publicidad.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                            //                             value={state.gastos_comerciales_publicidad}
                            //                             setValue={setState}
                            //                             state={state}
                            //                         />
                            //                         <div className={errors.gastos_comerciales_publicidad.bol ? "invalid-input" : "valid-input"}>
                            //                             {errors.gastos_comerciales_publicidad.msg}
                            //                         </div>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 {
                            //                     addInputComerciales.option_1.bol ? (
                            //                         <Col sm="12" md="6">
                            //                             <div className="form-group">
                            //                                 <label className="form-label fs-6">{addInputComerciales.option_1.name}</label>
                            //                                 <button
                            //                                     type={'number'}
                            //                                     name={'gastos_personales_option_1'}
                            //                                     className="form-control p-form btn-height-3 br-btn-t"
                            //                                     value={state.gastos_personales_option_1}
                            //                                     onChange={(e) => {
                            //                                         setState({
                            //                                             ...state,
                            //                                             [e.target.name]: e.target.value
                            //                                         })
                            //                                     }}
                            //                                 />
                            //                             </div>
                            //                         </Col>
                            //                     ) : null
                            //                 }
                            //                 {
                            //                     addInputComerciales.option_2.bol ? (
                            //                         <Col sm="12" md="6">
                            //                             <div className="form-group">
                            //                                 <label className="form-label fs-6">{addInputComerciales.option_2.name}</label>
                            //                                 <button
                            //                                     type={'number'}
                            //                                     name={'gastos_personales_option_2'}
                            //                                     className="form-control p-form btn-height-3 br-btn-t"
                            //                                     value={state.gastos_personales_option_2}
                            //                                     onChange={(e) => {
                            //                                         setState({
                            //                                             ...state,
                            //                                             [e.target.name]: e.target.value
                            //                                         })
                            //                                     }}
                            //                                 />
                            //                             </div>
                            //                         </Col>
                            //                     ) : null
                            //                 }
                            //                 {
                            //                     addInputComerciales.option_3.bol ? (
                            //                         <Col sm="12" md="6">
                            //                             <div className="form-group">
                            //                                 <label className="form-label fs-6">{addInputComerciales.option_3.name}</label>
                            //                                 <button
                            //                                     type={'number'}
                            //                                     name={'gastos_personales_option_3'}
                            //                                     className="form-control p-form btn-height-3 br-btn-t"
                            //                                     value={state.gastos_personales_option_3}
                            //                                     onChange={(e) => {
                            //                                         setState({
                            //                                             ...state,
                            //                                             [e.target.name]: e.target.value
                            //                                         })
                            //                                     }}
                            //                                 />
                            //                             </div>
                            //                         </Col>
                            //                     ) : null
                            //                 }
                            //                 {
                            //                     addInputComerciales.option_4.bol ? (
                            //                         <Col sm="12" md="6">
                            //                             <div className="form-group">
                            //                                 <label className="form-label fs-6">{addInputComerciales.option_4.name}</label>
                            //                                 <button
                            //                                     type={'number'}
                            //                                     name={'gastos_personales_option_4'}
                            //                                     className={"form-control p-form btn-height-3 br-btn-t"}
                            //                                     value={state.gastos_personales_option_4}
                            //                                     onChange={(e) => {
                            //                                         setState({
                            //                                             ...state,
                            //                                             [e.target.name]: e.target.value
                            //                                         })
                            //                                     }}
                            //                                 />
                            //                             </div>
                            //                         </Col>
                            //                     ) : null
                            //                 }
                            //                 {
                            //                     addInputComerciales.option_5.bol ? (
                            //                         <Col sm="12" md="6">
                            //                             <div className="form-group">
                            //                                 <label className="form-label fs-6">{addInputComerciales.option_5.name}</label>
                            //                                 <button
                            //                                     type={'number'}
                            //                                     name={'gastos_personales_option_5'}
                            //                                     className={"form-control p-form btn-height-3 br-btn-t"}
                            //                                     value={state.gastos_personales_option_5}
                            //                                     onChange={(e) => {
                            //                                         setState({
                            //                                             ...state,
                            //                                             [e.target.name]: e.target.value
                            //                                         })
                            //                                     }}
                            //                                 />
                            //                             </div>
                            //                         </Col>
                            //                     ) : null
                            //                 }
                            //                 {
                            //                     addInputComerciales.option_6.bol ? (
                            //                         <Col sm="12" md="6">
                            //                             <div className="form-group">
                            //                                 <label className="form-label fs-6">{addInputComerciales.option_6.name}</label>
                            //                                 <button
                            //                                     type={'number'}
                            //                                     name={'gastos_personales_option_6'}
                            //                                     className="form-control p-form btn-height-3 br-btn-t"
                            //                                     value={state.gastos_personales_option_6}
                            //                                     onChange={(e) => {
                            //                                         setState({
                            //                                             ...state,
                            //                                             [e.target.name]: e.target.value
                            //                                         })
                            //                                     }}
                            //                                 />
                            //                             </div>
                            //                         </Col>
                            //                     ) : null
                            //                 }
                            //             </Row>
                            //             <Row>
                            //                 <Col sm="12">
                            //                     <div className="form-group">
                            //                         <label className="form-label fs-6" style={{ visibility: "hidden" }}>?</label>
                            //                         <button type="button" style={{ width: "50% !important", margin: "auto", display: "block" }} className="btn btn-primary btn-height-3 br-btn-t" onClick={() => setGastosComerciales(true)}>
                            //                             <AddIcon />
                            //                         </button>
                            //                     </div>
                            //                 </Col>
                            //             </Row>
                            //             <Row className="mb-1">
                            //                 <Col></Col>
                            //                 <Col className="d-flex justify-content-flex-end">
                            //                     <span style={{ fontSize: "small" }}>* Campo informativo</span>
                            //                 </Col>
                            //             </Row>
                            //             <Row>
                            //                 <Col>
                            //                     <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                            //                 </Col>
                            //                 <Col className="d-flex justify-content-flex-end">
                            //                     <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); nextStep() }}>Continuar</button>
                            //                 </Col>
                            //             </Row>
                            //         </form>
                            //     </div>
                            // ) 
                        }

                        {
                            !isStep || currentStep === 4 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 color-primary " style={{ height: "10%", marginBottom: "1.5rem" }}>{'Consumo'}</h2>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Comida</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'consumo_comida'}
                                                            style={errors.consumo_comida.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.consumo_comida}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.consumo_comida.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.consumo_comida.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Alimento de mascotas</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'consumo_alimentos_mascota'}
                                                            style={errors.consumo_alimentos_mascota.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.consumo_alimentos_mascota}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.consumo_alimentos_mascota.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.consumo_alimentos_mascota.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Artículos de limpieza</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'consumo_articulos_limpieza'}
                                                            style={errors.consumo_articulos_limpieza.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.consumo_articulos_limpieza}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.consumo_articulos_limpieza.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.consumo_articulos_limpieza.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col></Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <span style={{ fontSize: "small" }}>* Campo informativo</span>
                                                </Col>
                                            </Row>

                                            {isStep ?
                                                <Row>
                                                    <Col>
                                                        <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                                                    </Col>
                                                    <Col className="d-flex justify-content-flex-end">
                                                        <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); nextStep() }}>Continuar</button>
                                                    </Col>
                                                </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        }

                        {   
                            !isStep || currentStep === 5 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 color-primary " style={{ height: "10%", marginBottom: "1.5rem" }}>{'Extras'}</h2>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Deudas o prestamos</label>
                                                        <HelpOutlineIcon
                                                            disabled
                                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                                            onMouseEnter={() => {
                                                                setHelp('extras_deuda')
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHelp('')
                                                            }}
                                                            id="extras_deuda"
                                                        />
                                                        <Tooltip placement="right" isOpen={help === "extras_deuda"} target="extras_deuda">
                                                        Es la cuota mensual a pagar
                                                        </Tooltip>

                                                        <Button
                                                            type={'number'}
                                                            name={'extras_deuda'}
                                                            style={errors.extras_deuda.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.extras_deuda}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.extras_deuda.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.extras_deuda.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Fondo de reservas</label>
                                                        <HelpOutlineIcon
                                                            disabled
                                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                                            onMouseEnter={() => {
                                                                setHelp('extras_fondo_reserva')
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHelp('')
                                                            }}
                                                            id="extras_fondo_reserva"
                                                        />
                                                        <Tooltip placement="right" isOpen={help === "extras_fondo_reserva"} target="extras_fondo_reserva">
                                                        Es lo que querés destinar como reserva cada mes
                                                        </Tooltip>

                                                        <Button
                                                            type={'number'}
                                                            name={'extras_fondo_reserva'}
                                                            style={errors.extras_fondo_reserva.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.extras_fondo_reserva}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.extras_fondo_reserva.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.extras_fondo_reserva.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Ahorros</label>
                                                        <HelpOutlineIcon
                                                            disabled
                                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                                            onMouseEnter={() => {
                                                                setHelp('extras_ahorros')
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHelp('')
                                                            }}
                                                            id="extras_ahorros"
                                                        />
                                                        <Tooltip placement="right" isOpen={help === "extras_ahorros"} target="extras_ahorros">
                                                            Esto es lo que querés ahorrar por mes
                                                        </Tooltip>

                                                        <Button
                                                            type={'number'}
                                                            name={'extras_ahorros'}
                                                            style={errors.extras_ahorros.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.extras_ahorros}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.extras_ahorros.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.extras_ahorros.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Esparcimiento o salidas</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'extras_esparcimiento_o_salidas'}
                                                            style={errors.extras_esparcimiento_o_salidas.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.extras_esparcimiento_o_salidas}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.extras_esparcimiento_o_salidas.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.extras_esparcimiento_o_salidas.msg}
                                                        </div>
                                                    </div>
                                                </Col>

                                                {Object.keys(state).map(field => {
                                                    let field_label = field + '_label'
                                                    let label_default = 'Opción ' + field.split('_option_')[1]
                                                    let label = state[field_label] ? state[field_label] : 'Opción ' + field.split('_option_')[1]
                                                    return (
                                                        field.includes('extras_option_') && !field.includes('_label') ?
                                                            <Col sm="12" md="6">
                                                                <div className="form-group">
                                                                    {
                                                                        editLabel === field_label ?
                                                                            <InputGroup className='mb-1'>
                                                                                <InputBT
                                                                                    value={state[field_label]}
                                                                                    onChange={(e) => setState({ ...state, [field_label]: e.target.value })}
                                                                                />
                                                                                <ButtonBT 
                                                                                    color='secondary'
                                                                                    onClick={() => {
                                                                                        setEditLabel('')
                                                                                        if(state[field_label] === '') {
                                                                                            setState({ ...state, [field_label]: label_default })
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CloseOutlined style={{ fontSize: "large", cursor: "pointer" }} />
                                                                                </ButtonBT>
                                                                        </InputGroup>
                                                                        :
                                                                        <label className="form-label fs-6">
                                                                            {label}
                                                                            <EditOutlined 
                                                                                style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                                onClick={() => setEditLabel(field_label)}
                                                                            />
                                                                            <DeleteOutlined 
                                                                                style={{ marginLeft: "1rem", fontSize: "large", cursor: "pointer" }} 
                                                                                onClick={() => setModalDeleteCustomField(field)}
                                                                            />
                                                                        </label>
                                                                    }
                                                                    <Button
                                                                        type={'number'}
                                                                        name={field}
                                                                        style={"form-control p-form btn-height-3 br-btn-t"}
                                                                        value={state[field]}
                                                                        setValue={setState}
                                                                        state={state}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        : null
                                                    )
                                                })}

                                                {/* {
                                                    addInputPersonales.option_1.bol || (state.gastos_personales_option_1 && state.gastos_personales_option_1 !== '') ? (
                                                        <Col sm="12" md="6">
                                                            <div className="form-group">
                                                                <label className="form-label fs-6">{addInputPersonales.option_1.name}</label>
                                                                <input
                                                                    type={'number'}
                                                                    name={'gastos_personales_option_1'}
                                                                    className="form-control p-form btn-height-3 br-btn-t"
                                                                    value={state.gastos_personales_option_1}
                                                                    onChange={(e) => {
                                                                        setState({
                                                                            ...state,
                                                                            [e.target.name]: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ) : null
                                                }
                                                {
                                                    addInputPersonales.option_2.bol || (state.gastos_personales_option_2 && state.gastos_personales_option_2 !== '') ? (
                                                        <Col sm="12" md="6">
                                                            <div className="form-group">
                                                                <label className="form-label fs-6">{addInputPersonales.option_2.name}</label>
                                                                <input
                                                                    type={'number'}
                                                                    name={'gastos_personales_option_2'}
                                                                    className="form-control p-form btn-height-3 br-btn-t"
                                                                    value={state.gastos_personales_option_2}
                                                                    onChange={(e) => {
                                                                        setState({
                                                                            ...state,
                                                                            [e.target.name]: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ) : null
                                                }
                                                {
                                                    addInputPersonales.option_3.bol || (state.gastos_personales_option_3 && state.gastos_personales_option_3 !== '') ? (
                                                        <Col sm="12" md="6">
                                                            <div className="form-group">
                                                                <label className="form-label fs-6">{addInputPersonales.option_3.name}</label>
                                                                <input
                                                                    type={'number'}
                                                                    name={'gastos_personales_option_3'}
                                                                    className="form-control p-form btn-height-3 br-btn-t"
                                                                    value={state.gastos_personales_option_3}
                                                                    onChange={(e) => {
                                                                        setState({
                                                                            ...state,
                                                                            [e.target.name]: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ) : null
                                                }
                                                {
                                                    addInputPersonales.option_4.bol || (state.gastos_personales_option_4 && state.gastos_personales_option_4 !== '') ? (
                                                        <Col sm="12" md="6">
                                                            <div className="form-group">
                                                                <label className="form-label fs-6">{addInputPersonales.option_4.name}</label>
                                                                <input
                                                                    type={'number'}
                                                                    name={'gastos_personales_option_4'}
                                                                    className={"form-control p-form btn-height-3 br-btn-t"}
                                                                    value={state.gastos_personales_option_4}
                                                                    onChange={(e) => {
                                                                        setState({
                                                                            ...state,
                                                                            [e.target.name]: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ) : null
                                                }
                                                {
                                                    addInputPersonales.option_5.bol || (state.gastos_personales_option_5 && state.gastos_personales_option_5 !== '') ? (
                                                        <Col sm="12" md="6">
                                                            <div className="form-group">
                                                                <label className="form-label fs-6">{addInputPersonales.option_5.name}</label>
                                                                <input
                                                                    type={'number'}
                                                                    name={'gastos_personales_option_5'}
                                                                    className={"form-control p-form btn-height-3 br-btn-t"}
                                                                    value={state.gastos_personales_option_5}
                                                                    onChange={(e) => {
                                                                        setState({
                                                                            ...state,
                                                                            [e.target.name]: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ) : null
                                                }
                                                {
                                                    addInputPersonales.option_6.bol || (state.gastos_personales_option_6 && state.gastos_personales_option_6 !== '') ? (
                                                        <Col sm="12" md="6">
                                                            <div className="form-group">
                                                                <label className="form-label fs-6">{addInputPersonales.option_6.name}</label>
                                                                <input
                                                                    type={'number'}
                                                                    name={'gastos_personales_option_6'}
                                                                    className="form-control p-form btn-height-3 br-btn-t"
                                                                    value={state.gastos_personales_option_6}
                                                                    onChange={(e) => {
                                                                        setState({
                                                                            ...state,
                                                                            [e.target.name]: e.target.value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ) : null
                                                } */}
                                            </Row>

                                            {true ? 
                                            <Row>
                                                <Col sm="12">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6" style={{ visibility: "hidden" }}>?</label>
                                                        <button type="button" style={{ width: "50% !important", margin: "auto", display: "block" }} className="btn btn-primary btn-height-3 br-btn-t" onClick={() => setCustomField('extras')}>
                                                            <AddIcon />
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            : null}

                                            <Row className="mb-1">
                                                <Col></Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <span style={{ fontSize: "small" }}>* Campo informativo</span>
                                                </Col>
                                            </Row>

                                            {isStep ?
                                            <Row>
                                                <Col>
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                                                </Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); nextStep() }}>Continuar</button>
                                                </Col>
                                            </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        }
                            
                        {
                            !isStep || currentStep === 6 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 mb-1 color-primary " style={{ height: "10%" }}>{'Información Adicional'}</h2>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            {/* <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Sueldo promedio de mercado para tareas similares</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'sueldo_promedio'}
                                                            style={errors.sueldo_promedio.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.sueldo_promedio}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.sueldo_promedio.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.sueldo_promedio.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Sueldo final decidido</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'sueldo_final'}
                                                            style={errors.sueldo_final.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.sueldo_final}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.sueldo_final.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.sueldo_final.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Horas de trabajo al día</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'horas_trabajo_dia'}
                                                            style={errors.horas_trabajo_dia.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.horas_trabajo_dia}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.horas_trabajo_dia.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.horas_trabajo_dia.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Días de trabajo por semana</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'dias_trabajo'}
                                                            style={errors.dias_trabajo.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.dias_trabajo}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.dias_trabajo.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.dias_trabajo.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col sm="12">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Gastos Totales</label>
                                                        <input
                                                            type={'number'}
                                                            name={'horas_trabajo_dia'}
                                                            className={"form-control p-form btn-height-3 br-btn-t"}
                                                            value={(gastosPersonales(state) + gastosComerciales(state)).toFixed(2)}
                                                            readOnly
                                                        />
                                                        <div className={errors.horas_trabajo_dia.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.horas_trabajo_dia.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <Row className="mb-1">
                                                <Col></Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <span style={{ fontSize: "small" }}>* Campo informativo</span>
                                                </Col>
                                            </Row>

                                            {isStep ? 
                                            <Row>
                                                <Col>
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                                                </Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => onSubmit(e)}>Continuar</button>
                                                </Col>
                                            </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        } 

                        {   
                            !isStep || currentStep === 7 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 mb-1 color-primary " style={{ height: "10%" }}>{'Sueldo'}</h2>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            
                                            {parseFloat(state.sueldo_final) < GASTOS_TOTALES ? 
                                                <>
                                                    <div className='alert background-primary-50 text-white'>No se recomienda que tu sueldo sea menor que tus gastos</div> 
                                                    <ol className='text-primary' style={{fontSize: 14}}>
                                                        <li>Si estás empezando a emprender y tenés otro trabajo/ingreso podés manejarte de esta manera por un tiempo.</li>
                                                        <li>En caso de que vivas con otra persona puede que los gastos totales no te correspondan totalmente. Tené eso en cuenta a la hora de definir tu sueldo.</li>
                                                    </ol>
                                                </>
                                            : null}
                                            
                                            <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Sueldo promedio de mercado para tareas similares</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'sueldo_promedio'}
                                                            style={errors.sueldo_promedio.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.sueldo_promedio}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.sueldo_promedio.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.sueldo_promedio.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className={`form-label fs-6 ${parseFloat(state.sueldo_final) < GASTOS_TOTALES ? 'text-primary' : ''}`}>Sueldo final decidido</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'sueldo_final'}
                                                            style={errors.sueldo_final.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : `form-control p-form btn-height-3 br-btn-t ${parseFloat(state.sueldo_final) < GASTOS_TOTALES ? 'text-white font-weight-bolder' : ''}`}
                                                            value={state.sueldo_final}
                                                            setValue={setState}
                                                            state={state}
                                                            importantStyle={{backgroundColor: parseFloat(state.sueldo_final) < GASTOS_TOTALES ? 'rgb(218, 18, 117, 0.7)' : '#FFF'}}
                                                        />
                                                        <div className={errors.sueldo_final.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.sueldo_final.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Horas de trabajo al día</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'horas_trabajo_dia'}
                                                            style={errors.horas_trabajo_dia.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.horas_trabajo_dia}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.horas_trabajo_dia.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.horas_trabajo_dia.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Días de trabajo por semana</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'dias_trabajo'}
                                                            style={errors.dias_trabajo.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.dias_trabajo}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <div className={errors.dias_trabajo.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.dias_trabajo.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                {/* <Col sm="12" md="6">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Sueldo emprendedor adicional</label>
                                                        <Button
                                                            type={'number'}
                                                            name={'sueldo_adicional'}
                                                            style={errors.sueldo_adicional.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                                            value={state.sueldo_adicional}
                                                            setValue={setState}
                                                            state={state}
                                                        />
                                                        <small class="form-text text-muted">Esto no es para calcular el sueldo de tus empleados sino en el caso de tener un socio/a o compartir el empre</small>
                                                        <div className={errors.sueldo_adicional.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.sueldo_adicional.msg}
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                <Col sm="12" md="12">
                                                    <div className="form-group">
                                                        <label className="form-label fs-6">Gastos Totales</label>
                                                        <input
                                                            type={'number'}
                                                            name={'horas_trabajo_dia'}
                                                            className={"form-control p-form btn-height-3 br-btn-t"}
                                                            value={GASTOS_TOTALES.toFixed(2)}
                                                            readOnly
                                                        />
                                                        <div className={errors.horas_trabajo_dia.bol ? "invalid-input" : "valid-input"}>
                                                            {errors.horas_trabajo_dia.msg}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col></Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <span style={{ fontSize: "small" }}>* Campo informativo</span>
                                                </Col>
                                            </Row>

                                            {isStep ?
                                            <Row>
                                                <Col>
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => { e.preventDefault(); previousStep() }}>Volver</button>
                                                </Col>
                                                <Col className="d-flex justify-content-flex-end">
                                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={e => onSubmit(e)}>Ver resultados</button>
                                                </Col>
                                            </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            currentStep === 8 ? (
                                <div className="card mb-4" style={{ margin: "auto", borderRadius: ".5rem", width: "100%" }}>
                                    <div className="card-body flex-space-around" style={{ padding: "2.5rem" }}>
                                        <Col sm="12">
                                            <h2 className="brand-text text-sm-start ta-center fs-1 color-primary " style={{ height: "10%", marginBottom: "1.5rem" }}>{'Resultados'}</h2>
                                        </Col>
                                        <form style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                            <Row>
                                                <Row style={{ margin: 0 }}>
                                                    <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "1.5rem 0" }}>
                                                        <span className="b-avatar mb-1 badge-light-success rounded-circle" style={{ height: "45px", width: "45px" }}>
                                                            <span className="b-avatar-custom">
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    width="24"
                                                                    height="24"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="css-i6dzq1">
                                                                    <circle cx="12" cy="12" r="10"></circle>
                                                                    <polyline points="12 6 12 12 16 14"></polyline>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Col sm="12" md="6" className="mb-1">
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <div className="margin-1">
                                                                <span className="b-avatar-custom">
                                                                    <span className="fs-4">Horas de trabajo por semana</span>
                                                                </span>
                                                            </div>
                                                            <div className="truncate">
                                                                <h2 className="mb-25 font-weight-bolder">{parseFloat(state.dias_trabajo) * parseFloat(state.horas_trabajo_dia)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6" className="mb-1">
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <div className="margin-1">
                                                                <span className="b-avatar-custom">
                                                                    <span className="fs-4">Horas de trabajo por mes</span>
                                                                </span>
                                                            </div>
                                                            <div className="truncate">
                                                                <h2 className="mb-25 font-weight-bolder">{parseFloat(state.dias_trabajo) * parseFloat(state.horas_trabajo_dia) * 4}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "1.5rem 0" }}>
                                                    <span className="b-avatar badge-light-success rounded-circle" style={{ height: "45px", width: "45px" }}>
                                                        <span className="b-avatar-custom">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="feather feather-dollar-sign"
                                                            >
                                                                <line x1="12" y1="1" x2="12" y2="23"></line>
                                                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="6" className="mb-1">
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <div className="margin-1">
                                                                <span className="b-avatar-custom">
                                                                    <span className="fs-4">Gastos personales mensuales</span>
                                                                </span>
                                                            </div>
                                                            <div className="truncate">
                                                                <h2 className="mb-25 font-weight-bolder">$ {gastosPersonales(state).toFixed(2)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* <Col sm="12" md="6" className="mb-1">
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <div className="margin-1">
                                                                <span className="b-avatar-custom">
                                                                    <span className="fs-4">Gastos comerciales mensuales</span>
                                                                </span>
                                                            </div>
                                                            <div className="truncate">
                                                                <h2 className="mb-25 font-weight-bolder">$ {gastosComerciales(state).toFixed(2)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                {/* <Col sm="12" md="6" className="mb-1">
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <div className="margin-1">
                                                                <span className="b-avatar-custom">
                                                                    <span className="fs-4">Gastos fijos totales</span>
                                                                </span>
                                                            </div>
                                                            <div className="truncate">
                                                                <h2 className="mb-25 font-weight-bolder">$ {gastosTotales(state).toFixed(2)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                {/* <Col sm="12" md="6" className="mb-1">
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <div className="margin-1">
                                                                <span className="b-avatar-custom">
                                                                    <span className="fs-4">Proyección en los resultados de gastos</span>
                                                                </span>
                                                            </div>
                                                            <div className="truncate">
                                                                <h2 className="mb-25 font-weight-bolder">$ {parseFloat(state.sueldo_final).toFixed(2)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col> */}
                                            {/* </Row>
                                            <Row> */}
                                                <Col sm="12" md="6" className="mb-1" style={{ margin: "auto" }}>
                                                    <div className="card text-center">
                                                        <div className="card-body">
                                                            <div className="margin-1">
                                                                <span className="b-avatar-custom">
                                                                    <span className="fs-4">Valor de tu hora de trabajo</span>
                                                                </span>
                                                            </div>
                                                            <div className="truncate">
                                                                <h2 className="mb-25 font-weight-bolder">$ {(state.sueldo_final / (state.dias_trabajo * state.horas_trabajo_dia * 4)).toFixed(2)}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {isStep ?
                                            <Row>
                                                <Col sm="6" style={{ margin: "auto" }}>
                                                    <button type="button" style={{ margin: "auto" }} className="btn btn-primary btn-block btn-height-3 br-btn-t width-50-porcentaje" onClick={() => save(true)}>Finalizar</button>
                                                </Col>
                                            </Row>
                                            : null}
                                        </form>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </Col>
            </Row>
        </div >
    )
}
