import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';


export const FormInvested = ({ title, state, setModal, errors, setState, back, onSubmit, disabled }) => {
    const history = useHistory();
    const { id } = useParams();

    return (
        <>
            <div className={history.location.pathname.includes('products') ? null : "card mb-3"}>
                {
                    history.location.pathname.includes('products') ? (
                        null
                    ) : history.location.pathname.includes('view') ? null : (
                        <div className="card-header mt-1">
                            <div className="card-title">
                                    <h2>
                                        {title}
                                    </h2>
                            </div>
                        </div>
                    )
                }
                <div className="card-body pt-1">
                    <form>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label className="form-label fs-6">Fecha</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'date'}
                                                name={'date'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                value={state.date}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                type="date"
                                                name="date"
                                                value={state.date}
                                                className={errors.date.bol ?
                                                    "form-control p-form  br-btn-t error-input" :
                                                    "form-control p-form  br-btn-t"
                                                }
                                                format={'YYYY-MM-DD'}
                                                onChange={e => {
                                                    setState({
                                                        ...state,
                                                        date: e.target.value
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    <div
                                        className={errors.date.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors.date.msg}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div className={'form-group'}>
                                    <label className={'form-label fs-6'}>{'Concepto'}</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'text'}
                                                name={'concept'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                value={state.concept}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                name={'concept'}
                                                type={'text'}
                                                className={
                                                    errors.concept.bol ?
                                                        "form-control p-form  br-btn-t error-input" :
                                                        "form-control p-form  br-btn-t"
                                                    
                                                }
                                                value={state.concept}
                                                onChange={e => {
                                                    setState({
                                                        ...state,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    <div
                                        className={errors.concept.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors.concept.msg}
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12">
                                <div className={'form-group'}>
                                    <label className={'form-label fs-6'}>{'Importe'}</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'number'}
                                                name={'amount'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                value={state.amount}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                name={'amount'}
                                                type={'number'}
                                                className={errors.amount.bol ?
                                                    "form-control p-form  br-btn-t error-input" :
                                                    "form-control p-form  br-btn-t"
                                                }
                                                value={state.amount}
                                                onChange={e => {
                                                    setState({
                                                        ...state,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    <div
                                        className={errors.amount.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors.amount.msg}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12">
                                <div className="form-group">
                                    <label className="form-label fs-6">Detalles</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <textarea
                                                name={'details'}
                                                className={"btn-disabled form-control p-form btn-height-6 br-btn-t resize-none"}
                                                value={state.details}
                                            />
                                        ) : (
                                            <textarea
                                                name={'details'}
                                                className={"form-control p-form btn-height-6 br-btn-t resize-none"}
                                                value={state.details}
                                                onChange={e =>
                                                    setState({
                                                        ...state,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }
                                            />
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
            <Row className="mb-3" style={window.innerWidth > 991 ? null : { display: "flex" }}>
                <div
                    className={window.innerWidth > 991 ? "col" : null}
                    style={
                        history.location.pathname.includes('view') ?
                            { display: "flex", justifyContent: "space-between" } :
                            window.innerWidth < 992 ? { width: "75%" } :
                                null
                    }
                >
                    {
                        id ? (
                            <>
                                <Link to={back}>
                                    <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">Cancelar</button>
                                </Link>
                                <button
                                    className="btn btn-primary  br-btn-t color-white"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal(true)
                                    }}
                                >
                                    Borrar
                                </button>
                            </>
                        ) : null
                    }
                </div>
                {
                    history.location.pathname.includes('view') ?
                        null : (
                            <div
                                style={
                                    window.innerWidth > 991 ?
                                        null : { width: "25%" }
                                }
                                className={
                                    window.innerWidth > 991 ?
                                        "col d-flex justify-content-flex-end" :
                                        "d-flex justify-content-flex-end"
                                }
                            >
                                {
                                    history.location.pathname.includes('invested') ? (
                                        !id ? (
                                            <Link to={back}>
                                                <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">
                                                    {
                                                        history.location.pathname.includes('view') ?
                                                            'Volver' : 'Cancelar'
                                                    }
                                                </button>
                                            </Link>
                                        ) : null
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    !disabled ? (
                                        <button type="submit" className="btn background-secondary  br-btn-t color-white" onClick={e => {
                                            onSubmit(e);
                                        }}>Guardar</button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </div>
                        )
                }
            </Row>
        </>
    )
}
