import './App.css';
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './pages/Login'
import CreateAccount from './pages/createAccount';
import ResetPassword from './pages/resetPassword';
// import SettingInitial from './pages/settingInitial';
import AccountMsg from './pages/AccountMsg';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Container from './pages/Container';
import { NewPassword } from './pages/NewPassword';
import AppTour from './component/AppTour';

function App() {

  return (
    <>
      <Router>
        <ToastContainer />
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={CreateAccount} />
          <Route path="/forgot-password" component={ResetPassword} />
          <Route path="/new-password/:token" component={NewPassword} />
          <Route path="/account-created" exact component={AccountMsg} />
          {/* <Route path="/setting-initial" exact component={SettingInitial} /> */}
          <Route path="/" component={Container} />
        </Switch>
      </Router>

      <AppTour />
    </>
  );
}

export default App;