import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
// import { DateRange } from 'react-date-range';
// import { es } from 'react-date-range/src/locale/index'
import moment from 'moment';

export const ModalFilter = ({setShowFilter, showFilter, state, setState, data, setChangeData, filter, changeFilter, stateDateRange, setStateDateRange}) => {
    const [ date, setDate ] = useState(false)
    const [ pedide, setPedide ] = useState(false)
    const [ page, setPage ] = useState(false)
    const [ medio, setMedio ] = useState(false)

    const [showDate, setShowDate] = React.useState(false)
        
    return (
        <Modal className="modal-danger" show={showFilter} onHide={() => setShowFilter(false)}>
            <Modal.Header>
                <h5 className="modal-title color-primary">Filtrar por</h5>
                <button type="button" className="close color-primary" onClick={() => setShowFilter(false)}>
                    <span>×</span>
                </button>
            </Modal.Header>

            <Modal.Body>
                <div className='border-bottom mb-2' style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                    <button onClick={()=>setDate(!pedide)} className='border-none' style={{background:'white'}}>FILTRO POR FECHA </button>
                    {
                        (date === false)?null:(
                            <>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=entered') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=> setStateDateRange([
                                        {
                                          startDate: moment().toDate(),
                                          endDate: moment().toDate(),
                                          key: 'selection'
                                        }
                                    ])}
                                >
                                    Hoy
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=in-preparation') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=> setStateDateRange([
                                        {
                                          startDate: moment().startOf('week').toDate(),
                                          endDate: moment().toDate(),
                                          key: 'selection'
                                        }
                                    ])}
                                >
                                    Esta semana
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=ready-to-deliver') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=> setStateDateRange([
                                        {
                                          startDate: moment().startOf('month').toDate(),
                                          endDate: moment().toDate(),
                                          key: 'selection'
                                        }
                                    ])}
                                >
                                    Este mes
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=ready-to-deliver') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=> setStateDateRange([
                                        {
                                          startDate: moment().startOf('year').toDate(),
                                          endDate: moment().toDate(),
                                          key: 'selection'
                                        }
                                    ])}
                                >
                                    Este ano
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=delivered') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=> setShowDate(!showDate)}
                                >
                                    Personalizado
                                </p>
                                {/* {
                                    showDate ?
                                    <DateRange
                                        // editableDateInputs={true}
                                        onChange={item => setStateDateRange([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={stateDateRange}
                                        className="w-100"
                                        locale={es}
                                    />
                                    : null
                                } */}
                            </>
                        )
                    }
                </div>
                <div className='border-bottom mb-2' style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                    <button onClick={()=>setPedide(!pedide)} className='border-none' style={{background:'white'}}>ESTADO DEL PEDIDO </button>
                    {
                        (pedide === false)?null:(
                            <>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=entered') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.status_order === 'entered'))
                                        changeFilter('status_order', 'entered')
                                    }}
                                >
                                    Ingresado
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=in-preparation') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.status_order === 'in-preparation'))
                                        changeFilter('status_order', 'in-preparation')
                                    }}
                                >
                                    En preparación
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=ready-to-deliver') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.status_order === 'ready-to-deliver'))
                                        changeFilter('status_order', 'ready-to-deliver')
                                    }}
                                >
                                    Listo para entregar
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_order=delivered') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.status_order === 'delivered'))
                                        changeFilter('status_order', 'delivered')
                                    }}
                                >
                                    Entregado
                                </p>
                            </>
                        )
                    }
                </div>
                <div className='border-bottom mb-2'  style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                    <button onClick={()=>setPage(!page)} className='border-none' style={{background:'white'}}>ESTADO DEL PAGO </button>
                    {
                        (page === false)?null:(
                            <>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_payment=payment-pending') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.status_payment === 'payment-pending'))
                                        changeFilter('status_payment', 'payment-pending')
                                    }}
                                >
                                    Pago pendiente
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('status_payment=payment-approved') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.status_payment === 'payment-approved'))
                                        changeFilter('status_payment', 'payment-approved')
                                    }}
                                >
                                    Pago aprobado
                                </p>
                            </>
                        )
                    }
                </div>
                <div className='border-bottom mb-2' style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                    <button onClick={()=>setMedio(!medio)} className='border-none' style={{background:'white'}}>MEDIO DE PAGO </button>
                    {
                        (medio === false)?null:(
                            <>
                                <p 
                                    style={{color: filter.split('&').indexOf('method_payment=efectivo') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        setShowFilter(false)
                                        changeFilter('method_payment', 'efectivo')
                                    }}
                                >
                                    Efectivo
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('method_payment=mercado_pago') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.method_payment === 'mercado_pago'))
                                        changeFilter('method_payment', 'mercado_pago')
                                    }}
                                >
                                    Mercado Pago
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('method_payment=paypal') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        setShowFilter(false)
                                        changeFilter('method_payment', 'paypal')
                                    }}
                                >
                                    Paypal
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('method_payment=uala') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        setShowFilter(false)
                                        changeFilter('method_payment', 'uala')
                                    }}
                                >
                                    Ualá
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('method_payment=rapipago') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.method_payment === 'rapipago'))
                                        changeFilter('method_payment', 'rapipago')
                                    }}
                                >
                                    Rapipago
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('method_payment=transferencia') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        // setChangeData(true)
                                        setShowFilter(false)
                                        // setState(data.filter(e => e.method_payment === 'transferencia'))
                                        changeFilter('method_payment', 'transferencia')
                                    }}
                                >
                                    Transferencia bancaria
                                </p>
                                <p 
                                    style={{color: filter.split('&').indexOf('method_payment=otro') === -1 ? '#71cabf' : '#e15c93', cursor:'pointer'}} 
                                    onClick={()=>{
                                        setShowFilter(false)
                                        changeFilter('method_payment', 'otro')
                                    }}
                                >
                                    Otro
                                </p>
                            </>
                        )
                    }
                </div>
                <div  style={{display: 'flex', flexDirection:'column', width:'100%', justifyContent:'left', alignItems:'left'}}>
                    <button className='border-none' style={{background:'white', color:'#e15c93'}} onClick={()=> {
                        // setState(data)
                        // setChangeData(false)
                        setShowFilter(false)
                        changeFilter('', '', true)
                    }}>DESACTIVAR FILTRO</button>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button type="submit" className="btn btn-outline-primary btn-height-3 br-btn-t mr-1" onClick={() => setShowFilter(false)}>Aceptar</button>
                <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() => setShowFilter(false)}>Cancelar</button>
            </Modal.Footer>
        </Modal>
    )
}
