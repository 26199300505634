import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropdown from './DropDownMenu';

export default function TableVendors({ data, toEdit, setModal, modal, setId, setDeleteAll, setModalDelete, disabled }) {
    const [checkbox, setCheckbox] = React.useState({})
    const [all, setAll] = React.useState(false)


    React.useEffect(() => {
        setCheckbox({})
    }, [])

    const onChecked = (a) => {
        setCheckbox({
            ...checkbox,
            [a]: !checkbox[a]
        })
    }

    const handleDelete = () => {
        if (all) {
            const arr = []
            data.map(el => arr.push(el.id))
            setDeleteAll(arr)
        } else {
            setDeleteAll(Object.keys(checkbox).filter(x => checkbox[x] === true))
        }
        setModalDelete(true)
        return setCheckbox({})
    }

    return (
        <>
            <Row>
                <Col className="mb-1" sm="12">
                    <Table responsive className="align-middle">
                        <thead>
                            <tr>
                                <th className="text-align-center checkboxContainer">
                                    <label className="containerCheckbox">
                                        <input
                                            type="checkbox"
                                            className={
                                                Object.values(checkbox).filter(x => x === true).length === data.length
                                                ? "checked" : ""
                                            }
                                            onClick={() => {
                                                setAll(!all)
                                                const obj = {}
                                                data.map(el => {
                                                    return obj[el.id] = !all
                                                })
                                                setCheckbox(obj)
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                                <th>Proveedor</th>
                                <th>Material/Servicio</th>
                                {
                                    window.innerWidth > 991 ? (
                                        <>
                                            {/* <th>Dirección</th>
                                            <th>Barrio</th> */}
                                            <th>Ciudad</th>
                                            <th>Teléfono</th>
                                            <th>Envío</th>
                                            <th>Pago</th>
                                            <th>Encargo</th>
                                        </>
                                    ) : null
                                }
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((e, i = -1) => {
                                    i++
                                    return (
                                        <tr key={e.id} >
                                            <th className="text-align-center">
                                                {
                                                    checkbox[e.id] ? (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                className="checked"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    ) : (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                }
                                            </th>
                                            <th>
                                                <Link to={`/vendors/edit/${e.id}`}>
                                                    {e.name}
                                                </Link>
                                            </th>
                                            <th>{e.material || "-"}</th>
                                            {
                                                window.innerWidth > 991 ? (
                                                    <>
                                                        {/* <th>{e.address || "-"}</th>
                                                        <th>{e.neighborhood || "-"}</th> */}
                                                        <th>{e.city || "-"}</th>
                                                        <th>{e.telephone || "-"}</th>
                                                        <th>{e.method_shipping || "-"}</th>
                                                        <th>{e.method_payment || "-"}</th>
                                                        <th>{e.method_order || "-"}</th>
                                                    </>
                                                ) : null
                                            }
                                            <td className="text-end">
                                                <Dropdown setId={setId} toEdit={toEdit} e={e} setModal={setModal} modal={modal} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
                {
                    Object.values(checkbox).filter(x => x === true).length ? (
                        <Col>
                            <footer>
                                {
                                    !disabled ? (
                                        <button
                                            className="btn background-secondary br-btn-t color-white color-white"
                                            onClick={() => {
                                                setCheckbox({
                                                    all: false
                                                })
                                                handleDelete()
                                            }}
                                        >
                                            Eliminar seleccionados
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </footer>
                        </Col>
                    ) : null
                }
            </Row>
        </>
    )
}