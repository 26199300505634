import React from 'react';
import { Link } from 'react-router-dom'

export default function NavFormStep({ currentStep, goToStep, state, setErrors }) {
    const _goToStep = (step) => {
        const err = {}

        Object.keys(state).map(x => {
            if ((x === "horas_trabajo_dia" || x === "dias_trabajo") && currentStep == 6) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                }
            }
            if ((x === "sueldo_final") && currentStep == 7) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                }
            } 
            else {
                return err[x] = {
                    bol: false,
                    msg: ''
                }
            }
        })

        setErrors(err)

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            goToStep(step)
        }
    }
    
    return (
        <div className="form-step mb-3">
            <div className="wizard-navigation margin-auto" >
                <ul className="wizard-nav wizard-nav-pills d-flex align-items-center justify-content-sa padding-bottom-1">
                    {/* <li className={currentStep === 0 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(0)} className={currentStep === 0 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : "flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 0 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 0 ? { borderColor: "rgba(218, 18, 117,.6)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 0 ? { backgroundColor: "rgba(218, 18, 117,.6)" } : null}>
                                    <i className="wizard-icon">0</i>
                                </div>
                            </div>
                            <span className={currentStep === 0 ? "stepTitle active" : "stepTitle"} style={currentStep === 0 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Confirmación
                            </span>
                        </div>
                    </li> */}
                    <li className={currentStep === 1 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(1)} className={currentStep === 1 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : "flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 1 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 1 ? { borderColor: "rgba(218, 18, 117,.6)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 1 ? { backgroundColor: "rgba(218, 18, 117,.6)" } : null}>
                                    <i className="wizard-icon">1</i>
                                </div>
                            </div>
                            <span className={currentStep === 1 ? "stepTitle active" : "stepTitle"} style={currentStep === 1 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Gastos Personales
                            </span>
                        </div>
                    </li>
                    <li className={currentStep === 2 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(2)} className={currentStep === 2 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : "flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 2 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 2 ? { borderColor: "rgba(218, 18, 117,.6)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 2 ? { backgroundColor: "rgba(218, 18, 117,.6)" } : null}>
                                    <i className="wizard-icon">2</i>
                                </div>
                            </div>
                            <span className={currentStep === 2 ? "stepTitle active" : "stepTitle"} style={currentStep === 2 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Transporte
                            </span>
                        </div>
                    </li>
                    <li className={currentStep === 3 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(3)} className={currentStep === 3 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : "flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 3 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 3 ? { borderColor: "rgba(218, 18, 117,.6)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 3 ? { backgroundColor: "rgba(218, 18, 117,.6)" } : null}>
                                    <i className="wizard-icon">3</i>
                                </div>
                            </div>
                            <span className={currentStep === 3 ? "stepTitle active" : "stepTitle"} style={currentStep === 3 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Educación
                            </span>
                        </div>
                    </li>
                    {/* <li className={currentStep === 4 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(4)} className={currentStep === 4 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : "flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 4 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 4 ? { borderColor: "rgba(218, 18, 117,.6)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 4 ? { backgroundColor: "rgba(218, 18, 117,.6)" } : null}>
                                    <i className="wizard-icon">4</i>
                                </div>
                            </div>
                            <span className={currentStep === 4 ? "stepTitle active" : "stepTitle"} style={currentStep === 4 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Gastos Comerciales
                            </span>
                        </div>
                    </li> */}
                    <li className={currentStep === 4 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(4)} className={currentStep === 4 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : "flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 4 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 4 ? { borderColor: "rgba(218, 18, 117,.6)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 4 ? { backgroundColor: "rgba(218, 18, 117,.6)" } : null}>
                                    <i className="wizard-icon">4</i>
                                </div>
                            </div>
                            <span className={currentStep === 4 ? "stepTitle active" : "stepTitle"} style={currentStep === 5 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Consumo
                            </span>
                        </div>
                    </li>
                    <li className={currentStep === 5 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(5)} className={currentStep === 5 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : "flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 5 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 5 ? { borderColor: "rgba(218, 18, 117,.6)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 5 ? { backgroundColor: "rgba(218, 18, 117,.6)" } : null}>
                                    <i className="wizard-icon">5</i>
                                </div>
                            </div>
                            <span className={currentStep === 5 ? "stepTitle active" : "stepTitle"} style={currentStep === 5 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Extras
                            </span>
                        </div>
                    </li>
                    <li className={currentStep === 6 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(6)} className={currentStep === 6 ? "flex-direction-column  d-flex align-items-center justify-content-center no-cursor" : " flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 6 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 6 ? { borderColor: "rgb(218, 18, 118)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 6 ? { backgroundColor: "rgb(218, 18, 118)" } : null}>
                                    <i className="wizard-icon">6</i>
                                </div>
                            </div>
                            <span className={currentStep === 6 ? "stepTitle active" : "stepTitle"} style={currentStep === 6 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Información adicional
                            </span>
                        </div>
                    </li>
                    <li className={currentStep === 7 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(7)} className={currentStep === 7 ? "flex-direction-column  d-flex align-items-center justify-content-center no-cursor" : " flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 7 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 7 ? { borderColor: "rgb(218, 18, 118)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 7 ? { backgroundColor: "rgb(218, 18, 118)" } : null}>
                                    <i className="wizard-icon">7</i>
                                </div>
                            </div>
                            <span className={currentStep === 7 ? "stepTitle active" : "stepTitle"} style={currentStep === 6 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                            Sueldo
                            </span>
                        </div>
                    </li>
                    <li className={currentStep === 8 ? "active" : ""} step-size="md">
                        <div onClick={() => _goToStep(8)} className={currentStep === 8 ? "flex-direction-column d-flex align-items-center justify-content-center no-cursor" : " flex-direction-column d-flex align-items-center justify-content-center disabled no-cursor"}>
                            <div className={currentStep === 8 ? "wizard-icon-circle md checked square_shape" : "wizard-icon-circle md square_shape"} style={currentStep === 8 ? { borderColor: "rgb(218, 18, 118)" } : null}>
                                <div className="wizard-icon-container square_shape" style={currentStep === 8 ? { backgroundColor: "rgb(218, 18, 118)" } : null}>
                                    <i className="wizard-icon">8</i>
                                </div>
                            </div>
                            <span className={currentStep === 8 ? "stepTitle active" : "stepTitle"} style={currentStep === 8 ? { color: "rgba(218, 18, 117,.6)" } : null}>
                                Proyección
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div >
    )
}