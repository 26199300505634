import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ModalComponent from '../component/Modal';
import TableResponsive from '../component/Table';
import { deleteInconmes, getPayUser } from '../redux/action';
import moment from 'moment';
// import CardTotal from '../component/CardTotal';
// import { incomesSum } from '../utils/functions';

export default function Incomes() {
    // const [data, setData] = useState({
    //     results: []
    // })
    const [data, setData] = useState()
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [ errorSearch ,setErrorSearch ] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [modal, setModal] = useState(false)
    const [disabled, setDisabled] = useState(false);
    const [deleteAll, setDeleteAll] = useState();
    const [reload, setReload] = useState(false);
    const [stateDateRange, setStateDateRange] = useState([
        {
          startDate: moment().subtract(1, 'years').toDate(),
          endDate: moment().toDate(),
          key: 'selection'
        }
    ]);
    const [filter, setFilter] = useState('')
    const [id, setId] = useState();
    useEffect(() => {
        setDisabled(false)
        // if(localStorage.filter_orders) {
        //     setFilter(localStorage.filter_orders)
        // }
    }, [])
    useEffect(() => {
        dispatch(getPayUser())
    }, [dispatch])

    useEffect(() => {
        // let _filter = localStorage.filter_orders ? localStorage.filter_orders : ''

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        if(search.length >= 1){
            return
        }else{
            let filter_date = stateDateRange && stateDateRange.length && stateDateRange[0]?.startDate && stateDateRange[0]?.endDate ? `&order_date__gte=${moment(stateDateRange[0]?.startDate).format('YYYY-MM-DD')} 00:00:00&order_date__lte=${moment(stateDateRange[0]?.endDate).format('YYYY-MM-DD')} 23:59:00` : ''
            fetch(`${process.env.REACT_APP_URL_API}/api/orders/?page=${page}${filter_date}${filter}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    if(json.detail) {
                        setPage(page-1)
                    } else {
                        setData(json)
                    }
                })
        }
        
    }, [reload, disabled, page, search, modal, stateDateRange, filter])

    const handleSubmit = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/orders/?search=${e.target.value}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                if(e.target.value.length > 2  && json.results.length === 0) {
                    setErrorSearch(true)
                }
                if(e.target.value.length <= 2 ) {
                    setErrorSearch(false)
                }
                setData(json)
            })
    }

    const pageData = (e, url) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${url}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
    }

    const onDeleteAll = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)

        dispatch(deleteInconmes(deleteAll))
        setDeleteAll([])
    }

    const onDelete = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/orders/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Cliente eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error('Cliente no se puede eliminar ya que tiene una venta activa', {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }

    const changeFilter = (key, value, clear=false) => {
        if(clear) {
            setFilter('')
            // localStorage.filter_orders = ''
            return;
        }

        let _filter = filter;
        if(_filter.includes(key+'=')) {
            let arr = _filter.split('&')
            let newFilter = ''
            arr.forEach((item) => {
                if(item.includes(key+'=')) {
                    newFilter += `&${key}=${value}`
                } else {
                    newFilter += `&${item}`
                }
                newFilter = newFilter.replace('&&', '&')
            })
            _filter = newFilter
        } else {
            _filter += `&${key}=${value}`
        }
        setFilter(_filter)
        // localStorage.filter_orders = _filter
    }

    return (
        <>
            {
                modalDelete ? (
                    <ModalComponent
                        open={modalDelete}
                        title={deleteAll && deleteAll.length === 1 ? "Eliminar ingreso" : "Eliminar ingresos"}
                        body={deleteAll && deleteAll.length === 1 ? "¿Esta segura de eliminar este ingreso?" : `¿Esta segura de eliminar ${deleteAll.length} ingresos?`}
                        setModal={setModalDelete}
                        onDelete={onDeleteAll}
                    />
                ) : null
            }
            {
                modal ? (
                    <ModalComponent
                        open={modal}
                        title={"Eliminar ingreso"}
                        body={"¿Esta segura de eliminar este ingreso?"}
                        setModal={setModal}
                        onDelete={onDelete}
                    />
                ) : null
            }
            {/*<CardTotal
                state={data.results}
                func={incomesSum}
                title={'Total ingresos'}
            /> */}

            <TableResponsive
                data={data}
                search={search}
                setSearch={setSearch}
                handleSubmit={handleSubmit}
                toCreate={"/incomes/create"}
                toEdit={'/incomes/edit'}
                modal={modal}
                setModal={setModal}
                setId={setId}
                page={page}
                setPage={setPage}
                next={data?.next}
                previous={data?.previous}
                pageData={pageData}
                reload={reload}
                setReload={setReload}
                id={id}
                setDeleteAll={setDeleteAll}
                setModalDelete={setModalDelete}
                disabled={disabled}
                errorSearch={errorSearch}
                stateDateRange={stateDateRange}
                setStateDateRange={setStateDateRange}
                filter={filter}
                changeFilter={changeFilter}
            />
        </>
    )
}