import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setResetPassword } from '../redux/action';
import Button from '../component/Button';
import Logo from '../assets/img/logo_lg.png'
import LogoTXT from '../component/LogoTXT';

export default function ResetPassword() {
    const dispatch = useDispatch();
    const [ wait, setWait ] = useState(false)
    const [state, setState] = useState({
        email: ''
    });

    const [errors, setError] = useState({
        email: {
            bol: false,
            msg: ''
        }
    });

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {};

        Object.keys(state).map(x => {
            if (!state[x]) {
                return err[x] = {
                    bol: true,
                    msg: 'Este campo no puede estar vacío'
                }
            } else if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(state[x])) {
                return err[x] = {
                    bol: false,
                    msg: ''
                }
            } else {
                return err[x] = {
                    bol: true,
                    msg: 'Verifique el formato de su email'
                }
            }
        });

        setError(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setWait(true)
            dispatch(setResetPassword(state, setWait))
        }
    }

    return (
        <>
            <div className="container brand-container">
                <Row>
                    <Col sm="12" style={{ margin: "auto"}}>
                        <div className="card" style={{ margin: "auto", borderRadius: ".5rem" }}>
                            <div className="card-body" style={{ minHeight: "400px" }}>
                                <div className="d-flex justify-content-evenly mb-1 align-items-center">
                                    <LogoTXT />
                                    {/* <img src={Logo} alt="logo" className="login-logo" /> */}
                                    {/* <h2 className="brand-text text-sm-start ta-center fs-1 color-primary font-primary" style={{ height: "10%" }}>Flor de Emprendedora</h2> */}
                                </div>
                                <div>
                                    <h4 className="card-title mb-1 text-sm-start fs-4">Olvidaste tu contraseña? </h4>
                                    <p className="card-text mb-2 text-sm-start fs-6">Ingresá tu correo electrónico y te enviaremos instrucciones para restablecer tu contraseña</p>
                                </div>
                                <form noValidate onSubmit={e => onSubmit(e)} style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                    <div className="form-group">
                                        <label htmlFor="reset-password-email" className="form-label fs-6">Correo electrónico</label>
                                        <Button
                                            type={'email'}
                                            name={'email'}
                                            style={errors.email.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                            value={state.email}
                                            setValue={setState}
                                            state={state}
                                        />
                                        <div className={errors.email.bol ? "invalid-input" : "valid-input"}>
                                            {errors.email.msg}
                                        </div>
                                    </div>
                                    {
                                        !wait
                                            ?<button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t">Enviar email</button>
                                            :(
                                                <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t" disabled>
                                                    <div class="spinner-border text-light" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </button>
                                            )
                                    }
                                    <hr />
                                    <p className="ta-center mt-2">
                                        <span className="mr-25 fs-6">Volver a</span>
                                        <Link to="/login">
                                            <span className="color-primary fs-6">Loguearte</span>
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}