import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import ModalComponent from '../component/Modal';
import FormProducts from '../component/FormProducts';
import Loading from '../component/Loading';
import CreateVendor from './createVendor';
import CreatePackaging from './createPackaging';
import CreateSupplies from './createSupplies';
import CreateSuppliesWorkforce from './createSuppliesWorkforce';
import Modal from 'react-bootstrap/Modal';
import { getSupplies, putProductResale, putProductProduction, getProviders, getPackaging, getConfig } from '../redux/action';

export default function EditProduct() {
    const [reload, setReload] = useState(true);
    const { id } = useParams();
    const [state, setState] = useState();
    const dispatch = useDispatch();
    const marginSurcharge = useSelector(store => store.marginSurcharge)
    const [modal, setModal] = useState(false);
    const [group, setGroup] = useState([]);
    const [suppliesProduct, setSuppliesProduct] = useState([]);
    const [groupWorkforce, setGroupWorkforce] = useState([])
    const history = useHistory();
    const [supplies, setSupplies] = useState([]);
    const packaging = useSelector(store => store?.packaging);
    const workforce = useSelector(store => store?.workforce);
    const [idVendor, setId] = useState(null)
    const [addVendors, setAddVendors] = useState(false)
    const vendors = useSelector(store => store?.providers);
    const [disabled, setDisabled] = React.useState(false);
    const [addPackaging, setAddPackaging] = useState(false);
    const [addSupplies, setAddSupplies] = useState(false);
    const [addSuppliesWorkfoce, setAddSuppliesWorkforce] = useState(false)
    const [supplyAdded, setSupplyAdded] = useState(null)
    const [supplyWorkforceAdded, setSupplyWorkforceAdded] = useState(null)
    const [packageAdded, setPackageAdded] = useState(null)

    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        cost_price: {
            bol: false,
            msg: ''
        },
        sale_price: {
            bol: false,
            msg: ''
        },
        wholesaler: {
            bol: false,
            msg: ''
        },
        packaging_cost: {
            bol: false,
            msg: ''
        },
        suppliesProduct: {
            bol: false,
            msg: ''
        },
        contribution_margin: {
            bol: false,
            msg: ''
        },
        group: {
            bol: false,
            msg: ''
        },
        surcharge: {
            bol: false,
            msg: ''
        },
        surcharge_wholesale: {
            bol: false,
            msg: ''
        },
        surcharge_unit: {
            bol: false,
            msg: ''
        },
        current_stock_in_units: {
            bol: false,
            msg: ''
        },
        current_stock_in_cost: {
            bol: false,
            msg: ''
        },
        sale_actual_stock: {
            bol: false,
            msg: ''
        },
        ideal_stock_in_units: {
            bol: false,
            msg: ''
        },
        packaging: {
            bol: false,
            msg: ''
        },
        quantity_per_recipe: {
            bol: false,
            msg: ''
        }
    })

    const onSubmit = (e) => {
        e.preventDefault();
        const err = {
            margin_surcharge: {
                bol: false,
                msg: ''
            },
            sale_actual_stock: {
                bol: false,
                msg: ''
            },
            group: {
                bol: false,
                msg: ''
            }
        }

        if (state?.product_type === "resale") {
            Object.keys(state).map(x => {
                if (
                    x === "name"
                ) {
                    if (!state[x]) {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                } else if (x === "wholesaler") {
                    if (state[x] === undefined) {
                        return err[x] = {
                            bol: true,
                            msg: 'Debe seleccionar una opción'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        }

                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            })

            setErrors(err);
        } else {
            if (!suppliesProduct.length) {
                err['suppliesProduct'] = {
                    bol: true,
                    msg: 'Debe seleccionar al menos un insumo'
                }
            }
            else {
                suppliesProduct.forEach((item) => {
                    if(item.supply == '') {
                        err['suppliesProduct'] = {
                            bol: true,
                            type: 'empty',
                            msg: 'Debe seleccionar el insumo'
                        }
                        toast.error(err['suppliesProduct']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                    else if(item.unit_use == '') {
                        err['suppliesProduct'] = {
                            bol: true,
                            type: 'empty',
                            msg: 'Debe seleccionar la unidad de uso del insumo'
                        }
                        toast.error(err['suppliesProduct']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
            }

            if (!groupWorkforce.length) {
                err['groupErrorWorkforce'] = {
                    bol: true,
                    msg: 'Debe seleccionar al menos una mano de obra'
                }
            }
            else {
                groupWorkforce.forEach((item) => {
                    if(item.id == '') {
                        err['groupErrorWorkforce'] = {
                            bol: true,
                            msg: 'Debe seleccionar la mano de obra'
                        }
                        toast.error(err['groupErrorWorkforce']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
            }

            if (!group.length) {
                err['group'] = {
                    bol: true,
                    msg: 'Debe seleccionar al menos un packaging'
                }
            }
            else {
                group.forEach((item) => {
                    if(item.id == '') {
                        err['group'] = {
                            bol: true,
                            msg: 'Debe seleccionar al menos un packaging'
                        }
                        toast.error(err['group']['msg'], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
            }

            Object.keys(state).map(x => {
                if (
                    x === "name"
                ) {
                    if (!state[x]) {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                } else if (x === "wholesaler") {
                    if (state[x] === undefined) {
                        return err[x] = {
                            bol: true,
                            msg: 'Debe seleccionar una opción'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        }

                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            })

            setErrors(err);
        }

        console.log('gruop', group )

        // if(state.packaging.length >= 1) {
        //     const arr25 = []

        //     state.packaging.map(el => arr25.push({
        //         packaging_id: el.packaging_id.id,
        //         quantity: parseInt(el.quantity)
        //     }))
            
        //     state.packaging = arr25;
        // }

        console.clear()
        console.log('asdf', err, Object.values(err).filter(x => x.bol === true))


        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)

            if (state.product_type === "resale") {
                console.log('state after', state )
                dispatch(putProductResale(state, id, group))
                setTimeout(()=> {
                    history.push('/products');
                }, 800)
            } else {
                dispatch(putProductProduction(state, id, suppliesProduct, groupWorkforce, group))
                setTimeout(()=> {
                    history.push('/products');
                }, 800)
            }
        }
    }

    useEffect(() => {
        dispatch(getSupplies(false))
        dispatch(getPackaging())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/supplies/?pagination=false`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setSupplies({results: json})
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSupplies, addSuppliesWorkfoce])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/?product=${id}&pagination=false`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setSuppliesProduct(json.results)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                let _state = {...json}
                // setState(_state)
                console.log('json', json)
                if (json?.product_type === "production") {
                    // Esto es de packaging
                    const arr = []
                    json?.packaging?.map(el => {
                        // const element = packaging?.results?.filter(x => parseInt(x.id) === parseInt(el.packaging_id.id))[0]
                        return arr?.push({
                            name: el.packaging_id?.name,
                            // amount: el.packaging_id?.cost_unit,
                            amount: el.packaging_id?.cost_unit_real,
                            id: el.packaging_id?.id,
                            quantity_group: el?.quantity !== null||undefined ? el?.quantity : 1
                        })
                    })

                    setGroup(arr)
                    // Esto es de Insumos
                    const arr2 = []
                    console.clear()
                    json?.supplies?.map(el => {
                        // const element2 = supplies?.results?.filter(x => parseInt(x.id) === parseInt(el?.supply_id?.id))[0]
                        
                        let amount_used = el?.quantity ? el?.quantity : 1; //NO ESTA
                        let unit_sale = el.supply_id?.unit_measurement;

                        console.log('el.supply_id', el)

                        return arr2?.push({
                            name: el.supply_id?.name,
                            amount_used: amount_used, //NO ESTA
                            supply_id: el?.id,
                            id: el.supply_id?.id,
                            cost_product: el.cost_per_product, //costo total
                            cost_of_use: el.cost_of_use,
                            // cost_product: parseFloat(amount_used) * (unit_sale === "cm²" || unit_sale === "pk" ? el.supply_id.cost_per_unit_of_use : el.cost_of_use),
                            cost_unit_sale: el.supply_id?.cost_unit, // costo de compra
                            cost_per_unit_of_use: el.supply_id?.cost_per_unit_of_use,
                            cost_unit_use: el.supply_id?.cost_unit, //costo de uso
                            square_metter: el.supply_id?.square_metter,
                            supply: el.supply_id?.id,
                            unit_sale: unit_sale,
                            unit_use: el.unit_use, // medida select
                            element_height: el.supply_id?.element_height,
                            element_width: el.supply_id?.element_width
                        })
                    })
                    setSuppliesProduct(arr2)
                    // Esto es de mano de obra
                    const arr3 = []
                    json?.workforces?.map(el => {
                        // const element4 = workforce?.results?.filter(x => parseInt(x.id) === parseInt(el.workforce_id.id))[0]
                        return arr3?.push({
                            name: el.workforce_id?.name,
                            cost_unit: el.workforce_id?.cost_unit,
                            id: el.workforce_id?.id,
                            workfoce_id: el?.id,
                            quantity: el.quantity !== null||undefined ? el.quantity : 1
                        })
                    })
                    setGroupWorkforce(arr3)
                } else {
                    // Esto es de packaging
                    const arr = []
                    json?.packaging?.map(el => {
                        // const element = packaging?.results?.filter(x => parseInt(x.id) === parseInt(el.packaging_id.id))[0]
                        return arr?.push({
                            name: el.packaging_id?.name,
                            // amount: el.packaging_id?.cost_unit,
                            amount: el.packaging_id?.cost_unit_real,
                            id: el.packaging_id?.id,
                            quantity_group: el?.quantity !== null||undefined ? el?.quantity : 1
                        })
                    })
                    setGroup(arr)
                }

                dispatch(getConfig(function(data) {
                    setState({
                        ..._state,
                        surcharge_wholesale: data['margin_surcharge'],
                        surcharge_minorista_wholesale: data['margin_wholesaler_surcharge'],
                        tax: data['tax'] ? parseFloat(data['tax']) : 0,
                        is_wholesale: data['wholesale'] ? data['wholesale'] === 'true': false
                    })
                }))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        dispatch(getProviders())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    const handleDelete = (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}/`, {
            'method': "PATCH",
            'headers': myHeaders,
            'body': JSON.stringify({
                is_active: !state.is_active
            }),
        })
            .then((res) => {
                if(res.status === 200) {
                    history.push('/products')
                    toast.success(`Producto ${state.is_active ? 'desactivado' : 'activado'} exitosamente`, {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.success('No se puede cambiar el estado de este producto', {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }

    // const handleDelete = (e) => {
    //     e.preventDefault();

    //     alert("Eliminado")

    //     const myHeaders = new Headers();
    //     myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    //     myHeaders.append("Content-Type", 'application/json');

    //     setDisabled(true)
    //     setTimeout(() => {
    //         setDisabled(false);
    //     }, 1500)

    //     const arr = suppliesProduct.length && suppliesProduct.map(async (sup) => {
    //         return await deleteSuppliesProduct(sup)
    //     })

    //     Promise.all(arr)
    //         .then(() => {
    //             fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}`, {
    //                 'method': "DELETE",
    //                 'headers': myHeaders
    //             })
    //                 .then(() => {
    //                     toast.success('Producto eliminado exitosamente', {
    //                         className: "Toastify__toast--success"
    //                     })
    //                     history.push('/products')
    //                 })
    //                 .catch(() => {
    //                     toast.success('Ha ocurrido un error. Intente nuevamente', {
    //                         className: "Toastify__toast--error"
    //                     })
    //                 })
    //         })
    // }

    // function deleteSuppliesProduct(sup) {
    //     return new Promise(resolve => {
    //         const myHeaders = new Headers();
    //         myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    //         myHeaders.append("Content-Type", 'application/json')
    //         fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/${sup.id}`, {
    //             'method': 'DELETE',
    //             'headers': myHeaders
    //         });
    //         resolve()
    //     })
    // }


    const deleteSupplies = (idSupplies) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        setDisabled(true)
        fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/${idSupplies}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(() => {
                setDisabled(false);
                // setReload(!reload)
                
                let _suppliesProduct = suppliesProduct.filter((i) => i.supply_id !== idSupplies)

                setSuppliesProduct(_suppliesProduct);
                                
                toast.success('Insumo eliminado exitosamente', {
                    className: "Toastify__toast--success"
                })
            })
    }

    const deleteWorkforce = (idWorkforce) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        setDisabled(true)
        fetch(`${process.env.REACT_APP_URL_API}/api/product-workforce/${idWorkforce}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(() => {
                setDisabled(false);
                // setReload(!reload)
                
                let _groupWorkforce = groupWorkforce.filter((i) => i.workfoce_id !== idWorkforce)

                setGroupWorkforce(_groupWorkforce);
                                
                toast.success('Mano de obra eliminada exitosamente', {
                    className: "Toastify__toast--success"
                })
            })
    }

    // console.log('supplies', supplies)

    return (
        !state ? <Loading /> : (
            <>
                {
                    <Modal show={addVendors} className="modal-create">
                        <Modal.Header>
                            <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                            <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                                <span>×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                        </Modal.Body>
                    </Modal>
                }
                {
                    <Modal show={addPackaging} className="modal-create">
                        <Modal.Header>
                            <h5 className="modal-title color-primary">{'Agregar packaging'}</h5>
                            <button type="button" className="close color-primary" onClick={() => setAddPackaging(false)}>
                                <span>×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <CreatePackaging setPackageAdded={setPackageAdded} setAddPackaging={(e) => [setAddPackaging(e), dispatch(getPackaging(false))]}/>
                        </Modal.Body>
                    </Modal>
                }
                {
                    <Modal show={addSupplies} className="modal-create">
                        <Modal.Header>
                            <h5 className="modal-title color-primary">{'Agregar insumo'}</h5>
                            <button type="button" className="close color-primary" onClick={() => setAddSupplies(false)}>
                                <span>×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateSupplies setSupplyAdded={setSupplyAdded} setAddSupplies={setAddSupplies} />
                        </Modal.Body>
                    </Modal>
                }
                {
                    <Modal show={addSuppliesWorkfoce} className="modal-create">
                        <Modal.Header>
                            <h5 className="modal-title color-primary">{'Agregar mano de obra'}</h5>
                            <button type="button" className="close color-primary" onClick={() => setAddSuppliesWorkforce(false)}>
                                <span>×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateSuppliesWorkforce setSupplyWorkforceAdded={setSupplyWorkforceAdded} setAddSuppliesWorkforce={(e) => [setAddSuppliesWorkforce(e)]} />
                        </Modal.Body>
                    </Modal>
                }
                <ModalComponent
                    open={modal}
                    title={state.is_active ? "Desactivar producto" : "Activar producto"}
                    body={`¿Esta segura de ${state.is_active ? "desactivar" : "activar"} este producto?`}
                    setModal={setModal}
                    onDelete={handleDelete}
                    label={state.is_active ? "Desactivar" : "Activar"}
                />
                <FormProducts
                    title={'Editar producto'}
                    state={state}
                    setState={setState}
                    errors={errors}
                    back={'/products'}
                    onSubmit={onSubmit}
                    id={id}
                    vendors={vendors}
                    setAddVendors={setAddVendors}
                    addVendors={addVendors}
                    setModal={setModal}
                    idVendor={idVendor}
                    setDisabled={setDisabled}
                    disabled={disabled}
                    supplies={supplies}
                    setSuppliesProduct={setSuppliesProduct}
                    suppliesProduct={suppliesProduct}
                    deleteSupplies={deleteSupplies}
                    group={group}
                    setGroup={setGroup}
                    packaging={packaging}
                    setAddPackaging={setAddPackaging}
                    marginSurcharge={marginSurcharge}
                    groupWorkforce={groupWorkforce}
                    setGroupWorkforce={setGroupWorkforce}
                    setAddSupplies={setAddSupplies}
                    setAddSuppliesWorkforce={setAddSuppliesWorkforce}
                    deleteWorkforce={deleteWorkforce}
                    packageAdded={packageAdded}
                    setPackageAdded={setPackageAdded}
                    supplyAdded={supplyAdded}
                    setSupplyAdded={setSupplyAdded}
                    supplyWorkforceAdded={supplyWorkforceAdded}
                    setSupplyWorkforceAdded={setSupplyWorkforceAdded}
                />
            </>
        )
    )
}