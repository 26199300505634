import React, { useEffect, useState, useRef } from 'react';
import { Table, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete'
import { priceCostProduction, priceSaleProductionWithoutTax, priceSaleProduction, totalPackaging, subtotalWorkforce } from '../utils/functions';
import { useDispatch } from 'react-redux';
import { getPackaging } from '../redux/action';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from 'reactstrap';


export default function FormProduction({ addPackaging, id,groupWorkforce, setGroupWorkforce, group, setGroup, state, setState, history, errors, supplies, setSuppliesProduct, suppliesProduct, deleteSupplies, packaging, setAddPackaging, setAddSupplies, setAddSuppliesWorkforce, help, setHelp, deleteWorkforce, supplyAdded, setSupplyAdded, supplyWorkforceAdded, setSupplyWorkforceAdded, packageAdded, setPackageAdded}) {
    const inputSalePrice = useRef(null);
    
    supplies = supplies?.results ? supplies : {results: supplies}
    
    const SupplyFiltred = supplies?.results?.filter(el => el.supply_type === 'supply')
    const WorkforceFiltred = supplies?.results?.filter(el => el.supply_type === 'workforce')
    const [salePriceAuto, setSalePriceAuto] = useState(0);

    useEffect(() => {
        if(state.automatic_price_calculation) {
            setSalePriceAuto(state.sale_price);
        }
    }, [state.sale_price])
    
    useEffect(() => {
        setState({ ...state, sale_price: state.automatic_price_calculation ? salePriceAuto : state.sale_price })
    }, [state.automatic_price_calculation])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getPackaging(false))
    }, [addPackaging])

    useEffect(() => { }, [suppliesProduct])
    const handleDecrement = (e, el) => {
        e.preventDefault()

        const newGroup = group.slice().filter(x => parseInt(x.id) !== parseInt(el.id))

        el.quantity_group--

        setGroup([
            ...newGroup,
            el
        ]) 
    }

    const handleIncrement = (e, el) => {
        e.preventDefault()

        const newGroup = group.slice().filter(x => parseInt(x.id) !== parseInt(el.id))

        el.quantity_group++
        
        setGroup([
            ...newGroup,
            el
        ])
    }

    let subtotalPackaging = 0;
    group?.forEach(element => {
        const sellPrice = element.amount;
        const quantity = element.quantity_group;
        
        subtotalPackaging += sellPrice*quantity
    });

    group.sort(function (a, b) {
        return a.id - b.id;
    })

    useEffect(() => {





        //     setState({
        //         ...state,
        //         workforce_cost: subtotalWorkforce(_groupWorkforce),
        //         amount_packaging: totalPackaging(arr),
        //         sale_price: state.automatic_price_calculation ? priceSaleProduction(
        //             { 
        //                 ...state,
        //                 surcharge_unit: state.surcharge_minorista_wholesale,
        //                 margin_surcharge: parseFloat(state.surcharge_minorista_wholesale),
        //                 workforce_cost: subtotalWorkforce(_groupWorkforce),
        //                 amount_packaging: totalPackaging(arr),
        //             },
        //             suppliesProduct
        //         ) : state.sale_price,
        //         cost_price: priceCostProduction(
        //             { 
        //                 ...state,
        //                 surcharge_unit: state.surcharge_minorista_wholesale,
        //                 margin_surcharge: parseFloat(state.surcharge_minorista_wholesale),
        //                 workforce_cost: subtotalWorkforce(_groupWorkforce),
        //                 amount_packaging: totalPackaging(arr),
        //             },
        //             suppliesProduct
        //         )
        //     })
    }, [])

    useEffect(()=>{
        const supply = supplies.results[supplies.results.length - 1]
        if (supplyAdded){
            setSuppliesProduct([
                ...suppliesProduct,
                {
                    name: supply.name,
                    unit_sale: supply.unit_measurement,
                    cost_unit_sale: supply.cost_unit,
                    cost_per_unit_of_use: supply.cost_per_unit_of_use,
                    element_height: supply.element_height,
                    element_width: supply.element_width,
                    unit_use: '',
                    cost_unit_use: supply.unit_measurement === 'pk' ? parseFloat(supply.cost_unit) : parseFloat(supply.cost_per_unit_of_use),
                    amount_used: 0,
                    cost_product: '',
                    supply: supply.id
                }
            ])
            setSupplyAdded(null)
        }
        else if (supplyWorkforceAdded){
            setGroupWorkforce([
                ...groupWorkforce,
                {
                    id: supply.id,
                    cost_unit: supply.cost_unit,
                    name: supply.name,
                    quantity: 1
                }
            ])
            setSupplyWorkforceAdded(null)
        }
    }, [supplies])

    useEffect(()=>{
        const last_package = packaging.results[packaging.results.length - 1]
        console.log(last_package)
        if (packageAdded){
            setGroup([
                ...group,
                {
                    id: last_package.id,
                    amount: last_package.amount,
                    name: last_package.name,
                    quantity_group: 1
                }
            ])
            setPackageAdded(null)
        }
    }, [packaging])

    // calcula el sale_price
    const sale_price = state.automatic_price_calculation ? priceSaleProduction(
        { 
            ...state,
            surcharge_unit: state.surcharge_minorista_wholesale,
            margin_surcharge: parseFloat(state.surcharge_minorista_wholesale),
            workforce_cost: subtotalWorkforce(groupWorkforce),
            amount_packaging: totalPackaging(group),
            tax: state.tax
        },
        suppliesProduct
    ) : state.sale_price

    const sale_price_whitout_tax = state.automatic_price_calculation ? priceSaleProductionWithoutTax(
        { 
            ...state,
            surcharge_unit: state.surcharge_minorista_wholesale,
            margin_surcharge: parseFloat(state.surcharge_minorista_wholesale),
            workforce_cost: subtotalWorkforce(groupWorkforce),
            amount_packaging: totalPackaging(group),
        },
        suppliesProduct
    ) : state.sale_price

    const cost_price = priceCostProduction(
        { 
            ...state,
            surcharge_unit: state.surcharge_minorista_wholesale,
            margin_surcharge: parseFloat(state.surcharge_minorista_wholesale),
            workforce_cost: subtotalWorkforce(groupWorkforce),
            amount_packaging: totalPackaging(group),
        },
        suppliesProduct
    )

    useEffect(() => {
        setState({...state, sale_price: sale_price, cost_price: cost_price})
    }, [sale_price])

    useEffect(() => {
        setState({...state, sale_price: sale_price, cost_price: cost_price})
    }, [])

    const getCostOfUse = (el) => {
        let cost_of_use =    el.unit_sale === "ml" && el.unit_use === "l" ? parseFloat(el.cost_unit_sale) / 1000 :
                                el.unit_sale === "m" && el.unit_use === "cm" ? parseFloat(el.cost_unit_sale) / 100 :
                                    el.unit_sale === "cm" && el.unit_use === "m" ? parseFloat(el.cost_unit_sale) * 100 :
                                        el.unit_sale === "kg" && el.unit_use === "g" ? parseFloat(el.cost_unit_sale) / 1000 :
                                            el.unit_sale === "l" && el.unit_use === "ml" ? parseFloat(el.cost_unit_sale) / 1000 :
                                                el.unit_sale === "cm²" && el.unit_use === "cm²" ? (parseFloat(el.cost_unit_sale) / ((parseFloat(el.element_height) * parseFloat(el.element_width)) * 10000)).toFixed(3) :
                                                    el.unit_sale === "pk" && el.unit_use === "un" ? parseFloat(el.cost_per_unit_of_use) :
                                                        el.unit_sale === el.unit_use ? el.cost_unit_use :
                                                            null
        return cost_of_use
    }
    
    return (
        supplies ? (
            <>
                <Row>
                    <Col sm="12" className="mb-1">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group mb-1 margin-bottom-0" >
                                    <label className="form-label fs-6">Insumos</label>
                                </div>
                                <Table
                                    responsive
                                    className="table-scroll"
                                    style={suppliesProduct && suppliesProduct.length ? { minHeight: "5rem" } : { minHeight: "2rem" }}
                                >
                                    <thead>
                                        <tr>
                                            <th className='pl-2 pr-2'>Producción</th>
                                            <th className='pl-2 pr-2'>Unidad de compra</th>
                                            <th className='pl-2 pr-2'>Costo compra</th>
                                            <th className='pl-2 pr-2'>Unidad de uso</th>
                                            <th className='pl-2 pr-2'>Costo de uso</th>
                                            <th className='pl-2 pr-2'>
                                                <span>Cantidad Utilizada</span>
                                                <HelpOutlineIcon
                                                    disabled
                                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                                    onMouseEnter={() => {
                                                        setHelp('quantity_used')
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHelp('')
                                                    }}
                                                    onClick={()=> {
                                                        setHelp('quantity_used')
                                                        setTimeout(() => {
                                                            setHelp('')
                                                        }, 1500)
                                                        return
                                                    }}
                                                    id="quantity_used"
                                                />
                                                <Tooltip placement="right" isOpen={help === "quantity_used"} target="quantity_used">
                                                    Esta es la cantidad utilizada de tu unidad de uso. Si son centímetros cuadrados multiplicá los centímetros de largo por los centímetros de ancho.
                                                </Tooltip>
                                            </th>
                                            <th className='pl-2 pr-2'>Costo total</th>
                                            <th className='pl-2 pr-2'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            suppliesProduct && suppliesProduct?.length ? (
                                                suppliesProduct?.map((el, i = 0) => {
                                                    i = i + 1
                                                    return (
                                                        <tr key={i}>
                                                            <th className='pl-2 pr-2'>
                                                                <select
                                                                    value={el?.supply}
                                                                    className="custom-select"
                                                                    onChange={e => {
                                                                        const element = SupplyFiltred.filter(el => parseInt(el.id) === parseInt(e.target.value))[0]

                                                                        const arr = suppliesProduct.slice()
                                                                        arr[i - 1] = {
                                                                            name: element.name,
                                                                            unit_sale: element.unit_measurement,
                                                                            cost_unit_sale: element.cost_unit,
                                                                            cost_per_unit_of_use: element.cost_per_unit_of_use,
                                                                            element_height: element.element_height,
                                                                            element_width: element.element_width,
                                                                            unit_use: '',
                                                                            cost_unit_use: element.unit_measurement === 'pk' ? parseFloat(element.cost_unit) : parseFloat(element.cost_per_unit_of_use),
                                                                            amount_used: 0,
                                                                            cost_product: '',
                                                                            supply: element.id
                                                                        }
                                                                        setSuppliesProduct(arr)
                                                                    }}
                                                                >
                                                                    <option value="" disabled>Seleccione</option>
                                                                    {
                                                                        SupplyFiltred && SupplyFiltred.map(sup => {
                                                                            if (parseInt(sup.id) === parseInt(el.supply)) {
                                                                                return (
                                                                                    <option value={sup.id} key={sup.id}>
                                                                                        {sup.name}
                                                                                    </option>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <option value={sup.id} key={sup.id}>
                                                                                        {sup.name}
                                                                                    </option>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </select>
                                                            </th>
                                                            <th className='pl-2 pr-2'>
                                                                {
                                                                    el.unit_sale === "kg" ?
                                                                        "Kilo" : el.unit_sale === "m" ?
                                                                            "Metros" : el.unit_sale === "cm²" ?
                                                                                "Metros ²" : el.unit_sale === "l" ?
                                                                                    "Litros" : el.unit_sale === "ml" ?
                                                                                        "Mililitros" : el.unit_sale === "un" ?
                                                                                            "Unidad" : el.unit_sale === "pk" ?
                                                                                                "Paquete" : null
                                                                }
                                                            </th>
                                                            <th className='pl-2 pr-2'>$ {el.cost_unit_sale}</th>
                                                            <th className='pl-2 pr-2'>
                                                                <select
                                                                    value={el.unit_use}
                                                                    onChange={e => {
                                                                        const arr = suppliesProduct.slice()
                                                                        arr[i - 1].cost_unit_use =
                                                                            el.unit_sale === e.target.value ? el.cost_unit_sale :
                                                                                el.unit_sale === "ml" && e.target.value === "l" ? `${parseFloat(el.cost_unit_sale) / 1000}` :
                                                                                    el.unit_sale === "m" && e.target.value === "cm" ? `${parseFloat(el.cost_unit_sale) / 100}` :
                                                                                        el.unit_sale === "cm" && e.target.value === "m" ? `${parseFloat(el.cost_unit_sale) * 100}` :
                                                                                            el.unit_sale === "kg" && e.target.value === "g" ? `${parseFloat(el.cost_unit_sale) / 1000}` :
                                                                                                el.unit_sale === "l" && e.target.value === "ml" ? `${parseFloat(el.cost_unit_sale) / 1000}` :
                                                                                                    el.unit_sale === "cm²" && e.target.value === "cm²" ? `${parseFloat(el.cost_unit_sale)}` :
                                                                                                        el.unit_sale === "pk" && e.target.value === "pk" ? `${parseFloat(el.cost_unit_sale)}` :
                                                                                                            null
                                                                        arr[i - 1].unit_use = e.target.value
                                                                        arr[i - 1].cost_product = arr[i - 1].cost_unit_use && arr[i - 1].amount_used ?
                                                                            arr[i - 1].cost_unit_use * parseFloat(arr[i - 1].amount_used) : 0

                                                                        setSuppliesProduct(arr)
                                                                        setState({
                                                                            ...state,
                                                                            // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                            //     {
                                                                            //         ...state,
                                                                            //         surcharge_unit: state.surcharge_minorista_wholesale
                                                                            //     },
                                                                            //     arr
                                                                            // ) : state.sale_price,
                                                                            cost_price: priceCostProduction(
                                                                                {
                                                                                    ...state,
                                                                                    surcharge_unit: state.surcharge_minorista_wholesale
                                                                                },
                                                                                arr
                                                                            )
                                                                        })
                                                                    }}
                                                                    className={"custom-select"}
                                                                >
                                                                    {
                                                                        el?.unit_sale === "m" ? (
                                                                            <>
                                                                                <option value={""} disabled>Seleccione</option>
                                                                                <option value="m">Metro</option>
                                                                                <option value="cm">Centimetro</option>
                                                                            </>
                                                                        ) : el?.unit_sale === "kg" ? (
                                                                            <>
                                                                                <option value={""} disabled>Seleccione</option>
                                                                                <option value="kg">Kilo</option>
                                                                                <option value="g">Gramo</option>
                                                                            </>
                                                                        ) : el?.unit_sale === "l" ? (
                                                                            <>
                                                                                <option value={""} disabled>Seleccione</option>
                                                                                <option value="l">Litros</option>
                                                                                <option value="ml">Mililitros</option>
                                                                            </>
                                                                        ) : el?.unit_sale === "ml" ? (
                                                                            <>
                                                                                <option value={""} disabled>Seleccione</option>
                                                                                <option value="ml">Mililitros</option>
                                                                            </>
                                                                        ) : supplies?.results?.filter(sup => sup?.id === el?.supply)[0]?.square_meter && el?.unit_sale === "cm²" ? (
                                                                            <>
                                                                                <option value={""} disabled>Seleccione</option>
                                                                                <option value="cm²">Centimetros ²</option>
                                                                            </>
                                                                        ) : el?.unit_sale === "pk" ? (
                                                                            <>
                                                                                <option value={""} disabled>Seleccione</option>
                                                                                <option value="un">Unidad</option>
                                                                            </>
                                                                        ) : el?.unit_sale === "un" ? (
                                                                            <>
                                                                                <option value={""} disabled>Seleccione</option>
                                                                                <option value="un">Unidad</option>
                                                                            </>
                                                                        ) : null
                                                                    }
                                                                </select>
                                                            </th>
                                                            <th className='pl-2 pr-2'>
                                                                $ {getCostOfUse(el)}
                                                            </th>
                                                            <th className='pl-2 pr-2'>
                                                                <input
                                                                    type={'number'}
                                                                    className="form-control p-form br-btn-t"
                                                                    value={el.amount_used}
                                                                    onChange={e => {
                                                                        const arr = suppliesProduct.slice()
                                                                        arr[i - 1].amount_used = 1
                                                                        arr[i - 1].amount_used = parseFloat(e.target.value)
                                                                        // arr[i - 1].cost_product = parseFloat(e.target.value) * parseFloat(el.unit_sale !== "cm²" ? arr[i - 1][el.unit_sale === "pk" ? 'cost_per_unit_of_use' : 'cost_unit_use'] : (parseFloat(el.cost_unit_sale) / ((parseFloat(el.element_height) * parseFloat(el.element_width)) * 10000)))
                                                                        // arr[i - 1].cost_product = arr[i - 1].cost_of_use ? arr[i - 1].cost_of_use * parseFloat(e.target.value) : 0
                                                                        arr[i - 1].cost_product = getCostOfUse(el) ? getCostOfUse(el) * parseFloat(e.target.value !== '' ? e.target.value : 0) : 0
                                                                        setSuppliesProduct(arr)
                                                                        setState({
                                                                            ...state,
                                                                            // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                            //     {
                                                                            //         ...state,
                                                                            //         margin_surcharge: parseFloat(state.surcharge_minorista_wholesale)
                                                                            //     },
                                                                            //     arr
                                                                            // ) : state.sale_price,
                                                                            cost_price: priceCostProduction(
                                                                                {
                                                                                    ...state,
                                                                                    margin_surcharge: parseFloat(state.surcharge_minorista_wholesale)
                                                                                },
                                                                                arr
                                                                            )
                                                                        })
                                                                    }}
                                                                />
                                                            </th>
                                                            <th className='pl-2 pr-2'>
                                                                <input
                                                                    type={'text'}
                                                                    className="form-control p-form  br-btn-t"
                                                                    value={(el.cost_product >= 0) ? (el.cost_product ? el.cost_product.toFixed(2) : el.cost_product) : (parseFloat(el.cost_unit_use) ? parseFloat(el.cost_unit_use).toFixed(2) : parseFloat(el.cost_unit_use))}
                                                                    readOnly
                                                                />
                                                            </th>
                                                            <th className='pl-2 pr-2'>
                                                                <button
                                                                    className="btn-disabled"
                                                                    onClick={e => {
                                                                        e.preventDefault()

                                                                        if (history.location.pathname.includes('edit')) {
                                                                            deleteSupplies(el.supply_id)
                                                                        } else {
                                                                            if (suppliesProduct.length === 1) {
                                                                                return setSuppliesProduct([])
                                                                            } else if (suppliesProduct.length === i) {
                                                                                return setSuppliesProduct(suppliesProduct.slice(0, i - 1))
                                                                            } else {
                                                                                const arr1 = suppliesProduct.slice(0, i - 1)
                                                                                const arr2 = suppliesProduct.slice(i, suppliesProduct.length)
                                                                                setSuppliesProduct(arr1.concat(arr2))
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    <DeleteIcon className="color-primary-light" />
                                                                </button>
                                                            </th>
                                                        </tr>
                                                    )
                                                })
                                            ) : (
                                                null
                                            )
                                        }
                                    </tbody>
                                </Table>
                                {suppliesProduct.length ? null : <div className={errors.suppliesProduct.bol ? "invalid-input mt-2 mb-2 text-align-center" : "mt-2 mb-2 text-align-center"}>{errors.suppliesProduct.bol ? errors.suppliesProduct.msg : "No se seleccionaron insumos para este producto"}</div>}
                                
                                <Row className="mb-1">
                                    <Col md="5"></Col>
                                    <Col md="7" sm="12">
                                        {
                                            SupplyFiltred?.length ? (
                                                <Row>
                                                    <Col className="mb-1" sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                const element = SupplyFiltred[0];
                                                                setSuppliesProduct([
                                                                    ...suppliesProduct,
                                                                    {
                                                                        id: '',
                                                                        name: '',
                                                                        unit_sale: '',
                                                                        cost_unit_sale: 0,
                                                                        cost_per_unit_of_use: 0,
                                                                        element_height: 0,
                                                                        element_width: 0,
                                                                        unit_use: '',
                                                                        cost_unit_use: 0,
                                                                        amount_used: 1,
                                                                        cost_product: 0,
                                                                        square_meter: 1,
                                                                        supply: ''
                                                                    }
                                                                ])
                                                            }}
                                                        >
                                                            + Agregar
                                                        </button>
                                                    </Col>
                                                    <Col className="mb-1" sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddSupplies(true)
                                                            }}
                                                        >
                                                            + Crear 
                                                        </button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row>
                                                    <Col sm="6"/>
                                                    <Col sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddSupplies(true)
                                                            }}
                                                        >
                                                            + Crear 
                                                        </button>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row >
                <div className='card mb-1'>
                    <div className="card-body">
                        <div className="form-group mb-1 margin-bottom-0" >
                            <label className="form-label fs-6">Cuantos productos/servicios se obtienen de esta fórmula/receta?</label>
                            <input
                                type='number'
                                className="form-control p-form br-btn-t"
                                value={state.quantity_per_recipe}
                                onBlur={(e) => {
                                    setState({
                                        ...state,
                                        quantity_per_recipe: parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1
                                    })
                                }}
                                onChange={e => {
                                    setState({
                                        ...state,
                                        quantity_per_recipe: parseInt(e.target.value)
                                    })
                                        
                                    // const arr = groupWorkforce.slice()
                                    // arr[i - 1].quantity = e.target.value
                                    // setGroupWorkforce(arr)

                                    // setState({
                                    //     ...state,
                                    //     workforce_cost: subtotalWorkforce(arr),
                                    //     // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                    //     //     {
                                    //     //         ...state,
                                    //     //         workforce_cost: subtotalWorkforce(arr),
                                    //     //     },
                                    //     //     suppliesProduct
                                    //     // ) : state.sale_price,
                                    //     cost_price: priceCostProduction(
                                    //         {
                                    //             ...state,
                                    //             workforce_cost: subtotalWorkforce(arr),
                                    //         },
                                    //         suppliesProduct
                                    //     )
                                    // })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="card mb-1">
                    <div className="card-body">
                        <Row>
                            <div className="form-group mb-1 margin-bottom-0" >
                                <label className="form-label fs-6">Mano de obra</label>
                            </div>
                            <Col className="mb-1">
                                <Table responsive style={groupWorkforce && groupWorkforce.length ? { minHeight: "5rem" } : { minHeight: "3rem" }}>
                                    <thead>
                                        <tr>
                                            <th className='pl-2 pr-2' style={{width: '30%'}}>Nombre</th>
                                            <th className='pl-2 pr-2'>Unidad de trabajo</th>
                                            <th className='pl-2 pr-2'>Valor de unidad</th>
                                            <th className='pl-2 pr-2'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            groupWorkforce?.length ? groupWorkforce?.map((el, i = -1) => {
                                                i++
                                                //ACA HAY QUE CAMBIAR packaging.results (para cuando agregue flavio que busque los packaging.results.packaging_type:"workforce")
                                                return (
                                                    <tr key={i}>
                                                        <th className='pl-2 pr-2'>
                                                            <select
                                                                value={el.id}
                                                                onChange={e => {
                                                                    const element = WorkforceFiltred.filter(el => parseInt(el.id) === parseInt(e.target.value))[0]
                                                                    const arr = groupWorkforce.slice()
                                                                    arr[i - 1] = {
                                                                        id: element.id,
                                                                        cost_unit: element.cost_unit,
                                                                        name: element.name,
                                                                        quantity: element.quantity ? element.quantity : 1,
                                                                    }
                                                                    setGroupWorkforce(arr)

                                                                    setState({
                                                                        ...state,
                                                                        workforce_cost: subtotalWorkforce(arr),
                                                                        // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                        //     {
                                                                        //         ...state,
                                                                        //         workforce_cost: subtotalWorkforce(arr),
                                                                        //     },
                                                                        //     suppliesProduct
                                                                        // ) : state.sale_price,
                                                                        cost_price: priceCostProduction(
                                                                            {
                                                                                ...state,
                                                                                workforce_cost: subtotalWorkforce(arr),
                                                                            },
                                                                            suppliesProduct
                                                                        )
                                                                    })
                                                                }}
                                                                className={errors.group.bol ? "custom-select  error-input" : "custom-select "}
                                                            >
                                                                <option value="" disabled>Seleccione</option>
                                                                {
                                                                    WorkforceFiltred ? WorkforceFiltred.map(pack => {
                                                                        return (
                                                                            <option value={pack.id}>{pack.name}</option>
                                                                        )
                                                                    }) : null
                                                                }
                                                            </select>
                                                        </th>
                                                        <th className='pl-2 pr-2'>
                                                            <input
                                                                type='number'
                                                                className="form-control p-form br-btn-t"
                                                                value={el.quantity}
                                                                onChange={e => {
                                                                    const arr = groupWorkforce.slice()
                                                                    arr[i - 1].quantity = e.target.value
                                                                    setGroupWorkforce(arr)

                                                                    setState({
                                                                        ...state,
                                                                        workforce_cost: subtotalWorkforce(arr),
                                                                        // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                        //     {
                                                                        //         ...state,
                                                                        //         workforce_cost: subtotalWorkforce(arr),
                                                                        //     },
                                                                        //     suppliesProduct
                                                                        // ) : state.sale_price,
                                                                        cost_price: priceCostProduction(
                                                                            {
                                                                                ...state,
                                                                                workforce_cost: subtotalWorkforce(arr),
                                                                            },
                                                                            suppliesProduct
                                                                        )
                                                                    })
                                                                }}
                                                            />
                                                        </th>
                                                        <th className='pl-2 pr-2'>
                                                            <input
                                                                className="form-control p-form br-btn-t"
                                                                value={`$ ${el.cost_unit.toFixed(2)}`}
                                                                readOnly
                                                            />
                                                        </th>
                                                        {/* boton eleminar mano de obra */}
                                                        <th className='pl-2 pr-2'>
                                                            <button
                                                                className="btn-disabled"
                                                                onClick={e => {
                                                                    e.preventDefault()

                                                                    if (history.location.pathname.includes('edit')) {
                                                                        deleteWorkforce(el.workfoce_id)
                                                                    } else {
                                                                        const arr2 = groupWorkforce.filter((x, index) => parseInt(index) !== parseInt(i-1))
                                                                    
                                                                        setGroupWorkforce(arr2)
    
                                                                        setState({
                                                                            ...state,
                                                                            workforce_cost: subtotalWorkforce(arr2),
                                                                            // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                            //     {
                                                                            //         ...state,
                                                                            //         workforce_cost: subtotalWorkforce(arr2),
                                                                            //     },
                                                                            //     suppliesProduct
                                                                            // ) : state.sale_price,
                                                                            cost_price: priceCostProduction(
                                                                                {
                                                                                    ...state,
                                                                                    workforce_cost: subtotalWorkforce(arr2),
                                                                                },
                                                                                suppliesProduct
                                                                            )
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteIcon className="color-primary-light" />
                                                            </button>
                                                        </th>
                                                    </tr>
                                                )
                                            }) : null
                                        }
                                    </tbody>
                                </Table>

                                {
                                    groupWorkforce && groupWorkforce.length ? null : (
                                        <div
                                            className={errors.groupErrorWorkforce && errors?.groupErrorWorkforce?.bol ?
                                                "invalid-input mt-2 mb-2 text-align-center" :
                                                "mt-2 mb-2 text-align-center"
                                            }
                                        >
                                            {
                                                errors?.groupErrorWorkforce && errors?.groupErrorWorkforce.bol ? errors?.groupErrorWorkforce?.msg : "No se seleccionaron manos de obra."
                                            }
                                        </div>
                                    )
                                }
                                
                                <Row className="mb-1">
                                    <Col md="5"></Col>
                                    <Col md="7" sm="12">
                                        {
                                            WorkforceFiltred?.length ? (
                                                <Row>
                                                    <Col className="mb-1" sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setGroupWorkforce([
                                                                    ...groupWorkforce,
                                                                    {
                                                                        id: '',
                                                                        cost_unit:  0,
                                                                        name:   '',
                                                                        quantity: 1
                                                                    }
                                                                ])
                                                            }}
                                                        >
                                                            + Agregar
                                                        </button>
                                                    </Col>
                                                    <Col className="mb-1" sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddSuppliesWorkforce(true)
                                                            }}
                                                        >
                                                            + Crear 
                                                        </button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row>
                                                    <Col sm="6"/>
                                                    <Col sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddSuppliesWorkforce(true)
                                                            }}
                                                        >
                                                            + Crear 
                                                        </button>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-1" md="5">
                                        {
                                            groupWorkforce?.length === 0 ? null : (
                                                <>
                                                    <label className="form-label fs-6">Total de mano de obra</label>
                                                    <input
                                                        type={'text'}
                                                        className="form-control p-form  br-btn-t"
                                                        value={state.workforce_cost.toFixed(2)}
                                                        readOnly
                                                    />
                                                </>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="card mb-1">
                    <div className="card-body">
                        <Row>
                            <div className="form-group mb-1 margin-bottom-0" >
                                <label className="form-label fs-6">Packaging</label>
                            </div>
                            <Col className="mb-1">
                                <Table responsive style={group && group.length ? { minHeight: "5rem" } : { minHeight: "3rem" }}>
                                    <thead>
                                        <tr>
                                            <th className="pl-2 pr-2" style={{width: '30%'}}>Nombre</th>
                                            <th className="pl-2 pr-2">Cantidad</th>
                                            <th className="pl-2 pr-2">Subtotal</th>
                                            <th className="pl-2 pr-2">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            group.length ? group.map((el, i = -1) => {
                                                i++
                                                return (
                                                    <tr key={i}>
                                                        <td className="pl-2 pr-2">
                                                            <select
                                                                value={el.id}
                                                                onChange={e => {
                                                                    const element = packaging.results.filter(el => parseInt(el.id) === parseInt(e.target.value))[0]
                                                                    const arr = group.slice()
                                                                    arr[i - 1] = {
                                                                        id: element.id,
                                                                        amount: element.cost_unit_real,
                                                                        name: element.name,
                                                                        quantity_group: (el.quantity_group >= 0)?el.quantity_group:1
                                                                    }
                                                                    setGroup(arr)
                                                                    setState({
                                                                        ...state,
                                                                        amount_packaging: totalPackaging(arr),
                                                                        // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                        //     {
                                                                        //         ...state,
                                                                        //         amount_packaging: totalPackaging(arr),
                                                                        //     },
                                                                        //     suppliesProduct
                                                                        // ) : state.sale_price,
                                                                        cost_price: priceCostProduction(
                                                                            {
                                                                                ...state,
                                                                                amount_packaging: totalPackaging(arr),
                                                                            },
                                                                            suppliesProduct
                                                                        )
                                                                    })
                                                                }}
                                                                className={errors.group.bol ? "custom-select  error-input" : "custom-select "}
                                                            >
                                                                <option value="" disabled>Seleccione</option>
                                                                {
                                                                    packaging.results ? packaging.results.map(pack => {
                                                                        return (
                                                                            <option value={pack.id}>{pack.name}</option>
                                                                        )
                                                                    }) : null
                                                                }
                                                            </select>
                                                        </td>
                                                        <td className="pl-2 pr-2">
                                                            <ButtonGroup className="btn-group" role="group">
                                                                <button className="btn btn-outline-secondary btn-height br-btn-t" disabled={(el.quantity_group <= 1)?"disabled":""} onClick={(e)=>{
                                                                    handleDecrement(e, el)

                                                                    const arr = group.slice();
                                                                    setState({
                                                                        ...state,
                                                                        amount_packaging: totalPackaging(arr),
                                                                        // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                        //     {
                                                                        //         ...state,
                                                                        //         amount_packaging: totalPackaging(arr),
                                                                        //     },
                                                                        //     suppliesProduct
                                                                        // ) : state.sale_price,
                                                                        cost_price: priceCostProduction(
                                                                            {
                                                                                ...state,
                                                                                amount_packaging: totalPackaging(arr),
                                                                            },
                                                                            suppliesProduct
                                                                        )
                                                                    })
                                                                }}>-</button>
                                                                <button className="btn btn-outline-secondary btn-height br-btn-t" disabled>{el.quantity_group}</button>
                                                                <button className="btn btn-outline-secondary btn-height br-btn-t" disabled={(el.quantity_group >= 10)?"disabled":""} onClick={(e)=>{
                                                                    handleIncrement(e, el)

                                                                    const arr = group.slice();
                                                                    setState({
                                                                        ...state,
                                                                        amount_packaging: totalPackaging(arr),
                                                                        // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                        //     {
                                                                        //         ...state,
                                                                        //         amount_packaging: totalPackaging(arr),
                                                                        //     },
                                                                        //     suppliesProduct
                                                                        // ) : state.sale_price,
                                                                        cost_price: priceCostProduction(
                                                                            {
                                                                                ...state,
                                                                                amount_packaging: totalPackaging(arr),
                                                                            },
                                                                            suppliesProduct
                                                                        )
                                                                    })
                                                                }}>+</button>
                                                            </ButtonGroup>
                                                        </td>
                                                        <td className="pl-2 pr-2">
                                                            <input
                                                                className="form-control p-form br-btn-t"
                                                                value={`$ ${el.amount ? el.amount.toFixed(2) : el.amount}`}
                                                                readOnly
                                                            />
                                                        </td>
                                                        <td className="pl-2 pr-2">
                                                            <button
                                                                className="p-2"
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: 'none'
                                                                }}
                                                                onClick={e => {
                                                                    e.preventDefault()

                                                                    if (id) {
                                                                        const arr = state.packaging.filter(x => parseInt(x) !== parseInt(el.id))
                                                                        const arr2 = group.filter(x => parseInt(x.id) !== parseInt(el.id))
                                                                        setState({
                                                                            ...state,
                                                                            packaging: arr,
                                                                            amount_packaging: totalPackaging(arr2)
                                                                        })
                                                                        setGroup(arr2)
                                                                    } else {
                                                                        if (group.length === 1) {
                                                                            setState({
                                                                                ...state,
                                                                                amount_packaging: 0,
                                                                                // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                                //     {
                                                                                //         ...state,
                                                                                //         amount_packaging: 0,
                                                                                //     },
                                                                                //     suppliesProduct
                                                                                // ) : state.sale_price,
                                                                                cost_price: priceCostProduction(
                                                                                    {
                                                                                        ...state,
                                                                                        amount_packaging: 0,
                                                                                        surcharge_unit: state.surcharge_minorista_wholesale
                                                                                    },
                                                                                    suppliesProduct
                                                                                )
                                                                            })
                                                                            return setGroup([])
                                                                        } else if (group.length === i) {
                                                                            setState({
                                                                                ...state,
                                                                                amount_packaging: totalPackaging(group.slice(0, i - 1)),
                                                                                // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                                //     {
                                                                                //         ...state,
                                                                                //         amount_packaging: totalPackaging(group.slice(0, i - 1)),
                                                                                //     },
                                                                                //     suppliesProduct
                                                                                // ) : state.sale_price,
                                                                                cost_price: priceCostProduction(
                                                                                    {
                                                                                        ...state,
                                                                                        amount_packaging: totalPackaging(group.slice(0, i - 1)),
                                                                                        surcharge_unit: state.surcharge_minorista_wholesale
                                                                                    },
                                                                                    suppliesProduct
                                                                                )
                                                                            })
                                                                            return setGroup(group.slice(0, i - 1))
                                                                        } else {
                                                                            const arr1 = group.slice(0, i - 1)
                                                                            const arr2 = group.slice(i, group.length)
                                                                            setState({
                                                                                ...state,
                                                                                amount_packaging: totalPackaging(arr1.concat(arr2)),
                                                                                // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                                //     {
                                                                                //         ...state,
                                                                                //         amount_packaging: totalPackaging(arr1.concat(arr2)),
                                                                                //     },
                                                                                //     suppliesProduct
                                                                                // ) : state.sale_price,
                                                                                cost_price: priceCostProduction(
                                                                                    {
                                                                                        ...state,
                                                                                        amount_packaging: totalPackaging(arr1.concat(arr2)),
                                                                                        surcharge_unit: state.surcharge_minorista_wholesale
                                                                                    },
                                                                                    suppliesProduct
                                                                                )
                                                                            })
                                                                            setGroup(arr1.concat(arr2))
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteIcon className="color-primary-light" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : null
                                        }
                                    </tbody>
                                </Table>
                                {
                                    group.length ? null : (
                                        <div
                                            className={errors.group.bol ?
                                                "invalid-input mt-2 mb-2 text-align-center" :
                                                "mt-2 mb-2 text-align-center"
                                            }
                                        >
                                            {
                                                errors.group.bol ? errors.group.msg : "No se seleccionaron paquetes"
                                            }
                                        </div>
                                    )
                                }
                                <Row className="mb-1">
                                    {/* <Col></Col> */}
                                    {/* </Row>
                                <Row> */}
                                    {/* <Col></Col> */}
                                    <Col md="5"></Col>
                                    <Col md="7" sm="12" className="">
                                        {
                                            packaging.results.length ? (
                                                <Row>
                                                    <Col className="mb-1" sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setGroup([
                                                                    ...group,
                                                                    {
                                                                        id: '',
                                                                        amount: 0,
                                                                        name: '',
                                                                        quantity_group: 1
                                                                    }
                                                                ])
                                                                setState({
                                                                    ...state,
                                                                    amount_packaging: totalPackaging([
                                                                        ...group,
                                                                        {
                                                                            id: packaging.results[0].id,
                                                                            name: packaging.results[0].name,
                                                                            amount: packaging.results[0].cost_unit_real,
                                                                            quantity_group: packaging.results[0].quantity_group
                                                                        }
                                                                    ]),
                                                                    // sale_price: state.automatic_price_calculation ? priceSaleProduction(
                                                                    //     {
                                                                    //         ...state,
                                                                    //         amount_packaging: totalPackaging([
                                                                    //             ...group,
                                                                    //             {
                                                                    //                 id: packaging.results[0].id,
                                                                    //                 name: packaging.results[0].name,
                                                                    //                 amount: packaging.results[0].cost_unit_real,
                                                                    //                 quantity_group: packaging.results[0].quantity_group
                                                                    //             }
                                                                    //         ]),
                                                                    //     },
                                                                    //     suppliesProduct
                                                                    // ) : state.sale_price,
                                                                    cost_price: priceCostProduction(
                                                                        {
                                                                            ...state,
                                                                            amount_packaging: totalPackaging([
                                                                                ...group,
                                                                                {
                                                                                    id: packaging.results[0].id,
                                                                                    name: packaging.results[0].name,
                                                                                    amount: packaging.results[0].cost_unit_real,
                                                                                    surcharge_unit: state.surcharge_minorista_wholesale
                                                                                }
                                                                            ]),
                                                                        },
                                                                        suppliesProduct
                                                                    )
                                                                })
                                                            }}
                                                        >
                                                            + Agregar
                                                        </button>
                                                    </Col>
                                                    <Col className="mb-1" sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddPackaging(true)
                                                            }}
                                                        >
                                                            + Crear 
                                                        </button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row>
                                                    <Col sm="6"/>
                                                    <Col className="mb-1" sm="6">
                                                        <button
                                                            className="linkModal mt-1 color-primary-light background-transparent border-none"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddPackaging(true)
                                                            }}
                                                        >
                                                            + Crear 
                                                        </button>
                                                    </Col>
                                                </Row>
                                                
                                            )
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-1" md="5">
                                        {
                                            group.length === 0 ? null : (
                                                <>
                                                    <label className="form-label fs-6">Total de packaging</label>
                                                    <input
                                                        type={'text'}
                                                        className="form-control p-form  br-btn-t"
                                                        value={subtotalPackaging.toFixed(2)}
                                                        readOnly
                                                    />
                                                </>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className='card mb-1'>
                    <div className="card-body">
                        <div className="form-group mb-1 margin-bottom-0" >
                            <label className="form-label fs-6">Inventario</label>
                        </div>
                        <div className="form-group">
                            <label className="form-label fs-6">Stock actual en unidades</label>
                            <HelpOutlineIcon
                                disabled
                                style={{ marginLeft: "1rem", fontSize: "large" }}
                                onMouseEnter={() => {
                                    setHelp('current_stock_in_units')
                                }}
                                onMouseLeave={() => {
                                    setHelp('')
                                }}
                                onClick={()=> {
                                    setHelp('current_stock_in_units')
                                    setTimeout(() => {
                                        setHelp('')
                                    }, 1500)
                                    return
                                }}
                                id="current_stock_in_units"
                            />
                            <Tooltip placement="right" isOpen={help === "current_stock_in_units"} target="current_stock_in_units">
                                La cantidad de unidades que tenés en stock actualmente.
                            </Tooltip>
                            {
                                history.location.pathname.includes('products/view') ? (
                                    <input
                                        type={'number'}
                                        name={'current_stock_in_units'}
                                        className={"btn-disabled form-control p-form  br-btn-t"}
                                        value={state.current_stock_in_units}
                                        readOnly
                                    />
                                ) : (
                                    <>
                                        <input
                                            type={'number'}
                                            name={'current_stock_in_units'}
                                            className={errors.current_stock_in_units.bol ? "form-control p-form  br-btn-t error-input" : "form-control p-form  br-btn-t"}
                                            value={state.current_stock_in_units}
                                            disabled={history.location.pathname.includes('edit')}
                                            onChange={e => {
                                                setState({
                                                    ...state,
                                                    current_stock_in_units: e.target.value
                                                })
                                            }}
                                        />
                                        <div className={errors.current_stock_in_units.bol ? "invalid-input" : "valid-input"}>
                                            {errors.current_stock_in_units.msg}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="card mb-1">
                    <div className="card-body">
                        <Row>
                            <Col md="4" sm="12" className="mb-1">
                                <label className="form-label fs-6">Costo del producto</label>
                                <input
                                    type={'text'}
                                    className="form-control p-form  br-btn-t"
                                    value={isNaN(state.cost_price) ? '0' : state.cost_price.toFixed(2)}
                                    readOnly
                                />
                                <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                            </Col>
                            <Col md="4" sm="12" className="mb-1">
                                <label className="form-label fs-6">Precio sin impuesto</label>
                                <input
                                    type={'text'}
                                    className="form-control p-form  br-btn-t"
                                    value={isNaN(sale_price_whitout_tax) ? '0' : sale_price_whitout_tax.toFixed(2)}
                                    readOnly
                                />
                                <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                            </Col>
                            <Col md="4" sm="12" className="mb-1">
                                <label className="form-label fs-6">Precio final del producto</label>
                                <input
                                    ref={inputSalePrice}
                                    type={'text'}
                                    className="form-control p-form  br-btn-t"
                                    value={isNaN(state.sale_price) ? '0' : (state.automatic_price_calculation ? parseFloat(state.sale_price).toFixed(2) : parseFloat(state.sale_price).toFixed(2)) }
                                    readOnly={state.automatic_price_calculation}
                                    onChange={e => {
                                        setState({
                                            ...state,
                                            sale_price: e.target.value
                                        })
                                    }}
                                />
                                <small className="form-text text-muted">Este valor se calcula automáticamente</small>

                                <small className="form-text text-muted d-flex align-items-center">
                                    <label 
                                        class="custom-checkbox" 
                                        onChange={e => {
                                            setState({
                                                ...state,
                                                automatic_price_calculation: !state?.automatic_price_calculation,
                                            })
                                        }}
                                    >
                                        <input type="checkbox" checked={!state?.automatic_price_calculation} />
                                        <span class="custom-checkbox-checkmark"></span>
                                        Cambiar el precio manualmente
                                    </label>
                                </small>
                            </Col>

                            {!state.automatic_price_calculation && parseFloat(state.sale_price) <= parseFloat(state.cost_price) && !state.use_manual_pricing ?
                            <Col>
                                <Alert variant="danger">
                                    No es recomendable vender este producto a este valor. Ya que generaría pérdidas
                                    <div className='mt-1'>
                                        <button type="button" className="btn btn-light btn-sm" onClick={() => setState({...state, use_manual_pricing: true})}>Mantener valor</button>
                                        <button type="button" className="btn btn-light btn-sm ms-2" onClick={() => inputSalePrice.current.focus()}>Corregir</button>
                                    </div>
                                </Alert>
                            </Col>
                            : null}

                            {/* {state.is_wholesale ? 

                            : null} */}
                        </Row>
                    </div>
                </div>

                <div className='card mb-1'>
                    <div className='card-body'>
                        <div className="form-group margin-bottom-0">
                            <label className="form-label fs-6">Detalles</label>
                            <textarea
                                name={'details'}
                                className={"form-control p-form btn-height-6 br-btn-t resize-none"}
                                value={state.details}
                                onChange={e =>
                                    setState({
                                        ...state,
                                        details: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </>
        ) : null
    )
}