import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function AccountMsg() {
    const history = useHistory();
    
    return (
        <>
            <div className="container brand-container">
                <Row>
                    <Col sm="12" className="d-flex" style={{width: "75%", margin: "auto"}}>
                        <div className="card" style={{ margin: "auto", borderRadius: ".5rem" }}>
                            <div className="card-body flex-space-around" style={{ minHeight: "300px", padding: "2.5rem" }}>
                                <Col sm="12">
                                    <Link className="brand-logo" to="/login">
                                        <h2 className="brand-text text-sm-start ta-center fs-1 brand-logo color-primary font-primary" style={{ height: "10%" }}>Flor de Emprendedora</h2>
                                    </Link>
                                </Col>
                                <Col sm="12">
                                    <h4 className="card-title mb-1 ta-center fs-4">Gracias por registrarte</h4>
                                    <p className="card-text mb-2 ta-center fs-6">Te enviamos un email para validar tu cuenta. Deberas validarla para ingresar</p>
                                </Col>
                                <button type="submit" onClick={() => history.push("/login")} className="btn btn-primary btn-block btn-height-3 br-btn-t">Loguearse</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div >
        </>
    )
}