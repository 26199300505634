import { toast } from 'react-toastify';

export function setResetPassword(obj, setWait) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/auth/reset-password/`, {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resp => resp?.json())
            .then(json => {
                if (json.detail === "User not found.") {
                    setWait(false)

                    toast.error(json.detail, {
                        className: "Toastify__toast--error"
                    })
                } else {
                    setWait(false)
                    toast.success(json.detail, {
                        className: "Toastify__toast--success"
                    })
                }
            })
            .catch(err => console.log(err))
    }
}

export function setNewPassword(obj, history) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')
    const {password, token} = obj;

    const data = {
        token,
        new_password: password
    }

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/auth/reset-new-password/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resp => resp?.json())
            .then(json => {
                if (json.detail === "No se pudo restablecer la contraseña.") {
                    toast.error(json.detail, {
                        className: "Toastify__toast--error"
                    })
                } else {
                    setTimeout(()=> {
                        history.push('/login')
                    }, 800)
                    toast.success(json.detail, {
                        className: "Toastify__toast--success"
                    })
                }
            })
            .catch(err => console.log(err))
    }
}

export function getProviders() {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
                method: 'GET',
                headers: myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "GET_PROVIDERS",
                        payload: json
                    })
                })
        )
    }
}

export function searchProviders(str) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/orders/?search=${str}`, {
                method: 'GET',
                headers: myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "SEARCH_PROVIDERS",
                        payload: json
                    })
                })
        )
    }
}

export function deleteProvider(id) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/providers/${id}/`, {
                'method': "DELETE",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    if(json.length <= 0) {
                        toast.success('Proveedor eliminado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                    } else {
                        toast.error(json[0], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        )
    }
}

export function deleteAllProvider(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/providers/bulk/`, {
                'method': "DELETE",
                body: JSON.stringify(all),
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    if(json.length <= 0) {
                        toast.success('Proveedores eliminado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                    } else {
                        toast.error(json[0], {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        )
    }
}

export function pageProvider(url) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${url}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "PAGE_PROVIDER",
                        payload: json
                    })
                })
        )
    }
}


export function getSupplies(pagination=true) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    pagination = pagination === false ? '?pagination=false' : ''

    return function (dispatch) {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/supplies/${pagination}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "GET_SUPPLIES",
                        payload: json

                    })
                })
        )
    }
}

export function searchSupplies(str) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/supplies/?search=${str}`, {
                method: 'GET',
                headers: myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "SEARCH_SUPPLIES",
                        payload: json
                    })
                })
        )
    }
}

export function deleteSupplies(id) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        return fetch(`${process.env.REACT_APP_URL_API}/api/supplies/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Insumo eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function deleteAllSupplies(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        return fetch(`${process.env.REACT_APP_URL_API}/api/supplies/bulk/`, {
            'method': "DELETE",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Insumo eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function pageSupplies(url) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${url}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "PAGE_SUPPLIES",
                        payload: json
                    })
                })
        )
    }
}

export function getExpenses() {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/expenses/`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "GET_EXPENSES",
                        payload: json
                    })
                })
        )
    }
}

export function pageExpenses(url) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${url}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "PAGE_EXPENSES",
                        payload: json
                    })
                })
        )
    }
}

export function deleteAllExpenses(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/expenses/bulk/`, {
            'method': "DELETE",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Expensas eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function deleteExpenses(id) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/expenses/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Expensa eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function getProducts() {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/products/?is_active=true`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "GET_PRODUCTS",
                        payload: json
                    })
                })
        )
    }
}

export function getWorkforce() {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/supplies/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                dispatch({
                    type: "GET_WORKFORCE",
                    payload: json
                })
            })
    }
}

export const createNewProducts = (all) => ({
    type: 'GET_NEW_PRODUCT',
    payload: [all]
})

export function searchProducts(str) {
    return function (dispatch) {
        return (
            null
            // fetch(`${process.env.REACT_APP_URL_API}/api/products/?search=${str}`, {
            //     method: 'GET',
            //     headers: myHeaders
            // })
            //     .then(resp => resp?.json())
            //     .then(json => {
            //         dispatch({
            //             type: "SEARCH_PRODUCTS",
            //             payload: json
            //         })
            //     })
        )
    }
}

export function desactivateAllProduct(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/products/bulk_desactivate/`, {
            'method': "PATCH",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success(`Producto${all.length === 1 ? '' : 's'} desactivado${all.length === 1 ? '' : 's'} exitosamente`, {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function activateAllProduct(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/products/bulk_activate/`, {
            'method': "PATCH",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success(`Producto${all.length === 1 ? '' : 's'} activado${all.length === 1 ? '' : 's'} exitosamente`, {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function deleteProduct(id) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}/`, {
            'method': "PATCH",
            'headers': myHeaders,
            'body': JSON.stringify({
                is_active: false
            }),
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Producto eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function pageProduct(url) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function (dispatch) {
        return (
            fetch(`${url}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp?.json())
                .then(json => {
                    dispatch({
                        type: "PAGE_PRODUCTS",
                        payload: json
                    })
                })
        )
    }
}


export function getClients() {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/clients/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                dispatch({
                    type: "GET_CLIENTS",
                    payload: json
                })
            })
    }
}

export function pageClients(url) {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${url}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                dispatch({
                    type: "PAGE_CLIENTS",
                    payload: json
                })
            })
    }
}

export function deleteClient(id) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/clients/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Cliente eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function deleteAllClient(all) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/clients/bulk/`, {
            'method': "DELETE",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Cliente eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function searchClients(str) {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/clients/?search=${str}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                dispatch({
                    type: "SEARCH_CLIENTS",
                    payload: json
                })
            })
    }
}

export function deleteCommercial(id) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        let url = `${process.env.REACT_APP_URL_API}/api/commertial-expenses/${id}/`;

        fetch(url, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(resp => {
                if(resp.status === 204) {
                    toast.success('Gastos comerciales eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error('Error al eliminar gasto comercial', {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function deleteAllCommercial(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/bulk/`, {
            'method': "DELETE",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Gastos comerciales eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function getCommercialExpenses() {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                dispatch({
                    type: "GET_COMMERCIAL_EXPENSES",
                    payload: json
                })
            })
    }
}

export function deleteAllPackaging(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/packaging/bulk/`, {
            'method': "DELETE",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Paquete eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function getPackaging(pagination=true) {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/packaging/${!pagination ? '?pagination=false' : ''}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                dispatch({
                    type: "GET_PACKAGING",
                    payload: pagination ? json : {results: json}
                })
            })
    }
}

export function getConfigSupply() {
    return function async (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/?key=supplies`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                dispatch({
                    type: "GET_CONFIG_SUPPLY",
                    payload: json.results && json.results.length ? json.results[0].value : true
                })
            })
    }
}

export function getMarginSurcharge(callback) {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/?key=margin_surcharge`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                let value = json.results && json.results.length ? json.results[0].value : 0
                dispatch({
                    type: "GET_MARGIN_SURCHARGE",
                    payload: value
                })
                callback(value)
            })
    }
}

export function getMarginWholesalerSurcharge(callback) {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/?key=margin_wholesaler_surcharge`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                let value = json.results && json.results.length ? json.results[0].value : 0
                dispatch({
                    type: "GET_MARGIN_WHOLESALER_SURCHARGE",
                    payload: value
                })
                callback(value)
            })
    }
}

export function getIsWholesaler(callback) {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/?key=wholesale`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                let value = json.results && json.results.length ? json.results[0].value : 0
                dispatch({
                    type: "GET_WHOLESALER",
                    payload: value
                })
                callback(value)
            })
    }
}

export function getConfig(callback) {
    return function (dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/global/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                let arr = Array()
                json.results.forEach((item) => {
                    arr[item.key] = item.value
                })
                callback(arr)
            })
    }
}

export function postClient(obj, setId) {
    return () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/clients/`, {
            'method': "POST",
            'headers': myHeaders,
            'body': JSON.stringify(obj)
        })
            .then(resp => resp?.json())
            .then(json => {
                if (json.id) {
                    toast.success('Cliente creado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                    if (setId) {
                        setId(json.id)
                    }
                } else {
                    toast.success(json.name, {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function postIncomes(obj, clientsChange, history) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        const data = {
            ...obj,
            order_date: `${obj.order_date} 00:00:00`,
            client: clientsChange
        }

        fetch(`${process.env.REACT_APP_URL_API}/api/orders/`, {
            'method': "POST",
            'headers': myHeaders,
            'body': JSON.stringify(data)
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json[0]){
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
                if (json.id) {
                    setTimeout(() => {
                        history.push(`/incomes`)
                    }, 900)
                    toast.success('Ingreso creado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                }
            })
    }
}

export function deleteInconmes(all) {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    return function () {
        fetch(`${process.env.REACT_APP_URL_API}/api/orders/bulk/`, {
            'method': "DELETE",
            body: JSON.stringify(all),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.length <= 0) {
                    toast.success('Venta eliminada exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error(json[0], {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function postPackaging(body, callback=null) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/packaging/`, {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if (json.id) {
                    toast.success('Packaging creado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                    if(callback) callback()
                }
            })
    }
}

export function postProductProduction(obj, callback) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/products/`, {
            'method': 'POST',
            'body': JSON.stringify(obj),
            'headers': myHeaders
        })
            .then(response => {
                if (!response.ok) {
                    // throw Error(response);
                    return response.text().then(text => { throw Error(text) })

                }
                return response;
            })
            .then(json => {
                if(json) {
                    callback(json)
                } else {
                    toast.error('Hubo un error al agregar el producto', {
                        className: "Toastify__toast--error"
                    })
                }
                
            })
            .catch(error => {
                let errors = JSON.parse(error.message)
                for(var err in errors) {
                    let label = document.querySelector(`[for="${err}"]`)?.innerText
                    toast.error(label +': '+ errors[err].toString(), {
                        className: "Toastify__toast--error"
                    })
                }
            });
    }
}

export function postProductResale(obj, callback) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/`, {
            'method': 'POST',
            'body': JSON.stringify(obj),
            'headers': myHeaders
        })
            .then(response => {
                if (!response.ok) {
                    // throw Error(response);
                    return response.text().then(text => { throw Error(text) })

                }
                return response;
            })
            .then(json => {
                callback(json)
            })
            .catch(error => {
                let errors = JSON.parse(error.message)
                for(var err in errors) {
                    let label = document.querySelector(`[for="${err}"]`)?.innerText
                    toast.error(label +': '+ errors[err].toString(), {
                        className: "Toastify__toast--error"
                    })
                }
            });

            // .then(resp => resp?.json())
            // .then(json => {
            //     if (json.id) {
            //         toast.success('Producto creado exitosamente', {
            //             className: "Toastify__toast--success"
            //         })
            //     }
            //     callback(json)
            // })
    }
}

export async function postSuppliesProduct(sup, id) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    const resp = await fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/`, {
        'method': 'POST',
        'body': JSON.stringify({
            ...sup,
            product: id
        }),
        'headers': myHeaders
    });
    const json = await resp?.json();
    return json;
}

export async function deleteSuppliesProduct(sup) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    const resp = await fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/${sup.id}`, {
        'method': 'DELETE',
        'headers': myHeaders
    });
    const json = await resp?.json();
    return json;
}

export async function putSuppliesProduct(sup, id) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append("Content-Type", 'application/json')

    const resp = await fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/${sup.id}/`, {
        'method': 'PUT',
        'body': JSON.stringify({
            ...sup,
            product: id
        }),
        'headers': myHeaders
    });
    const json = await resp?.json();
    return json;
}

export function putProductProduction(state, id, suppliesProduct, groupWorkforce, group) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        const data = {
            ...state,
            supplies: [],
            workforces: [],
            packaging: []
        }

        if(suppliesProduct.length >= 1) {
            const arr23 = []
            suppliesProduct.map(el => arr23.push({
                supply_id: el.supply,
                quantity: parseInt(el.amount_used),
                unit_measurement: el.unit_use
            }))
            arr23.map(el => data.supplies.push(el))
        }
        if(groupWorkforce.length >= 1) {
            const arr24 = []
            groupWorkforce.map(el => arr24.push({
                workforce_id: el.id,
                quantity: parseInt(el.quantity)
            }))
            arr24.map(el => data.workforces.push(el))
        }
        if(group.length >= 1) {
            const arr25 = []
            group.map(el => arr25.push({
                packaging_id: el.id,
                quantity: parseInt(el.quantity_group)
            }))
            arr25.map(el => data.packaging.push(el))
        }

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}/`, {
            'method': 'PUT',
            'body': JSON.stringify(data),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json) {
                    toast.success('Producto modificado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.error('No se pudo modificar el producto.', {
                        className: "Toastify__toast--error"
                    })
                }
                
            })
    }
}

export function putProductResale(state, id, group) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json');

        const data = {
            ...state,
            packaging: []
        }

        if(group.length >= 1) {
            const arr25 = []
            group.map(el => arr25.push({
                packaging_id: el.id,
                quantity: parseInt(el.quantity_group)
            }))
            arr25.map(el => data.packaging.push(el))
        }

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}/`, {
            'method': "PUT",
            'body': JSON.stringify(data),
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if (json.id) {
                    toast.success('Producto modificado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                } else {
                    toast.success(json.name, {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
}

export function deleteProductProduction(id) {
    return function () {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json');

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(() => {
                toast.success('Producto eliminado exitosamente', {
                    className: "Toastify__toast--success"
                })
            })
    }
}

export function uploadImage(files, state, imagesApi, callback) {
    return function async() {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        for(let i = 0; i < files?.length;  i++) {
            const formdata = new FormData();
            formdata?.append("image", files[i], files[i].name);
            fetch(`${process.env.REACT_APP_URL_API}/api/images/`, {
                'method': "POST",
                'headers': myHeaders,
                'body': formdata,
                'redirect': 'follow'
            })
                .then(resp => resp.json())
                .then(json => {
                    imagesApi.push(json)
                    state.images.push(json?.id);
                    callback()
                })
        }
    }
}


export function getImage(id, setImagesApi, imagesApi) {
    return function async() {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}/images/`, {
            'method': 'GET',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                return setImagesApi(json.images)
                }
            )
        return
    }
}

export function deleteImage(id) {
    return function async() {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/images/${id}/`, {
            'method': 'DELETE',
            'headers': myHeaders
        })
            .then(() => {
                // toast.success('Producto eliminado exitosamente', {
                //     className: "Toastify__toast--success"
                // })
                
            })
        return
    }
}

export function getDashboardResume(select, from, to, setDataDashboard) {
    return function async() {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/dashboard/?period=${select}${from ? '&from='+from : ''}${to ? '&to='+to : ''}`, {
            'method': 'GET',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => setDataDashboard(json))
        return 
    }
}

export function getPackagingSimple(setDataPackaging) {
    return function async() {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/packaging/?packaging_type=simple&pagination=false`, {
            'method': 'GET',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => setDataPackaging(json))
        return 
    }
}

export function getPremium(setPlans, setLoading) {
    return function async(dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/plans/`, {
            'method': 'GET',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(data => {
                setPlans({data})
                setLoading(false)
                const premium = data?.filter(elem => elem.name === "Pro")
                dispatch({
                    type: "GET_PREMIUM",
                    payload: premium[0]
                })
            })
        return 
    }
}

export function getConfigPremium() {
    return function async(dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/config/plans/`, {
            'method': 'GET',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(data => {
                const premium = data?.filter(elem => elem.name === "Pro")
                dispatch({
                    type: "GET_PREMIUM",
                    payload: premium[0]
                })
            })
        return 
    }
}


export function getPayUser() {
    return function async(dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/account/plan/`, {
            'method': 'GET',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(data => {
                dispatch({
                    type: "SET_PREMIUM_USER",
                    payload: data
                })
            })
        return 
    }
}

export function cancelPremium(setModal) {
    return function async(dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/payments/process_payment/`, {
            'method': 'DELETE',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(data => {
                dispatch({
                    type: "SET_PREMIUM_USER",
                    payload: data
                })
                setModal(false)
            })
        return 
    }
}


export function resetPremiumUser(preapproval_plan_id) {
    return function async(dispatch) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/payments/process_payment/`, {
            'method': 'DELETE',
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(data => {
                dispatch({
                    type: "SET_PREMIUM_USER",
                    payload: data
                })
                window.location.href=`https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=${preapproval_plan_id}`
            })
        return 
    }
}

export function setPayUser(data) {
    return function async(dispatch) {
        dispatch({
            type: "SET_PREMIUM_USER",
            payload: data
        })
    }
}