import React from 'react';
import { Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';


export default function CardProfile({ state, setState, enviarInput, name, error }) {

    return (
        <form onSubmit={enviarInput}>
            <div className="card">
                <div class="card-header mt-1"><div class="card-title"><h2>Perfil</h2></div></div>
                <div className="card-body pt-1">
                    <Row>
                        <Col md="4" sm="12">
                            <div className="form-group">
                                <label> Nombre  </label>
                                <input
                                    className={error.first_name.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state.first_name}
                                    onChange={(e) => { setState({ ...state, first_name: e.target.value }) }} />
                                <div className={error.first_name.bol ?
                                    "invalid-input" : "valid-input"}>
                                    {error.first_name.msg}
                                </div>
                            </div>
                        </Col>
                        <Col md="4" sm="12">
                            <div className="form-group">
                                <label> Apellido </label>
                                <input
                                    className={error.last_name.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state.last_name}
                                    onChange={(e) => { setState({ ...state, last_name: e.target.value }) }} />
                                <div className={error.last_name.bol ?
                                    "invalid-input" : "valid-input"}>
                                    {error.last_name.msg}
                                </div>
                            </div>
                        </Col>
                        <Col md="4" sm="12">
                            <div className="form-group">
                                <label> Email </label>
                                <input
                                    className={error.email.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    value={state.email}
                                    readOnly
                                />
                                <div className={error.email.bol ?
                                    "invalid-input" : "valid-input"}>
                                    {error.email.msg}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md="4" sm="12">
                            <div className="form-group">
                                <label> Emprendimiento </label>
                                <input className='form-control p-form  br-btn-t' placeholder="emprendimiento" />
                            </div>
                        </Col>
                    </Row> */}
                </div>
            </div>
            <div className='mt-4 mb-1'>
                <div className='d-flex justify-content-end'>
                    <div className="form-group">
                        <NavLink to="/">
                            <button
                                type="submit"
                                className='btn btn-outline-secondary  br-btn-t'
                            >
                                Cancelar
                            </button>
                        </NavLink>
                    </div>
                    <div className="form-group pl-3">
                        <button className='btn background-secondary br-btn-t  color-white color-white'
                            type='sumbit'> Guardar </button>
                    </div>
                </div>
            </div>
        </form>
    )
}