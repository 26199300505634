import CardProfile from '../component/CardProfile';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export default function Profile({ reloadContainer, bol }) {
    const [state, setState] = useState(null)
    const [name, setName] = useState(null);
    const [reload, setReload] = useState(false)
    const [error, setError] = useState({
        email: {
            bol: false,
            msg: ''
        },
        first_name: {
            bol: false,
            msg: ''
        },
        last_name: {
            bol: false,
            msg: ''
        }
    })

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/auth/profile/`, {
            'method': "GET",
            'headers': myHeaders
        }).then(resp => resp?.json())
            .then((e) => {
                setName(e.first_name)
                setState(e)
            })
    }, []);



    const enviarInput = async (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).map(x => {
            if (!state[x]) {
                return err[x] = {
                    bol: true,
                    msg: 'Este campo no puede estar vacío'
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                }
            }
        })
        setError(err)

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/auth/profile/`, {
                'method': "PUT",
                'body': JSON.stringify(state),
                'headers': myHeaders
            }).then(resp => resp.json())
                .then((e) => {
                    if (e.id) {
                        setState(e)
                        setName(e.first_name)
                        toast.success('Usuario modificado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                        setReload(!reload)
                        reloadContainer(!bol)
                    } else {
                        toast.success('Ocurrio un error', {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    return (
        state ? (
            <CardProfile
                state={state}
                setState={setState}
                enviarInput={enviarInput}
                name={name}
                error={error}
            />
        ) :
            null
    )
}
