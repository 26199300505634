import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { getDashboardResume, getPayUser } from '../redux/action';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from 'reactstrap';
import Loading from '../component/Loading';

export default function Dashboard() {
    const dispatch = useDispatch();
    const [select, setSelect] = useState('today');
    const [dataDashboard, setDataDashboard] = useState()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(moment().subtract(1, 'months'));
    const [to, setTo] = useState(moment());
    const [help, setHelp] = React.useState('');

    useEffect(()=> {
        dispatch(getPayUser())
    }, [dispatch])

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const data = {
        labels: [],
        datasets: [
            {
                label: 'Ventas',
                data: [],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };
    const dataMediesOfPage = {
        labels: [],
        datasets: [
            {
                label: 'Mercado pago',
                data: [],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
            {
                label: 'Rapipago',
                data: [],
                fill: false,
                backgroundColor: 'rgb(102, 199, 190)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
            {
                label: 'Tranferencia bancaria',
                data: [],
                fill: false,
                backgroundColor: 'rgb(223, 184, 53)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };
    const mercadoPago = dataDashboard?.sales_by_means_of_payment['Mercado pago']
    const rapipago = dataDashboard?.sales_by_means_of_payment['Rapipago']
    const transferenciaBancaria = dataDashboard?.sales_by_means_of_payment['Transferencia bancaria']
    
    dataDashboard?.latest_sales?.forEach(element=>{
        data?.labels?.push(element?.date)
        data?.datasets[0].data.push(element?.value)
    })
    mercadoPago?.forEach(element => {
        dataMediesOfPage?.labels?.push(element?.date)
        dataMediesOfPage?.datasets[0].data.push(element?.value)
    })
    rapipago?.forEach(element => {
        dataMediesOfPage?.labels?.push(element?.date)
        dataMediesOfPage?.datasets[1].data.push(element?.value)
    })

    transferenciaBancaria?.forEach(element => {
        dataMediesOfPage?.labels?.push(element?.date)
        dataMediesOfPage?.datasets[2].data.push(element?.value)
    })


    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    const optionsMediesOfPage = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    useEffect(()=> {
        let _from = select === 'custom' ? from : null;
        let _to = select === 'custom' ? to : null;

        if(select !== 'custom' || (select === 'custom' && _from && _to)) {
            setLoading(true)
            dispatch(getDashboardResume(select, _from?.format('YYYY-MM-DD') , _to?.format('YYYY-MM-DD'), (data) => {
                setDataDashboard(data)
                setLoading(false)
            }))
        }
        return
    }, [dispatch, select, from, to])


    const userIsPayment = useSelector(stee => stee?.userPremiumPay)


    return (
        <>
            <Row>
                <Col xl="3" lg="6" md="6" className="mb-2">
                    <Dropdown className='card' isOpen={dropdownOpen} toggle={toggle} style={{backgroundColor:'#e15c93'}}>
                        <DropdownToggle caret>
                            Filtrar resumenes
                        </DropdownToggle>
                        <DropdownMenu >
                            {/* <DropdownItem 
                                disabled
                            >
                                Resumenes por
                            </DropdownItem> */}
                            <DropdownItem 
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelect('today')
                                }}
                            >
                                Dias
                            </DropdownItem>
                            <DropdownItem 
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelect('weekly')
                                }}
                            >
                                Esta semana
                            </DropdownItem>
                            <DropdownItem 
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelect('monthly')
                                }}
                            >
                                Este mes
                            </DropdownItem>
                            <DropdownItem 
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelect('yearly')
                                }}
                            >
                                Este año
                            </DropdownItem>
                            <DropdownItem 
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelect('custom')
                                }}
                            >
                                Personalizar
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col xl="9" lg="6" md="6" className="d-flex align-items-center mb-2">
                    {select !== 'custom' ? 'Resumen de' : ''}{'\u00A0'}
                    <b>
                    {select === 'today' ? 'Hoy' : ''}
                    {select === 'weekly' ? 'esta semana' : ''}
                    {select === 'monthly' ? 'este mes' : ''}
                    {select === 'yearly' ? 'este año' : ''}
                    </b>

                    {select === 'custom' ?
                    <div className='d-flex'>
                        <div className='d-flex align-items-center mr-2'>
                            <label className='mr-1 mb-0'>De:</label>
                            <input type="date" className='form-control' style={{width: 150}} value={from.format('YYYY-MM-DD')} max={to.format('YYYY-MM-DD')} onChange={(e) => setFrom(moment(e.target.value, 'YYYY-MM-DD'))} />
                        </div>
                        <div className='d-flex align-items-center'>
                            <label className='mr-1 mb-0'>Hasta:</label>
                            <input type="date" className='form-control' style={{width: 150}} value={to.format('YYYY-MM-DD')} min={from.format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setTo(moment(e.target.value, 'YYYY-MM-DD'))} />
                        </div>
                    </div>
                    : null}
                </Col>
            </Row>

            <div className='dashboard'>
                <Row>
                    {!userIsPayment?.plan?.is_free ?
                    <Col xl="4" lg="6" md="6" className="mb-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <span className="fs-6" style={{ fontWeight: 'bold' }}>Capital total</span>
                                <Row className="d-flex align-items-center justify-content-between">
                                    <Col sm="8">
                                        <h2 style={{ marginTop: ".25rem" }} className="font-weight-bolder h3">$ {dataDashboard?.total_available.toFixed(2)}</h2>
                                    </Col>
                                    <Col sm="4" className='text-right'>
                                        <span
                                            className="align-items-center"
                                            style={{ height: "45px", width: "45px", color: 'rgba(0,0,254,.5)' }}
                                        >
                                            <span className="b-avatar-custom">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30px"
                                                    height="30px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-dollar-sign"
                                                >
                                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </Col>
                                    <Col sm="12">
                                        <div className='text-information' style={{ fontSize: '.75rem', height: 40 }}>Ventas  +  Invertido + Stock  -  Gastos</div>
                                    </Col>
                                    <Col sm="12" style={{ margin: 'auto', marginTop: ".5rem" }}>
                                        <div className="progress" style={{ height: '.5rem' }}>
                                            <div
                                                className="progress-bar progress-bar-striped"
                                                role="progressbar"
                                                style={{ width: "100%", padding: '0 1rem', height: '100%', backgroundColor: 'rgba(0,0,254,.5)' }}
                                                aria-valuenow="100"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    : null}

                    <Col xl={!userIsPayment?.plan?.is_free ? 4 : 6} lg="6" md="6" className="mb-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <span className="fs-6" style={{ fontWeight: 'bold' }}>Total de ventas</span>
                                <Row className="d-flex align-items-center justify-content-between">
                                    <Col sm="8">
                                        <h2 style={{ marginTop: ".25rem" }} className="font-weight-bolder h3">$ {dataDashboard?.total_sales.toFixed(2)}</h2>
                                    </Col>
                                    <Col sm="4" className='text-right'>
                                        <span
                                            className="align-items-center"
                                            style={{ height: "45px", width: "45px", color: 'rgba(0,0,254,.5)' }}
                                        >
                                            <span className="b-avatar-custom" style={{ color: 'rgb(102,199,190)' }}>
                                                <svg
                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                    style={{ width: '35px', height: '35px' }}
                                                    data-testid="AccountBalanceIcon"
                                                >
                                                    <path d="M4 10h3v7H4zm6.5 0h3v7h-3zM2 19h20v3H2zm15-9h3v7h-3zm-5-9L2 6v2h20V6z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </Col>
                                    <Col sm="12">
                                        <div style={{ fontSize: '.75rem', height: 40 }}>En todas las ventas</div>
                                    </Col>
                                    <Col sm="12" style={{ margin: 'auto', marginTop: ".5rem" }}>
                                        <div className="progress" style={{ height: '.5rem' }}>
                                            <div
                                                className="progress-bar progress-bar-striped"
                                                role="progressbar"
                                                style={{ width: "100%", padding: '0 1rem', height: '100%', backgroundColor: 'rgb(102,199,190)' }}
                                                aria-valuenow="100"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>

                    {!userIsPayment?.plan?.is_free ?
                    <Col xl="4" lg="6" md="6" className="mb-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <span className="fs-6" style={{ fontWeight: 'bold' }}>Stock en $</span>
                                <HelpOutlineIcon
                                        disabled
                                        style={{ marginLeft: "1rem", fontSize: "large" }}
                                        onMouseEnter={() => {
                                            setHelp('stock_en_pesos')
                                        }}
                                        onMouseLeave={() => {
                                            setHelp('')
                                        }}
                                        onClick={()=> {
                                            setHelp('stock_en_pesos')
                                            setTimeout(() => {
                                                setHelp('')
                                            }, 1500)
                                            return
                                        }}
                                        id="stock_en_pesos"
                                    />
                                    <Tooltip placement="right" isOpen={help === "stock_en_pesos"} target="stock_en_pesos">
                                        Este valor se calcula utilizando los costos de tus materiales y productos
                                    </Tooltip>
                                    
                                <Row className="d-flex align-items-center justify-content-between">
                                    <Col sm="8">
                                        <h2 style={{ marginTop: ".25rem" }} className="font-weight-bolder h3">$ {dataDashboard?.capital_in_products.toFixed(2)}</h2>
                                    </Col>
                                    <Col sm="4" className='text-right'>
                                        <span
                                            className="align-items-center"
                                            style={{ height: "45px", width: "45px", color: 'rgba(0,0,254,.5)' }}
                                        >
                                            <span className="b-avatar-custom" style={{ color: 'rgb(223,184,53)' }}>
                                                <svg
                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                    data-testid="TimerIcon"
                                                    style={{ width: '35px', height: '35px' }}
                                                >
                                                    <path d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61 1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </Col>
                                    <Col sm="12">
                                        <div style={{ fontSize: '.75rem', height: 40 }}>Productos + Insumos</div>
                                    </Col>
                                    <Col sm="12" style={{ margin: 'auto', marginTop: ".5rem" }}>
                                        <div className="progress" style={{ height: '.5rem' }}>
                                            <div
                                                className="progress-bar progress-bar-striped"
                                                role="progressbar"
                                                style={{ width: "100%", padding: '0 1rem', height: '100%', backgroundColor: 'rgb(223,184,53)' }}
                                                aria-valuenow="100"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    : null}

                    {!userIsPayment?.plan?.is_free ?
                    <Col xl={!userIsPayment?.plan?.is_free ? 6 : 6} lg="6" md="6" className="mb-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <span className="fs-6" style={{ fontWeight: 'bold' }}>Total Bienes de Uso</span>
                                <Row className="d-flex align-items-center justify-content-between">
                                    <Col sm="8">
                                        <h2 style={{ marginTop: ".25rem" }} className="font-weight-bolder h3">$ {dataDashboard?.total_invested.toFixed(2)}</h2>
                                    </Col>
                                    <Col sm="4" className='text-right'>
                                        <span
                                            className="align-items-center"
                                            style={{ height: "45px", width: "45px", color: 'rgba(0,0,254,.5)' }}
                                        >
                                            <span className="b-avatar-custom" style={{ color: 'rgb(211,82,114)' }}>
                                                <svg
                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                    data-testid="TimerIcon"
                                                    style={{ width: '35px', height: '35px' }}
                                                >
                                                    <path d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61 1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </Col>
                                    <Col sm="12">
                                        <div style={{ fontSize: '.75rem', height: 40 }}>Inversiones totales</div>
                                    </Col>
                                    <Col sm="12" style={{ margin: 'auto', marginTop: ".5rem" }}>
                                        <div className="progress" style={{ height: '.5rem' }}>
                                            <div
                                                className="progress-bar progress-bar-striped"
                                                role="progressbar"
                                                style={{ width: "100%", padding: '0 1rem', height: '100%', backgroundColor: 'rgb(211,82,114)' }}
                                                aria-valuenow="100"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    : null}

                    <Col xl={!userIsPayment?.plan?.is_free ? 6 : 6} lg="6" md="6" className="mb-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <span className="fs-6" style={{ fontWeight: 'bold' }}>Total de Gastos</span>
                                <Row className="d-flex align-items-center justify-content-between">
                                    <Col sm="8">
                                        <h2 style={{ marginTop: ".25rem" }} className="font-weight-bolder h3">$ {dataDashboard?.spending.toFixed(2)}</h2>
                                    </Col>
                                    <Col sm="4" className='text-right'>
                                        <span
                                            className="align-items-center"
                                            style={{ height: "45px", width: "45px", color: 'rgba(0,0,254,.5)' }}
                                        >
                                            <span className="b-avatar-custom" style={{ color: 'rgb(211,82,114)' }}>
                                                <svg
                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                    data-testid="TimerIcon"
                                                    style={{ width: '35px', height: '35px' }}
                                                >
                                                    <path d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61 1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </Col>
                                    <Col sm="12">
                                        <div style={{ fontSize: '.75rem', height: 40 }}>Gastos totales</div>
                                    </Col>
                                    <Col sm="12" style={{ margin: 'auto', marginTop: ".5rem" }}>
                                        <div className="progress" style={{ height: '.5rem' }}>
                                            <div
                                                className="progress-bar progress-bar-striped"
                                                role="progressbar"
                                                style={{ width: "100%", padding: '0 1rem', height: '100%', backgroundColor: 'rgb(211,82,114)' }}
                                                aria-valuenow="100"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={window.innerWidth > 991 ? "mt-4" : null}>
                    <Col sm="12" md={!userIsPayment?.plan?.is_free ? 6 : 12} className="mb-2">
                        <div className="card h-100">
                            <div className='card-header pt-4'>
                                <h2 className="fs-4"> 
                                Ventas 
                                {select === 'today' ? ' de hoy' : ''}
                                {select === 'weekly' ? ' de esta semana' : ''}
                                {select === 'monthly' ? ' de este mes' : ''}
                                {select === 'yearly' ? ' de este año' : ''}
                                {select === 'custom' ? ` desde ${moment(from).format('DD/MM/YYYY')} hasta ${moment(to).format('DD/MM/YYYY')}` : ''}
                                </h2>
                            </div>
                            <div className="card-body pt-0">

                                <Line data={data} options={options} />
                            </div>
                        </div>
                    </Col>

                    {!userIsPayment?.plan?.is_free ?
                    <Col sm="12" md="6" className="mb-2">
                        <div className="card h-100">
                            <div className='card-header pt-3'>
                                <h2 className="fs-4"> Medios de pagos</h2>
                            </div>
                            <div className="card-body pt-0">
                                <Line data={dataMediesOfPage} options={optionsMediesOfPage} />
                            </div>
                        </div>
                    </Col>
                    : null}

                    {/* <Col sm="12" md="6" className="mb-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-header">
                                    <h2 className="fs-4">Resumen</h2>
                                </div>
                                <div className="card-body">
                                    <div className="modify-to-make d-flex justify-content-between">
                                        <div>
                                            <div className="d-flex align-items-center justify-content-flex-start">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" />
                                                        <label
                                                            className={
                                                                select === "today" ? "custom-control-label checked" : "custom-control-label"
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setSelect('today')
                                                            }}
                                                        >
                                                            Hoy
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-flex-start">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" />
                                                        <label
                                                            className={
                                                                select === "weekly" ? "custom-control-label checked" : "custom-control-label"
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setSelect('weekly')
                                                            }}
                                                        >
                                                            Esta semana
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-flex-start">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" />
                                                        <label
                                                            className={
                                                                select === "monthly" ?
                                                                    window.innerWidth > 991 ? "custom-control-label checked fs-7" : "custom-control-label checked"
                                                                    : window.innerWidth > 991 ? "custom-control-label fs-7" : "custom-control-label"
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setSelect('monthly')
                                                            }}
                                                        >
                                                            Este mes
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-flex-start">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    <div className="custom-control-primary custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input" />
                                                        <label
                                                            className={
                                                                select === "yearly" ? "custom-control-label checked" : "custom-control-label"
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setSelect('yearly')
                                                            }}
                                                        >
                                                            Este año
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (dataDashboard?.summary?.length <= 0)?null:(
                                            <Row className="mt-5">
                                                {
                                                    dataDashboard?.summary?.map((recibed, key) => {
                                                        return(
                                                            <Col sm="6" className="mb-2" key={key}>
                                                                <div className="d-flex flex-direction-column">
                                                                    <span className="fs-6" style={{ fontWeight: 400 }}>{recibed.label}</span>
                                                                    <h2 className="font-weight-bolder" style={{ marginTop: '.5rem', fontSize: '1.5rem' }}>$ {recibed.value}</h2>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        ) 
                                    }
                                </div>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </div>
            {loading ? <Loading /> : null}
        </>
    )
}
