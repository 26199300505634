import React from 'react';
import FilesUpload from './FilesUpload';
import CancelIcon from '@material-ui/icons/Cancel';

import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { deleteImage, getImage } from '../redux/action';

export default function ContainerFiles(props) {
    const [loading, setLoading] = React.useState(false)
    const [uploadingImage, setUploadingImage ] = React.useState(false)
    const [images, setImages] = React.useState([])
    const [deleteImg, setDeleteImg] = React.useState(false)
    const [imagesApi, setImagesApi ] = React.useState([])
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        // post
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(()=> {
        dispatch(getImage(props.state.id, setImagesApi, imagesApi))
    }, [])
    
    const handleDeleteImageApi = (e, id) => {
        e.preventDefault()
        const imageDeleted = imagesApi.filter(e=> e.id !==id)
        setImagesApi(imageDeleted)
        setDeleteImg(true)
        dispatch(deleteImage(id))
        setUploadingImage(!uploadingImage)
        const imageFiltred = props.state.images.filter(img => img !== id)
        props.setState({
            ...props.state,
            images: imageFiltred
        })
        setTimeout(()=> {
            setDeleteImg(false)
            //if()
        }, 1000)
    }

    return (
        <>
            {
                (!loading)?null:(
                    <div
                        className={window.innerWidth > 991 ?
                            "avatar-products col-md-3 margin1 min-width-10 mb-1" :
                            "avatar-products margin1 min-width-10 mb-1"
                        }
                    >
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                    </div>
                )
            }
            <>
                {
                    (props.state.images.length <= 0 && imagesApi.length <= 0)?null:(
                        imagesApi.map((img) => {
                            return(
                                <div
                                    key={img?.id}
                                    className={window.innerWidth > 991 ?
                                        "avatar-products col-md-3 margin1 min-width-10 mb-1" :
                                        "avatar-products margin1 min-width-10 mb-1"
                                    }
                                >
                                    {
                                        loading ? (
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        ) :(
                                            <>
                                                <button
                                                    className="border-none background-transparent cancel-img"
                                                    onClick={e => handleDeleteImageApi(e, img?.id)}
                                                >
                                                    <CancelIcon style={{marginTop: 5, marginRight: 5}} />
                                                </button>
                                                <img
                                                    alt="new"
                                                    src={
                                                        (img?.image.startsWith('h'))
                                                            ? img?.image
                                                            :`${process.env.REACT_APP_URL_API}${img?.image}`
                                                    }
                                                    className="max-height-100 width-100"
                                                />
                                            </>
                                        )
                                    }
                                </div>
                            )
                        })
                    )
                }
            </>
            {
                history.location.pathname.includes('view') ? null : (
                    <FilesUpload {...props} setImages={setImages} images={images} setLoading={setLoading} imagesApi={imagesApi} setUploadingImage={setUploadingImage}/>
                )
            }
        </>
    )
}