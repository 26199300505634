import React, { useEffect, useState } from 'react';
import ModalComponent from '../component/Modal';
import TableResponsive from '../component/Table';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { desactivateAllProduct, activateAllProduct, deleteProduct, getPayUser, getProducts, getWorkforce, pageProduct, getIsWholesaler } from '../redux/action';

let showIsActive = 'true'
let showProvider = ''

export default function Products() {
    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [sortData, setSortData] = useState('');
    const [id, setId] = useState(undefined);
    const [reload, setReload] = useState(false);
    const [deleteAll, setDeleteAll] = useState();
    const [modalDelete, setModalDelete] = useState(false)
    const [disabled, setDisabled] = React.useState(false);
    const [suppliesProduct, setSuppliesProduct] = useState([])
    const [filterIsActive, setFilterIsActive] = useState(showIsActive);
    const [filterProvider, setFilterProvider] = useState(showProvider);
    const [isWholesale, setIsWholesale] = useState(false);
    const [providers, setProviders] = useState([])
    

    const newProductTrue = useSelector(pedro => pedro?.newProduct);

    useEffect(() => {
        dispatch(getProducts())
        dispatch(getWorkforce())
        dispatch(getPayUser())
    }, [dispatch])

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        dispatch(getIsWholesaler(function(value) {
            setTimeout(() => {
                setIsWholesale(value === 'true')
            }, 300)
        }))        

        fetch(`${process.env.REACT_APP_URL_API}/api/products/?page=${page}&is_active=${showIsActive}${search.length > 2 ? '&search=' + search : ''}${showProvider !== '' ? '&provider=' + showProvider : ''}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.detail) {
                    setPage(page-1)
                } else {
                    setData(json)
                }
            })
            
        // if( search.length >= 1 ) {
        //     return
        // } else {
        //     fetch(`${process.env.REACT_APP_URL_API}/api/products/?page=${page}&is_active=${showIsActive}`, {
        //         'method': "GET",
        //         'headers': myHeaders
        //     })
        //         .then(resp => resp?.json())
        //         .then(json => {
        //             if(json.detail) {
        //                 setPage(page-1)
        //             } else {
        //                 setData(json)
        //             }
        //         })
        // }
    
        fetch(`${process.env.REACT_APP_URL_API}/api/providers/?pagination=false`, {
            method: 'GET',
            headers: myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                setProviders(json)
            })       
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, disabled, page, dispatch, newProductTrue])

    useEffect(() => {
        if (id !== undefined) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies?product=${id}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    setSuppliesProduct(json.results)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleSearchChangeProduct = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        setSearch(() => e.target.value)

        if (e.target.value.length > 2) {
            return (
                fetch(`${process.env.REACT_APP_URL_API}/api/products/?search=${e.target.value}`, {
                    method: 'GET',
                    headers: myHeaders
                })
                    .then(resp => resp.json())
                    .then(json => {
                        setData(json)
                    })
            )
        } else {
            return setReload(!reload)
        }

        
    }

    const filterProducts = (e, field) => {
        e.preventDefault();
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false);
        }, 1500)

        showIsActive = filterIsActive
        if(field === 'is_active') {
            showIsActive = e.target.value
            setFilterIsActive(e.target.value)
        }

        showProvider = filterProvider
        if(field === 'provider') {
            showProvider = e.target.value
            setFilterProvider(e.target.value)
        }

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/products/?is_active=${showIsActive}&provider=${showProvider}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        // alert(e.target.value)
        /*  FILTRADO  */
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${process.env.REACT_APP_URL_API}/api/products/?wholesaler=${e.target.value}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setData(json)
            })

        if(e.target.value === 'true'){
            setSortData('True')
            return
        }

        if(e.target.value === 'false'){
            setSortData('False')
            return
        }

        if(e.target.value === 'Filtrar'){
            setSortData('Filter')
            return
        }
    }

    if( sortData==='True' ) {
        data?.results?.sort(function(x, y) {
            return (x.wholesaler === y.wholesaler)? 0 : x.wholesaler? -1 : 1;
        });
    }

    if( sortData==='False' ) {
        data?.results?.sort(function(x, y) {
            return (x.wholesaler === y.wholesaler)? 0 : x.wholesaler? 1 : -1;
        });
    }

    const onDelete = (e) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const element = data.results.filter(el => parseInt(el.id) === parseInt(id))[0];
        if (element.product_type === "resale") {
            dispatch(deleteProduct(id))
            setReload(!reload)
        } else {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            const arr = suppliesProduct.length && suppliesProduct.map(async (sup) => {
                return await deleteSuppliesProduct(sup)
            })

            Promise?.all(arr)
                .then(() => {
                    fetch(`${process.env.REACT_APP_URL_API}/api/products/${id}`, {
                        'method': "DELETE",
                        'headers': myHeaders
                    })
                        .then(() => {
                            toast.success('Producto eliminado exitosamente', {
                                className: "Toastify__toast--success"
                            })
                            setReload(!reload)
                        })
                        .catch(() => {
                            toast.success('Ha ocurrido un error. Intente nuevamente', {
                                className: "Toastify__toast--error"
                            })
                        })
                })
        }
    }

    function deleteSuppliesProduct(sup) {
        return new Promise(resolve => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            fetch(`${process.env.REACT_APP_URL_API}/api/product-supplies/${sup.id}`, {
                'method': 'DELETE',
                'headers': myHeaders
            });
            resolve()
        })
    }

    const onDesactivateAll = (e) => {
        e.preventDefault();
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        dispatch(desactivateAllProduct(deleteAll))
        setDeleteAll([])
    }

    const onActivateAll = (e) => {
        e.preventDefault();
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        dispatch(activateAllProduct(deleteAll))
        setDeleteAll([])
    }

    const pageData = (e, url) => {
        e.preventDefault();
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        dispatch(pageProduct(url))
    }

    return (
        <>
            {
                modalDelete ? (
                    <ModalComponent
                        open={modalDelete}
                        title={deleteAll && deleteAll.length === 1 ?
                            "Desactivar producto" : "Desactivar producto"
                        }
                        body={deleteAll && deleteAll.length === 1 ? `¿Esta segura de ${showIsActive === 'true' ? 'desactivar' : 'activar'} este producto?` : `¿Esta segura de ${showIsActive === 'true' ? 'desactivar' : 'activar'} ${deleteAll.length} producto?`}
                        setModal={setModalDelete}
                        onDelete={(e) => showIsActive === 'true' ? onDesactivateAll(e) : onActivateAll(e)}
                        label={showIsActive === 'true' ? 'Desactivar' : 'Activar'}
                    />
                ) : null
            }
            {
                modal ? (
                    <ModalComponent
                        open={modal}
                        title={"Desactivar producto"}
                        body={`¿Esta segura de ${showIsActive === 'true' ? 'desactivar' : 'activar'} este producto?`}
                        setModal={setModal}
                        onDelete={(e) => showIsActive === 'true' ? onDesactivateAll(e) : onActivateAll(e)}
                        label={showIsActive === 'true' ? 'Desactivar' : 'Activar'}
                    />
                ) : null
            }
            <TableResponsive
                data={data}
                search={search}
                setSearch={setSearch}
                handleSearchChangeProduct={handleSearchChangeProduct}
                handleSubmit={handleSubmit}
                filterProducts={filterProducts}
                toCreate={"/products/create"}
                toEdit={'/products/edit'}
                modal={modal}
                setModal={setModal}
                setId={(id) => setDeleteAll([String(id)])}
                page={page}
                setPage={setPage}
                next={data?.next}
                previous={data?.previous}
                pageData={pageData}
                reload={reload}
                setReload={setReload}
                id={id}
                setDeleteAll={setDeleteAll}
                setModalDelete={setModalDelete}
                disabled={disabled}
                showIsActive={showIsActive}
                isWholesale={isWholesale}
                providers={providers}
            />
        </>
    )
}