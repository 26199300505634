import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';
import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import Inbox from '@material-ui/icons/Inbox';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

export default function Dropdown({ setId, toEdit, e, setModal, modal, setModalDeleteOne, modalProduce, setModalProduce, customOptions=[], setModalDetails }) {
    const [open, setOpen] = useState(false)
    const history = useHistory();
    const toggle = () => {
        setOpen(false)
        setId(null)
    }

    return (
        history.location.pathname.includes('commercial') ? (
            <ButtonDropdown
                direction={'right'}
                onClick={() => {
                    setOpen(!open)
                    setId(e.id)
                }}
                isOpen={open}
                toggle={e => toggle(e)}
            >
                <DropdownToggle caret className="btn">
                    <MoreVertIcon style={{ color: "rgba(0,0,0,.8" }} />
                </DropdownToggle>
                <DropdownMenu className="card">
                    <div className="card-body padding-0">
                        <div className="d-flex align-items-center user-item dropdown-item justify-content-flex-start">
                            <DropdownItem
                                style={{ paddingLeft: 0 }}
                                onClick={() => toEdit(e.id)}
                                className="d-flex align-items-center"
                            >
                                <CreateIcon style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>Editar</p>
                            </DropdownItem>
                        </div>
                        {customOptions.map((option, key) => {
                            return (
                                <div key={key} className="d-flex align-items-center user-item dropdown-item justify-content-flex-start">
                                    <DropdownItem
                                        style={{ paddingLeft: 0 }}
                                        onClick={() => option.onClick(e.id)}
                                        className="d-flex align-items-center"
                                    >
                                        {option.icon}
                                        <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>{option.label}</p>
                                    </DropdownItem>
                                </div>
                            )
                        })}
                        <div className="d-flex align-items-center user-item dropdown-item justify-content-flex-start">
                            <DropdownItem
                                onClick={() => {
                                    setModalDeleteOne(true)
                                }}
                                style={{ paddingLeft: 0 }}
                                className="d-flex align-items-center"
                            >
                                <DeleteIcon style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>Eliminar</p>
                            </DropdownItem>
                        </div>
                    </div>
                </DropdownMenu>
            </ButtonDropdown >
        ) :
            (
                <ButtonDropdown
                    direction={'right'}
                    onClick={() => {
                        setOpen(!open)
                        setId(e.id)
                    }}
                    isOpen={open}
                    toggle={e => toggle(e)}
                >
                    <DropdownToggle caret className="btn">
                        <MoreVertIcon style={{ color: "rgba(0,0,0,.8" }} />
                    </DropdownToggle>
                    <DropdownMenu className="card">
                        <div className="card-body padding-0">
                            <div className="d-flex align-items-center user-item dropdown-item justify-content-flex-start" onClick={() => history.push(`${toEdit}/${e.id}`)}>
                                <DropdownItem style={{ paddingLeft: 0 }}>

                                    <div
                                        className="d-flex align-items-center"
                                    >
                                        <CreateIcon style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                        <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>Editar</p>
                                    </div>
                                </DropdownItem>
                            </div>
                            {
                                history.location.pathname.includes('invested') ?
                                    <div className="d-flex align-items-center user-item dropdown-item justify-content-flex-start" onClick={() => {
                                        console.log("ver: ", e)
                                        setModalDetails(e)}}>
                                        <DropdownItem style={{ paddingLeft: 0 }}>

                                            <div
                                                className="d-flex align-items-center"
                                            >
                                                <VisibilityIcon style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                                <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>Ver</p>
                                            </div>
                                        </DropdownItem>
                                    </div>
                                : null
                            }
                            <div className="d-flex align-items-center user-item dropdown-item justify-content-flex-start"
                                onClick={() => {
                                    setModal(!modal)
                                }}
                            >
                                <DropdownItem
                                    style={{ paddingLeft: 0 }}
                                    className="card-header d-flex align-items-center"
                                >
                                    {!history.location.pathname.includes('products') ?
                                        <DeleteIcon style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                    :
                                        <Inbox style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                    }
                                    <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>{!history.location.pathname.includes('products') ? 'Eliminar' : (e.is_active ? 'Desactivar' : 'Activar')}</p>
                                </DropdownItem>
                            </div>
                            {e.product_type === 'production' ?
                            <>
                                <div className="d-flex align-items-center user-item dropdown-item justify-content-flex-start" onClick={() => setModalProduce(e, 'Producir')}>
                                    <DropdownItem style={{ paddingLeft: 0 }}>

                                        <div
                                            className="d-flex align-items-center"
                                        >
                                            <ArrowUpwardOutlined style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                            <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>Producir</p>
                                        </div>
                                    </DropdownItem>
                                </div>
                                <div className="d-flex align-items-center user-item dropdown-item justify-content-flex-start" onClick={() => setModalProduce(e, 'Reducir stock')}>
                                    <DropdownItem style={{ paddingLeft: 0 }}>

                                        <div
                                            className="d-flex align-items-center"
                                        >
                                            <ArrowDownwardOutlined style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />
                                            <p className="fs-6" style={{ color: "rgba(0,0,0,.8", margin: 0 }}>Reducir stock</p>
                                        </div>
                                    </DropdownItem>
                                </div>
                            </>
                            : null}
                        </div>
                    </DropdownMenu>
                </ButtonDropdown >
            )
    )
}