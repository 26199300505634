import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropdown from './DropDownMenu';

export default function TablePackaging ({ data, toEdit, setModal, modal, setId, setDeleteAll, setModalDelete, disabled }) {
    const [checkbox, setCheckbox] = React.useState({})
    const [all, setAll] = React.useState(false)

    React.useEffect(() => {
        setCheckbox({})
    }, [])

    const onChecked = (a) => {
        setCheckbox({
            ...checkbox,
            [a]: !checkbox[a]
        })
    }

    const handleDelete = () => {
        if (checkbox.all) {
            const arr = []
            data.map(el => arr.push(el.id))
            setDeleteAll(arr)
        } else {
            setDeleteAll(Object.keys(checkbox).filter(x => checkbox[x] === true))
        }
        setModalDelete(true)
        return setCheckbox({})
    }

    return (
        <>
            <Row>
                <Col className="mb-1" sm="12">
                    <Table responsive className="align-middle">
                        <thead>
                            <tr>
                                <th className="text-align-center checkboxContainer">
                                    <label className="containerCheckbox">
                                        <input
                                            type="checkbox"
                                            className={
                                                Object.values(checkbox).filter(x => x === true).length === data.length
                                                ? "checked" : ""
                                            }
                                            onClick={() => {
                                                setAll(!all)
                                                const obj = {}
                                                data.map(el => {
                                                    return obj[el.id] = !all
                                                })
                                                setCheckbox(obj)
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                                <th>Nombre</th>
                                {
                                    window.innerWidth < 992 ? (
                                        null
                                    ) : (
                                        <>
                                            <th>Costo</th>
                                            <th>Tipo de packaging</th>
                                            <th>Stock actual en unidades</th>
                                            <th>Unidad de compra</th>
                                        </>
                                    )
                                }
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((e, i = -1) => {
                                    i++
                                    return (
                                        <tr key={e.id} >
                                            <th className="text-align-center checkboxContainer">
                                                {
                                                    checkbox[e.id] ? (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                className="checked"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    ) : (
                                                        <label className="containerCheckbox">
                                                            <input
                                                                type="checkbox"
                                                                onClick={() => {
                                                                    onChecked(e.id)
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                }
                                            </th>
                                            <th>
                                                <Link to={`/packaging/edit/${e.id}`}>
                                                    {e.name}
                                                </Link>
                                            </th>
                                            {
                                                window.innerWidth < 992 ? (
                                                    null
                                                ) : (
                                                    <>
                                                        {
                                                            (e.cost_group_unit === null)
                                                                ? (
                                                                    <th>
                                                                        {e.cost_unit || "-"}
                                                                    </th>
                                                                )
                                                                : (
                                                                    <th>
                                                                        {e.cost_group_unit || "-"}
                                                                    </th>
                                                                )
                                                        }
                                                        <th>{e.packaging_type === "simple" ? "Simple" : "Agrupado" || "-"}</th>
                                                        <th>{e.current_stock_in_units || "-"}</th>
                                                        <th>{e.unit_measurement.replace('cm²', 'm²') || "-"}</th>
                                                    </>
                                                )
                                            }
                                            <th className="text-end">
                                                <Dropdown setId={setId} toEdit={toEdit} e={e} setModal={setModal} modal={modal} />
                                            </th>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
                {
                    Object.values(checkbox).filter(x => x === true).length ? (
                        <Col>
                            <footer>
                                {
                                    !disabled ? (
                                        <button
                                            className="btn background-secondary br-btn-t color-white color-white"
                                            onClick={() => handleDelete()}
                                        >
                                            Eliminar seleccionados
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </footer>
                        </Col>
                    ) : null
                }
            </Row>
        </>
    )
}