import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import  Logo  from '../assets/img/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { cancelPremium, getPayUser, getPremium, resetPremiumUser, setPayUser } from '../redux/action'
import ModalPremium from '../component/ModalPremium'
import { ModalCancelPremium } from '../component/ModalCancelPremium'
import LogoTXT from '../component/LogoTXT'

export const Premium = () => {
    //is_free=false (Quiere decir que es premium)
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(true)
    const [ plans, setPlans ] = useState()
    const [ errorPay, setErrorPay ] = useState(false)
    const [ acceptPay, setAcceptPay ] = useState(false)
    const [ stopPremium ,setStopPremium ] = useState(false)
    const [ returnFree, setReturnFree ] = useState(false)
    const [ alternate, setAlternate ] = useState(false)
    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    useEffect(() => {
        let url = document.referrer !== '' ? new URL(document.referrer) : null;
        if(url && url.hostname === 'www.mercadopago.com.ar') {
            let collection_id = url.searchParams.get('collection_id');
            let collection_status = url.searchParams.get('collection_status');
            let preference_id = url.searchParams.get('preference_id');
            let payment_type = url.searchParams.get('payment_type');
            let payment_id = url.searchParams.get('payment_id');
            let external_reference = url.searchParams.get('external_reference');
            let site_id = url.searchParams.get('site_id');
            let status = url.searchParams.get('status');
            let preapproval_id = new URL(document.location.href).searchParams.get('preapproval_id')
            
            const data = {
                collection_id,
                collection_status,
                preference_id,
                payment_type,
                payment_id,
                external_reference,
                site_id,
                status,
                preapproval_id
            }
        
            if(data.status !== 'approved') {
                setErrorPay(true)
                alert('Recibimos un error de Mercado Pago sobre tu pago.')
            } else {
                fetch(
                    `${process.env.REACT_APP_URL_API}/api/payments/process_payment/`,
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${localStorage.getItem('flor_emprendedora')}`
                        }
                    }
                )
                    .then(resp => resp.json())
                    .then(json => {
                        dispatch(setPayUser(json))
                        setAcceptPay(true)
                    })
            }
        }
    }, [])

    useEffect(()=> {
        setLoading(true)
        dispatch(getPremium(setPlans, setLoading))
        dispatch(getPayUser())
        return
    }, [dispatch])
    let free;
    let premium;
    let matchPremiumItems;
    let matchFreeItems
    if (!loading) {
        free = plans?.data?.find(ele => ele.name === "Gratis")
        premium = plans?.data?.find(ele => ele.name === "Pro")
        const premiumItems = premium.items
        const freeItems = free.items
        matchPremiumItems = premiumItems.split(";")
        matchFreeItems = freeItems.split(";")
    }

    useEffect(()=> {}, [loading, free, premium])
    
    const handleSubmit = (preapproval_plan_id) => {
        window.location.href=`https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=${preapproval_plan_id}`
    }
    const handleResetPay = (preapproval_plan_id) => {
        dispatch(resetPremiumUser(preapproval_plan_id))
    }

    const isPremium = useSelector(is => is?.userPremiumPay)

    useEffect(() => {
        (userIsPayment?.plan?.is_free === false)
        ? (userIsPayment?.payment_data?.status === "paused" || userIsPayment?.payment_data?.status === "cancelled") ? setAlternate(true) : setAlternate(false)
        : setAlternate(false)
    }, [userIsPayment])

    return (
        <>
            {
                (alternate)
                    ?(
                        <>
                            {
                                (!returnFree)?null:(
                                    <ModalCancelPremium
                                        title={"Volver a gratis"}
                                        body={"¿Estas seguro que deseas volver al plan gratis?"}
                                        button={"Aceptar"}
                                        open={returnFree}
                                        setModal={setReturnFree}
                                    />
                                )
                            }
                            <Row className="d-flex justify-content-center">
                                <div className="text-center mb-1">
                                    <h1>Tu subscripción no fue aprobada.</h1>
                                </div>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col md="6" sm="6" className="mb-1">
                                    <div className="card text-center h-100">
                                        <div className="card-body d-flex flex-direction-column align-items-center">
                                            <LogoTXT style={{marginBottom: 30}} />
                                            {/* <img src={Logo} alt='svg img' className='mb-1' style={{width: '60%'}}/> */}
                                            <h3>{free?.name}</h3>
                                            <p className='card-text'>{free?.description}</p>
                                            <div className='annual-plan'>
                                                <div className='plan-price mt-2'>
                                                    <sup className="font-medium-1 font-weight-bold text-primary" style={{left: '0', top: '-3.5rem', position: 'relative'}}>$</sup>
                                                    <sup className="pricing-basic-value font-weight-bolder text-primary" style={{fontSize: '3.5rem', lineHeight: '2'}}>{free?.value}</sup>
                                                    <sup className="pricing-duration text-body font-medium-1 font-weight-bold" style={{left: '0', top: '-1.9rem', position: 'relative'}}>/al mes</sup>
                                                </div>
                                                <small className="annual-pricing text-muted" style={{display: 'none'}}>USD {free?.value} / year</small>
                                            </div>
                                            <div className="list-group text-left">
                                                {
                                                    matchFreeItems?.map(el => {
                                                        return(
                                                            el ? <div className={`list-group-item border-0 d-flex  ${el.includes('()') ? 'text-muted' : ''}`}><span className="material-icons text-primary" style={{fontSize: 20, marginTop: 2, visibility: el.includes('(x)') ? 'visible' : 'hidden'}}>check</span> {el.replace('()', '').replace('(x)', '').trim()}</div> : null
                                                        )
                                                    })
                                                }
                                            </div>
                                            <button type='button' className="btn mt-auto btn-outline-success btn-block" onClick={()=>setReturnFree(true)}>Volver al plan gratuito</button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6" sm="6" className="mb-1">
                                    <div className="card text-center h-100">
                                        <div className="card-body d-flex flex-direction-column align-items-center">
                                            <LogoTXT style={{marginBottom: 30}} />
                                            {/* <img src={Logo} alt='svg img' className='mb-1' style={{width: '60%'}}/> */}
                                            <h3>{premium?.name}</h3>
                                            <p className='card-text' style={{minHeight: 50}}>{premium?.description}</p>
                                            <div className='annual-plan'>
                                                <div className='plan-price mt-2'>
                                                    <sup className="font-medium-1 font-weight-bold text-primary" style={{left: '0', top: '-3.5rem', position: 'relative'}}>$</sup>
                                                    <sup className="pricing-basic-value font-weight-bolder text-primary" style={{fontSize: '3.5rem', lineHeight: '2'}}>{premium?.value}</sup>
                                                    <sup className="pricing-duration text-body font-medium-1 font-weight-bold" style={{left: '0', top: '-1.9rem', position: 'relative'}}>/al mes</sup>
                                                </div>
                                                <small className="annual-pricing text-muted" style={{display: 'none'}}>USD {premium?.value} / year</small>
                                            </div>
                                            <div className="list-group list-group text-left">
                                                {   
                                                    matchPremiumItems?.map(el => {
                                                        return(
                                                            el ? <div className={`list-group-item border-0 d-flex  ${el.includes('()') ? 'text-muted' : ''}`}><span className="material-icons text-primary" style={{fontSize: 20, marginTop: 2, visibility: el.includes('(x)') ? 'visible' : 'hidden'}}>check</span> {el.replace('()', '').replace('(x)', '').trim()}</div> : null
                                                        )
                                                    })
                                                }
                                            </div>
                                            <button type='button' className="btn mt-auto btn-primary btn-block" onClick={(e)=> handleResetPay(premium?.preapproval_plan_id)}>Volver a realizar la suscripción</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )
            :(
                <>
                    {
                        (!errorPay)?null:(
                            <ModalPremium
                                title={"Error"}
                                body={"No se pudo procesar el pago correctamente."}
                                open={errorPay}
                                setModal={setErrorPay}
                            />
                        )
                    }
                    {
                        (!acceptPay)?null:(
                            <ModalPremium
                                title={"Pagado"}
                                body={"Ya tenés la Calcu Pro. ¡A tu negocio no lo para nadie!"}
                                type={'modal-success'}
                                open={acceptPay}
                                setModal={setAcceptPay}
                            />
                        )
                    }
                    {
                        (!stopPremium)?null:(
                            <ModalCancelPremium
                                title={"Volver a Gratis"}
                                body={"¿Estas seguro que deseas volver a gratis?"}
                                button={"Confirmar"}
                                open={stopPremium}
                                setModal={setStopPremium}
                            />
                        )
                    }
                    <>
                        <Row className="d-flex justify-content-center">
                            <div className="text-center">
                                <h1>Planes y Precios</h1>
                                <p className='mb-2 pb-75'>Los planes incluyen +40 herramientas para potenciar tus productos. Elige el mejor plan que necesites.</p>
                            </div>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Col lg="6" sm="6" xs="12" className="mb-1">
                                <div className="card text-center h-100">
                                    <div className="card-body d-flex flex-direction-column align-items-center">
                                        <LogoTXT style={{marginBottom: 30}} />
                                        <img src={Logo} alt='svg img' className='mb-1' style={{width: '70px'}}/>
                                        <h3>{free?.name}</h3>
                                        <p className='card-text'>{free?.description}</p>
                                        <div className='annual-plan'>
                                            <div className='plan-price mt-2'>
                                                <sup className="font-medium-1 font-weight-bold text-primary" style={{left: '0', top: '-3.5rem', position: 'relative'}}>$</sup>
                                                <sup className="pricing-basic-value font-weight-bolder text-primary" style={{fontSize: '3.5rem', lineHeight: '2'}}>{free?.value}</sup>
                                                <sup className="pricing-duration text-body font-medium-1 font-weight-bold" style={{left: '0', top: '-1.9rem', position: 'relative'}}>/al mes</sup>
                                            </div>
                                            <small className="annual-pricing text-muted" style={{display: 'none'}}>USD {free?.value} / year</small>
                                        </div>
                                        <div className="list-group text-left">
                                            {
                                                matchFreeItems?.map(el => {
                                                    return(
                                                        el ? <div className={`list-group-item border-0 d-flex  ${el.includes('()') ? 'text-muted' : ''}`}><span className="material-icons text-primary" style={{fontSize: 20, marginTop: 2, visibility: el.includes('(x)') ? 'visible' : 'hidden'}}>check</span> {el.replace('()', '').replace('(x)', '').trim()}</div> : null
                                                    )
                                                })
                                            }
                                        </div>
                                        <button type='button' className="btn mt-auto btn-outline-success btn-block" disabled={isPremium?.plan?.is_free} onClick={() => !isPremium?.plan?.is_free ? setStopPremium(true) : null}>{isPremium?.plan?.is_free ? 'Este es tu plan' : 'Volver a Gratis'}</button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" sm="6" xs="12" className="mb-1">
                                <div className="card text-center h-100">
                                    <div className="card-body d-flex flex-direction-column align-items-center">
                                        <LogoTXT style={{marginBottom: 30}} />
                                        <img src={Logo} alt='svg img' className='mb-1' style={{width: '70px'}}/>
                                        <h3>{premium?.name}</h3>
                                        <p className='card-text' style={{minHeight: 50}}>{premium?.description}</p>
                                        <div className='annual-plan'>
                                            <div className='plan-price mt-2'>
                                                <sup className="font-medium-1 font-weight-bold text-primary" style={{left: '0', top: '-3.5rem', position: 'relative'}}>$</sup>
                                                <sup className="pricing-basic-value font-weight-bolder text-primary" style={{fontSize: '3.5rem', lineHeight: '2'}}>{premium?.value}</sup>
                                                <sup className="pricing-duration text-body font-medium-1 font-weight-bold" style={{left: '0', top: '-1.9rem', position: 'relative'}}>/al mes</sup>
                                            </div>
                                            <small className="annual-pricing text-muted" style={{display: 'none'}}>USD {premium?.value} / year</small>
                                        </div>
                                        <div className="list-group list-group text-left">
                                            {
                                                matchPremiumItems?.map(el => {
                                                    return(
                                                        el ? <div className={`list-group-item border-0 d-flex  ${el.includes('()') ? 'text-muted' : ''}`}><span className="material-icons text-primary" style={{fontSize: 20, marginTop: 2, visibility: el.includes('(x)') ? 'visible' : 'hidden'}}>check</span> {el.replace('()', '').replace('(x)', '').trim()}</div> : null
                                                    )
                                                })
                                            }
                                        </div>
                                        <button type='button' className="btn mt-auto btn-primary btn-block" disabled={!isPremium?.plan?.is_free} onClick={(e)=> isPremium?.plan?.is_free ? handleSubmit(premium?.preapproval_plan_id) : null}>{isPremium?.plan?.is_free ? 'Pagar' : 'Este es tu plan'}</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                </>
            )}
        </>
    )
}
