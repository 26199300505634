import React, { useEffect, useState } from 'react';
import FormClients from '../component/FormClients';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import ModalComponent from '../component/Modal';
import Loading from '../component/Loading';

export default function EditClient() {
    const { id } = useParams();
    const [state, setState] = useState();
    const history = useHistory();
    const [disabled, setDisabled] = React.useState(false)
    const [modal, setModal] = useState(false);
    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        material: {
            bol: false,
            msg: ''
        },
        address: {
            bol: false,
            msg: ''
        },
        neighborhood: {
            bol: false,
            msg: ''
        },
        city: {
            bol: false,
            msg: ''
        },
        identification: {
            bol: false,
            msg: ''
        },
        telephone: {
            bol: false,
            msg: ''
        },
        method_shipping: {
            bol: false,
            msg: ''
        }
    })

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {
            identification: {
                bol: false,
                msg: ''
            }
        }

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (x === "name") {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            setDisabled(true)
            fetch(`${process.env.REACT_APP_URL_API}/api/clients/${id}/`, {
                'method': "PUT",
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    setDisabled(false)
                    if(json.id) {
                        toast.success('Cliente modificado exitosamente', {
                            className: "Toastify__toast--success"
                        })
                        setState(json);
                        history.push('/clients')
                    } else{ 
                        toast.success(json.name, {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/clients/${id}/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setState(json)
            })
    }, [id])

    const handleDelete = (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/clients/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then((res) => {
                if(res.status === 204) {
                    toast.success('Cliente eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                    history.push('/clients')
                } else {
                    toast.success('No se puede eliminar este cliente', {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }
    
    return (
        !state ? <Loading /> : (
            <>
                <ModalComponent 
                    open={modal} 
                    title={"Eliminar cliente"} 
                    body={"¿Esta segura de eliminar este cliente?"} 
                    setModal={setModal} 
                    onDelete={handleDelete}
                />
                <FormClients 
                    title={"Editar cliente"} 
                    state={state} 
                    setState={setState} 
                    errors={errors} 
                    back={'/clients'} 
                    onSubmit={onSubmit} 
                    id={id}
                    setModal={setModal}
                    disabled={disabled}
                />
            </>
        )
    )
}