import React, { useState } from 'react';
import { Row, Col, ButtonGroup, Spinner } from 'react-bootstrap';
// import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from './Pagination';
import TableClients from './TableClients';
import TableProducts from './TableProducts';
import TableSupplies from './TableSupplies';
import TableVendors from './TableVendors';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableIncomes from './TableIncomes';
import TableWithdrawals from './TableWithdrawals';
import TablePackaging from './TablePackaging';
import { TableInvested } from './TableInvested';
import { ModalFilter } from './ModalFilter';
// import { DateRange } from 'react-date-range';
// import { es } from 'react-date-range/src/locale/index'
import moment from 'moment';

export default function TableResponsive({
    errorSearch, handleSearchChangeProduct, data, deleteAll,suppliesIsOff, setSearch, search, handleSubmit, filterProducts, toCreate, toCreateWorkforce, toEdit, setModal, modal, setId, page, setPage, next, previous, pageData, reload, setReload, id, setDeleteAll, setModalDelete, disabled, onModalCreate, showIsActive, isWholesale, setFilter, stateDateRange, setStateDateRange, filter, changeFilter, providers
}) {
    const [showFilter, setShowFilter] = useState(false)
    const [state, setState] = React.useState(data)
    const [changeData, setChangeData] = React.useState(false)
    const [modalDate, setModalDate] = React.useState(false)
    const [order, setOrder] = React.useState('')

    const history = useHistory()

    const handleSearchChange = (e) => {
        setSearch(() => e.target.value)
        if (e.target.value.length > 2) {
            return handleSubmit(e)
        } else {
            return setReload(!reload)
        }
    }

    const handleSearchDateChange = (e) => {
        
        const filtredDate = data?.results.filter(f => f.created_at.startsWith(`${e?.target?.value}`))
        
        if(filtredDate.length >= 1) {
            setChangeData(true)
            setState(filtredDate)
        } 
        return
    }

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    return (
        <>
            <div className="card mt-1">
                <div className="card-body">
                    <nav>
                        <div className="">
                            <div className={"filter-income mb-1 d-flex justify-content-flex-end"}>
                            {toCreate.includes('withdrawals') ? 
                                <button
                                    type='button'
                                    className="btn btn-primary mr-5"
                                    // style={{background: 'grey', borderTopLeftRadius: '4px', borderBottomLeftRadius:'4px'}}
                                    onClick={(e)=> setFilter('date=' + moment().format('YYYY-MM-DD'))}
                                >
                                    Pagos del mes
                                </button>
                            : null}

                                <div 
                                    // sm={(history.location.pathname.includes('incomes')?"11":"3")} 
                                    style={history.location.pathname.includes('supplies') || history.location.pathname.includes('packaging')?{width: 300}: window.innerWidth > 991 ? null : history.location.pathname.includes('incomes')? {width: "100%"} : history.location.pathname.includes('products')?{width: "66.4%"}:history.location.pathname.includes('supplies')||history.location.pathname.includes('packaging')?window.innerWidth > 991 ? null : { width: "100%", marginBottom: '1rem'}: { width: "50%"}}
                                >   
                                    <div className="input-group input-group-md">
                                
                                        {
                                            history.location.pathname.includes('products') ? (
                                                <>
                                                    <input
                                                        id="filterInput"
                                                        className="form-control mr-1 rounded"
                                                        value={search}
                                                        onChange={(e) => handleSearchChangeProduct(e)}
                                                        type="text"
                                                        placeholder={'Buscar'}
                                                    />

                                                    {isWholesale ?
                                                    <select
                                                        className={"form-control p-form btn-sm br-btn-t rounded"}
                                                        style={{height: 38}}
                                                        onChange={e => {
                                                            handleSubmit(e)
                                                        }}
                                                    >
                                                        <option default>Filtrar</option>
                                                        <option value={true}>Mayorista</option>
                                                        <option value={false}>Minorista</option>
                                                    </select>
                                                    : null}
                                                    
                                                    <select
                                                        className={"form-control p-form btn-sm br-btn-t rounded ml-3"}
                                                        style={{height: 38}}
                                                        onChange={e => {
                                                            filterProducts(e, 'is_active')
                                                        }}
                                                    >
                                                        <option value={'true'}>Activos</option>
                                                        <option value={'false'}>Desactivados</option>
                                                        <option value={'true,false'}>Todos</option>
                                                    </select>
                                                    
                                                    <select
                                                        className={"form-control p-form btn-sm br-btn-t rounded ml-3"}
                                                        style={{height: 38}}
                                                        onChange={e => {
                                                            filterProducts(e, 'provider')
                                                        }}
                                                    >
                                                        <option value={''}>Proveedor</option>
                                                        {providers.map((provider, key) => {
                                                            return <option key={key} value={provider.id}>{provider.name}</option>
                                                        })}
                                                    </select>
                                                </>
                                            ) : (
                                                history.location.pathname.includes('incomes')? (
                                                    <>
                                                        {/* <input
                                                            type="date"
                                                            name="order_date"
                                                            className="form-control rounded mr-1"
                                                            format={'YYYY-MM-DD'}
                                                            onChange={(e)=>handleSearchDateChange(e)}
                                                        /> */}

                                                        {/* <input
                                                            type="text"
                                                            name="order_date"
                                                            className="form-control rounded mr-1"
                                                            placeholder={'Filtrar por fecha'}
                                                            value={stateDateRange && stateDateRange.length && stateDateRange[0]?.startDate && stateDateRange[0]?.endDate ? moment(stateDateRange[0].startDate).format('DD/MM/YYYY') +' - '+ moment(stateDateRange[0].endDate).format('DD/MM/YYYY') : ''}
                                                            onClick={()=>setModalDate(true)}
                                                            style={{width: 230}}
                                                        />
                                                        <Modal style={{width: 350}} isOpen={modalDate} toggle={() => setModalDate(false)}>
                                                            <ModalBody className='p-1'>
                                                                <DateRange
                                                                    // editableDateInputs={true}
                                                                    onChange={item => setStateDateRange([item.selection])}
                                                                    moveRangeOnFirstSelection={false}
                                                                    ranges={stateDateRange}
                                                                    className="w-100"
                                                                    locale={es}
                                                                />
                                                            </ModalBody>
                                                        </Modal> */}

                                                        {/* <button
                                                            type='button'
                                                            className="btn br-btn-t color-white color-white mr-2"
                                                            style={{background: 'grey'}}
                                                            onClick={(e)=> setShowFilter(true)}
                                                        >
                                                            Por fecha
                                                        </button> */}

                                                        <input
                                                            id="filterInput"
                                                            className="form-control rounded mr-1"
                                                            style={{width: '150vw !important'}}
                                                            value={search}
                                                            onChange={(e) => handleSearchChange(e)}
                                                            type="text"
                                                            placeholder={'Buscar'}
                                                        />
                                                        <button
                                                            type='button'
                                                            className="btn br-btn-t color-white color-white"
                                                            style={{background: 'grey', borderTopLeftRadius: '4px', borderBottomLeftRadius:'4px'}}
                                                            onClick={(e)=> setShowFilter(true)}
                                                        >
                                                            Filtrar
                                                        </button>
                                                    </>
                                                ):( 
                                                    <input
                                                        id="filterInput"
                                                        className="form-control rounded"
                                                        value={search}
                                                        onChange={(e) => handleSearchChange(e)}
                                                        type="text"
                                                        placeholder={history.location.pathname.includes('supplies') || history.location.pathname.includes('packaging') ? 'Buscar por nombre o proveedor' : 'Buscar'}

                                                        style={history.location.pathname.includes('supplies')?window.innerWidth > 991 ? null : { width: "100%"}: null}
                                                    />
                                                )
                                            )
                                        }
                                        {
                                            (history.location.pathname.includes('clients') || history.location.pathname.includes('vendors')) || history.location.pathname.includes('products') ?
                                                <select
                                                className={"form-control p-form btn-sm br-btn-t rounded ml-3"}
                                                style={{height: 38}}
                                                onChange={e => {
                                                    sortResults(data.results, e.target.value, setOrder)
                                                }}
                                            >
                                                <option default>Ordenar</option>
                                                <option value={'AZ'}>Alfabético A-Z</option>
                                                <option value={'ZA'}>Alfabético Z-A</option>
                                            </select>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div 
                                    // sm={
                                    //     (history.location.pathname.includes('supplies'))
                                    //         ? window.innerWidth > 1611
                                    //             ? '3'
                                    //             : window.innerWidth > 1311
                                    //                 ? '4'
                                    //                 : window.innerWidth > 1111
                                    //                     ? '5'
                                    //                     : window.innerWidth > 991
                                    //                         ? '7'
                                    //                         : window.innerWidth > 837
                                    //                             ? '8'
                                    //                             : '3'
                                    //         :"1"
                                    // } 
                                    className='ml-3'
                                    style={history.location.pathname.includes('supplies')?window.innerWidth > 991 ? { width: null } : { width: null }: history.location.pathname.includes('incomes')?{ width: null }: window.innerWidth < 992 ? { width: null }:{width: null}}
                                >
                                    {
                                        toCreate ? (
                                            history.location.pathname.includes('supplies') ?(
                                                <ButtonGroup>
                                                    {!userIsPayment?.plan?.is_free ?
                                                    <Link to={toCreateWorkforce} >
                                                        <button
                                                            type="submit" 
                                                            className="btn background-secondary br-btn-t color-white color-white mr-1"
                                                            title='Agregar mano de obra'
                                                            // style={window.innerWidth > 991 ? null : { fontSize: '.89rem' }}
                                                            style={{minWidth: 200}}
                                                        >Agregar mano de obra</button>
                                                    </Link>
                                                    : null}

                                                    <Link to={toCreate} >
                                                        <button type="submit" className="btn background-secondary br-btn-t color-white color-white" style={window.innerWidth > 991 ? null : { fontSize: '.89rem' }}>Agregar</button>
                                                    </Link>
                                                </ButtonGroup>
                                            ):(
                                                <Link to={toCreate}
                                                    // style={
                                                    //     (!history.location.pathname.includes('incomes')
                                                    //         ?null
                                                    //         :(window.innerWidth > 991) 
                                                    //             ? null 
                                                    //             : {width: '100%', marginTop: '1rem'}
                                                    //     )
                                                    // }
                                                >
                                                    <button type="submit" className="btn background-secondary br-btn-t color-white color-white" 
                                                        // style={
                                                        //     (!history.location.pathname.includes('incomes')
                                                        //         ?null
                                                        //         :(window.innerWidth > 991) 
                                                        //             ? null 
                                                        //             : {width: '89%', position: 'absolute', left: '5%', marginTop: '1rem'}
                                                        //     )
                                                        //     }
                                                    >
                                                        Agregar
                                                    </button>
                                                </Link>
                                            )
                                        ) : (
                                            <button
                                                type="submit"
                                                onClick={() => onModalCreate({
                                                    bol: true,
                                                    title:
                                                        history.location.pathname.includes('incomes') ? 'Agregar nuevo ingreso' :
                                                            history.location.pathname.includes('withdrawals') ? 'Agregar nuevo egreso' :
                                                                null
                                                })}
                                                className="btn background-secondary br-btn-t color-white color-white"
                                            >
                                                Agregar
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </nav>
                    {
                        data?.results && data?.results.length ? (
                            <>
                                {
                                    toCreate.includes('vendors') ? (
                                        <TableVendors id={id} data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} />
                                    ) :
                                        toCreate.includes('clients') ? (
                                            <TableClients id={id} data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} />
                                        ) :
                                            toCreate.includes('products') ? (
                                                <TableProducts id={id} data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} showIsActive={showIsActive} isWholesale={isWholesale} />
                                            ) :
                                                toCreate.includes('supplies') ? (
                                                    <TableSupplies data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} />
                                                ) :
                                                    toCreate.includes('packaging') ? (
                                                        <TablePackaging data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} />
                                                    ) :
                                                        toCreate.includes('incomes') ? (
                                                            <TableIncomes data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} showFilter={showFilter} setShowFilter={setShowFilter} setState={setState} state={state} setChangeData={setChangeData} changeData={changeData} filter={changeFilter} changeFilter={changeFilter}/>
                                                        ) :
                                                            toCreate.includes('invested') ? (
                                                                <TableInvested data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} />
                                                            ) : 
                                                            toCreate.includes('withdrawals') ? (
                                                                <TableWithdrawals data={data.results} modal={modal} toEdit={toEdit} setModal={setModal} setId={setId} setDeleteAll={setDeleteAll} setModalDelete={setModalDelete} disabled={disabled} />
                                                            ) : null
                                }
                                {
                                    data.next || data.previous ? (
                                        <Row>
                                            <Pagination data={data}  page={page} setPage={setPage} previous={previous} next={next} pageData={pageData} disabled={disabled} />
                                        </Row>
                                    ) : null
                                }
                            </>
                        ) : (
                            <>
                                {
                                    (errorSearch) 
                                    ?
                                    <Row style={
                                        (!history.location.pathname.includes('incomes')
                                        ?null
                                        :(window.innerWidth > 991) 
                                            ? null 
                                            : {marginTop: '4rem'}
                                    )
                                    }>
                                        <Col className="ta-center mb-1 mt-1">No existe un elemento con ese nombre.</Col>
                                    </Row>
                                    :
                                        data === undefined ? 
                                            <Row>
                                                <Col className="d-flex justify-content-center align-items-center mb-1 mt-1"> 
                                                    <span className="spinner-border mr-1" style={{color: 'rgb(225, 92, 147)'}}>
                                                        <span className="sr-only"></span>
                                                    </span>
                                                    Cargando...
                                                </Col>
                                            </Row>
                                        :
                                            <Row style={
                                                (!history.location.pathname.includes('incomes')
                                                ?null
                                                :(window.innerWidth > 991) 
                                                    ? null 
                                                    : {marginTop: '4rem'}
                                            )
                                            }>
                                                <Col className="ta-center mb-1 mt-1">No hay información para mostrar</Col>
                                            </Row>
                                }
                            </>
                        )
                    }

                    <ModalFilter setShowFilter={setShowFilter} showFilter={showFilter} state={state} setState={setState} data={data} setChangeData={setChangeData} filter={filter} changeFilter={changeFilter} stateDateRange={stateDateRange} setStateDateRange={setStateDateRange}/>
                </div>
            </div>
        </>
    )
}

const sortResults = (results, type, setOrder) =>{
    if (type === 'AZ') results.sort((a, b) => a.name.localeCompare(b.name));
    else if (type === 'ZA') results.sort((a, b) => b.name.localeCompare(a.name));

    setOrder(type)
}