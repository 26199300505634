import React from 'react';
import { Row, Col, Table, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import Payment from '@material-ui/icons/Payment';
import { useHistory } from 'react-router';
import Pagination from './Pagination';
import Dropdown from './DropDownMenu';

const InputValue = ({id, value, onChange}) => {
    const [loading, setLoading] = React.useState(false);

    const updateValue = (value) => {
        setLoading(true);
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses/${id}/`, 
        {
            'method': "PATCH",
            'headers': myHeaders,
            'body': JSON.stringify({value: value})
        })
            .then(resp => resp.json())
            .then(json => {
                setLoading(false);
                onChange(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    return (
        <div style={{position: 'relative', width: 150}}>
            <input 
                type={'number'}
                className='form-control'
                style={{width: '100%'}}
                defaultValue={value}
                onChange={(e) => updateValue(e.target.value)}
            />
            {loading ? <Spinner size="sm" style={{position: 'absolute', right: 5, top: 12}} /> : null}
        </div>
    )
}

export default function TableCommercialData({ page, setPage, previous, next, pageData, setId, data, setModal, modal, onEdit, setModalDelete, setDeleteAll, setModalDeleteOne,search, setSearch, handleSubmit, setReload, reload, onChange }) {
    const history = useHistory();

    const [disabled, setDisabled] = React.useState(false);

    const [checkbox, setCheckbox] = React.useState({})
    const [all, setAll] = React.useState(false)

    const onChecked = (a) => {
        setCheckbox({
            ...checkbox,
            [a]: !checkbox[a]
        })
    }

    const handleDelete = () => {
        if (checkbox.all) {
            const arr = []
            data.map(el => arr.push(el.id))
            setDeleteAll(arr)
        } else {
            setDeleteAll(Object.keys(checkbox).filter(x => checkbox[x] === true))
        }
        setModalDelete(true)
    }

    const handleSearchChange = (e) => {
        setSearch(() => e.target.value)
        if (e.target.value.length > 2) {
            return handleSubmit(e)
        } else {
            return setReload(!reload)
        }
    }

    const isDisable = () => {
        let count = 0;
        data?.results.forEach((item) => {
            if(!item.is_default) count++;
        })
        return userIsPayment?.plan?.is_free && count >= 5
    }

    React.useEffect(() => { }, [data])

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)


    return (
        <>
            <nav>
                <div className="">
                    <div className={"mb-1 d-flex justify-content-flex-end"}>
                        <div style={window?.innerWidth > 991 ? null : { width: "50%" }}>
                            <div className="input-group input-group-md">
                                <input
                                    id="filterInput"
                                    className="form-control"
                                    value={search}
                                    onChange={handleSearchChange}
                                    type="text"
                                    placeholder={'Buscar'}
                                />
                            </div>
                        </div>
                        <div className='ml-3' style={window.innerWidth > 991 ? null : { width: "auto" }}>
                            {
                                !disabled ? (
                                    <button type="submit" disabled={isDisable()} className="btn background-secondary br-btn-t color-white color-white" onClick={() => {
                                        setDisabled(true)
                                        setModal({
                                            title: 'Agregue el nombre del gasto que desea agregar y su monto',
                                            bol: !modal.bol
                                        })
                                        setTimeout(() => {
                                            setDisabled(false);
                                        }, 500)
                                    }}>Agregar</button>
                                ) : (
                                    <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                        <span className="spinner-border spinner-border-sm">
                                            <span className="sr-only"></span>
                                        </span>
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </nav>
            {
                data?.results?.length ? (
                    <>
                        <Row>
                            <Col className="mb-1" sm="12">
                                <Table responsive className="align-middle mb-1">
                                    <thead>
                                        <tr>
                                            <th className="text-align-center checkboxContainer">
                                                <label className="containerCheckbox">
                                                    <input
                                                        type="checkbox"
                                                        className={
                                                            Object.values(checkbox).filter(x => x === true).length === data?.results?.length
                                                                ? "checked" : ""
                                                        }
                                                        onClick={() => {
                                                            setAll(!all)
                                                            const obj = {}
                                                            data?.results?.map(el => {
                                                                return obj[el.id] = !all
                                                            })
                                                            setCheckbox(obj)
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </th>
                                            <th>Gastos fijos mensuales</th>
                                            <th>Monto mensual</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data?.results?.length ? data?.results?.map((e, i = -1) => {
                                                i++
                                                return (
                                                    <tr key={e?.id} >
                                                        <th className="text-align-center checkboxContainer">
                                                            {
                                                                checkbox[e.id] ? (
                                                                    <label className="containerCheckbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checked"
                                                                            onClick={() => {
                                                                                onChecked(e.id)
                                                                            }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                ) : (
                                                                    <label className="containerCheckbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            onClick={() => {
                                                                                onChecked(e.id)
                                                                            }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                )
                                                            }
                                                        </th>
                                                        <th>
                                                            {e.name}
                                                        </th>
                                                        <th>
                                                            <InputValue {...e} onChange={onChange}/>
                                                        </th>
                                                        <td className="text-end">
                                                            <Dropdown
                                                                toEdit={onEdit}
                                                                setId={setId}
                                                                e={e}
                                                                customOptions={[{
                                                                    label: 'Pagar',
                                                                    icon: <Payment style={{ color: "rgba(0,0,0,.8)", marginRight: ".5rem", fontSize: "medium" }} />,
                                                                    onClick: () => history.push(`/withdrawals/create?type=expenses&spend=${e.id}`)
                                                                }]}
                                                                setModalDeleteOne={setModalDeleteOne}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            }) : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {
                            data?.next || data?.previous ? (
                                (
                                    <Row>
                                        <Pagination data={data}  page={page} setPage={setPage} previous={previous} next={next} pageData={pageData} disabled={disabled} />
                                    </Row>
                                )
                            ) : null
                        }
                    </>
                ) : (
                    data === undefined ? 
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center mb-1 mt-1"> 
                                <span className="spinner-border mr-1" style={{color: 'rgb(225, 92, 147)'}}>
                                    <span className="sr-only"></span>
                                </span>
                                Cargando...
                            </Col>
                        </Row>
                    :
                        <Row>
                            <Col className="ta-center mb-1 mt-1">No hay información para mostrar</Col>
                        </Row>
                    )
            }
            {
                Object.values(checkbox).filter(x => x === true)?.length ? (
                    <Col>
                        <footer>
                            {
                                !disabled ? (
                                    <button
                                        className="btn background-secondary br-btn-t color-white color-white"
                                        onClick={() => {
                                            setCheckbox({
                                                all: false
                                            })
                                            handleDelete()
                                        }}
                                    >
                                        Eliminar seleccionados
                                    </button>
                                ) : (
                                    <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                        <span className="spinner-border spinner-border-sm">
                                            <span className="sr-only"></span>
                                        </span>
                                    </button>
                                )
                            }
                        </footer>
                    </Col>
                ) : null
            }
            
        </>
    )
}