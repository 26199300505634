import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import Dropdown from './DropDownMenu';
import { ModalFilter } from './ModalFilter';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export default function TableIncomes({ errorSearch, changeData, setChangeData, setState,state, data, toEdit, setModal, modal, setId, setDeleteAll, setModalDelete, disabled, setShowFilter, showFilter, stateDateRange, filter, changeFilter }) {
    const [checkbox, setCheckbox] = React.useState({})
    const [all, setAll] = React.useState(false)
    const history = useHistory()

    const [tooltipOpen, setTooltipOpen] = React.useState(null);

    React.useEffect(() => {
        setCheckbox({})
    }, [])

    const onChecked = (a) => {
        setCheckbox({
            ...checkbox,
            [a]: !checkbox[a]
        })
    }

    const handleDelete = () => {
        if (all) {
            const arr = []
            data.map(el => arr.push(el.id))
            setDeleteAll(arr)
        } else {
            setDeleteAll(Object.keys(checkbox).filter(x => checkbox[x] === true))
        }
        setModalDelete(true)
        return setCheckbox({})
    }

    return (
        <>
            {
                (changeData === false)?(
                    <Row>
                        <Col className="mb-1" sm="12">
                            <Table responsive className="align-middle">
                                <thead>
                                    <tr>
                                        <th className="text-align-center checkboxContainer">
                                            <label className="containerCheckbox">
                                                <input
                                                    type="checkbox"
                                                    className={
                                                        Object.values(checkbox).filter(x => x === true).length === data.length
                                                        ? "checked" : ""
                                                    }
                                                    onClick={() => {
                                                        setAll(!all)
                                                        const obj = {}
                                                        data.map(el => {
                                                            return obj[el.id] = !all
                                                        })
                                                        setCheckbox(obj)
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </th>
                                        <th>Numero de pedido</th>
                                        <th>Cliente</th>
                                        {
                                            window.innerWidth < 992 ? (
                                                null
                                            ) : (
                                                <>
                                                    {/* <th>Numero de orden</th>
                                                    <th>Cliente</th> */}
                                                    <th>Estado del pedido</th>
                                                    <th>Fecha</th>
                                                    {/* <th>Estado del pedido</th>
                                                    <th>Estado del pago</th> */}
                                                </>
                                            )
                                        }
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.map((e, i = -1) => {
                                            i++
                                            const dateSell = e.order_date.substring(0, 10);
                                            return (
                                                <tr key={e.id} >
                                                    <th className="text-align-center checkboxContainer">
                                                        {
                                                            checkbox[e.id] ? (
                                                                <label className="containerCheckbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checked"
                                                                        onClick={() => {
                                                                            onChecked(e.id)
                                                                        }}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            ) : (
                                                                <label className="containerCheckbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        onClick={() => {
                                                                            onChecked(e.id)
                                                                        }}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            )
                                                        }
                                                    </th>
                                                    <th>
                                                        <Link to={`/incomes/edit/${e.id}`} id={'tooltip_' + e.order_id}>
                                                                {e.order_id}
                                                        </Link>
                                                        <Tooltip
                                                            isOpen={tooltipOpen === e.order_id}
                                                            target={'tooltip_' + e.order_id}
                                                            placement='bottom'
                                                            toggle={() => setTooltipOpen(!tooltipOpen ? e.order_id : null)}
                                                        >
                                                            {e.items.map((item) => {
                                                                return item.quantity +' x '+ item.name
                                                            })}
                                                        </Tooltip>
                                                    </th>
                                                    {/* <th>{e.amount}</th> */}
                                                    {
                                                        window.innerWidth < 992 ? (
                                                            null
                                                        ) : (
                                                            <>
                                                                {/* <th>{e.client}</th>
                                                                <th>{e.concept}</th>
                                                                <th>{e.details}</th>
                                                                <th>{e.method_payment}</th> */}
                                                                <th>{e?.client?.name}</th>
                                                                <th>
                                                                    {e?.status_order === 'entered' ? 'Ingresado' : ''}
                                                                    {e?.status_order === 'in-preparation' ? 'En preparación' : ''}
                                                                    {e?.status_order === 'ready-to-deliver' ? 'Listo para entregar' : ''}
                                                                    {e?.status_order === 'delivered' ? 'Entregado' : ''}
                                                                </th>
                                                                <th>{moment(e.order_date).format('DD/MM/YYYY')}</th>
                                                            </>
                                                        )
                                                    }
                                                    <th className="text-end">
                                                        <Dropdown setId={setId} toEdit={toEdit} e={e} setModal={setModal} modal={modal} />
                                                    </th>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        {
                            Object.values(checkbox).filter(x => x === true).length ? (
                                <Col>
                                    <footer>
                                        {
                                            !disabled ? (
                                                <button
                                                    className="btn background-secondary br-btn-t color-white color-white"
                                                    onClick={() => handleDelete()}
                                                >
                                                    Eliminar seleccionados
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                                    <span className="spinner-border spinner-border-sm">
                                                        <span className="sr-only"></span>
                                                    </span>
                                                </button>
                                            )
                                        }
                                    </footer>
                                </Col>
                            ) : null
                        }
                    </Row>
                ):(
                    <Row>
                        <Col className="mb-1" sm="12">
                            <Table responsive className="align-middle">
                                <thead>
                                    <tr>
                                        <th className="text-align-center checkboxContainer">
                                            <label className="containerCheckbox">
                                                <input
                                                    type="checkbox"
                                                    className={
                                                        Object?.values(checkbox)?.filter(x => x === true)?.length === state?.length
                                                        ? "checked" : ""
                                                    }
                                                    onClick={() => {
                                                        setAll(!all)
                                                        const obj = {}
                                                        state.map(el => {
                                                            return obj[el.id] = !all
                                                        })
                                                        setCheckbox(obj)
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </th>
                                        <th>Numero de pedido</th>
                                        <th>Cliente</th>
                                        {
                                            window.innerWidth < 992 ? (
                                                null
                                            ) : (
                                                <>
                                                    {/* <th>Numero de orden</th>
                                                    <th>Cliente</th> */}
                                                    <th>Estado del pedido</th>
                                                    <th>Fecha</th>
                                                    {/* <th>Estado del pedido</th>
                                                    <th>Estado del pago</th> */}
                                                </>
                                            )
                                        }
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state && state.map((e, i = -1) => {
                                            i++
                                            const dateSell = e.created_at.substring(0, 10);
                                            return (
                                                <tr key={e.id} >
                                                    <th className="text-align-center checkboxContainer">
                                                        {
                                                            checkbox[e.id] ? (
                                                                <label className="containerCheckbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checked"
                                                                        onClick={() => {
                                                                            onChecked(e.id)
                                                                        }}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            ) : (
                                                                <label className="containerCheckbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        onClick={() => {
                                                                            onChecked(e.id)
                                                                        }}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            )
                                                        }
                                                    </th>
                                                    <th>
                                                        <Link to={`/incomes/edit/${e.id}`}>
                                                            {e.order_id}
                                                        </Link>
                                                    </th>
                                                    {/* <th>{e.amount}</th> */}
                                                    {
                                                        window.innerWidth < 992 ? (
                                                            null
                                                        ) : (
                                                            <>
                                                                {/* <th>{e.client}</th>
                                                                <th>{e.concept}</th>
                                                                <th>{e.details}</th>
                                                                <th>{e.method_payment}</th> */}
                                                                <th>{e.client.name}</th>
                                                                <th>{e.status_order}</th>
                                                                <th>{moment(e.created_at).format('DD/MM/YYYY')}</th>
                                                            </>
                                                        )
                                                    }
                                                    <th className="text-end">
                                                        <Dropdown setId={setId} toEdit={toEdit} e={e} setModal={setModal} modal={modal} />
                                                    </th>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        {
                            Object.values(checkbox).filter(x => x === true).length ? (
                                <Col>
                                    <footer>
                                        {
                                            !disabled ? (
                                                <button
                                                    className="btn background-secondary br-btn-t color-white color-white"
                                                    onClick={() => handleDelete()}
                                                >
                                                    Eliminar seleccionados
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn background-secondary btn-height-3 br-btn-t color-white" disabled={true}>
                                                    <span className="spinner-border spinner-border-sm">
                                                        <span className="sr-only"></span>
                                                    </span>
                                                </button>
                                            )
                                        }
                                    </footer>
                                </Col>
                            ) : null
                        }
                    </Row>
                )
            }
        </>
    )
}