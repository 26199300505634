import React, { useState, useEffect } from 'react'
import Tour from 'reactour';

const steps = [
  {
    selector: '.app-tour',
    content: 'Podés volver a ejecutar el tutorial',
  },
  {
    selector: '.menu-header-items',
    content: 'Aquí encontrarás los módulos del sistema',
  },
  {
    selector: '.dropdown-user',
    content: 'Editar tus datos de usuario',
  },
  {
    selector: '.dashboard',
    content: 'Aquí podés ver las métricas de tu negocio',
  }
]

function AppTour() {

  const [isTourOpen, setIsTourOpen] = useState(false)

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Token ${localStorage.getItem('flor_emprendedora')}`)
    myHeaders.append('Content-Type', 'application/json')

    fetch(`${process.env.REACT_APP_URL_API}/api/config/global/?key=first_access`, {
        'method': 'GET',
        'headers': myHeaders
    }).then(resp => resp?.json())
        .then((e) => {
          if(e.count === 0) {
            setIsTourOpen(true)
            fetch(`${process.env.REACT_APP_URL_API}/api/config/global/`, {
              'method': 'POST',
              'headers': myHeaders,
              'body': JSON.stringify({
                'key': 'first_access',
                'value': 'true'
              })
            })
          }
        })
      
      window.addEventListener('openapptour', (e) => {
        setIsTourOpen(true)
      }, false);
  }, []);

  return (
    isTourOpen ? 
      <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          accentColor={'rgb(225, 92, 147)'}
      />
    : null
  );
}

export default AppTour;