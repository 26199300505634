import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { FormSuppliesWorkforce } from '../component/FormSuppliesWorkforce';
import CreateVendor from './createVendor';

const CreateSuppliesWorkforce = ({ setSupplyWorkforceAdded, setAddSuppliesWorkforce }) => {
    const history = useHistory();
    const [vendors, setVendors] = useState([])
    const [disabled, setDisabled] = React.useState(false);
    const [idVendor, setId] = useState(null)
    const [addVendors, setAddVendors] = useState(false)
    const [state, setState] = useState({
        name: '',
        work_unit: 0,
        cost_unit: '',
        supply_type: 'workforce',
        provider: '',
        current_stock_in_cost: 0,
        current_stock_in_units: 0,
        element_width: 0,
        ideal_stock_in_units: 0,
        square_meter: 0,
        stock_replenish: 0,
        stock_replenish_in_cost: 0,
        unit_measurement: 'kg'
    })

    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        cost_unit: {
            bol: false,
            msg: ''
        },
        provider: {
            bol: false,
            msg: ''
        },
        work_unit: {
            bol: false,
            msg: ''
        },
    })

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    React.useEffect(() => {
        setState({
            ...state,
            provider: idVendor
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idVendor])

    const onSubmit = (e) => {
        e.preventDefault();
        const err = {}

        Object.keys(state).map(x => {
            if (
                x === "name" ||
                x === "cost_unit" ||
                x === "provider" ||
                x === "work_unit"
            ) {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            } else {
                if (!state[x]) {
                    if (state[x] === 0) {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    } else {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            setDisabled(true)
            fetch(`${process.env.REACT_APP_URL_API}/api/supplies/`, {
                'method': 'POST',
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    console.log('asdfasdfasdf', json)
                    if (history.location.pathname.includes('product')) {
                        if (json.id) {
                            toast.success('Mano de obra creada exitosamente', {
                                className: "Toastify__toast--success"
                            })
                            setAddSuppliesWorkforce(false)
                        } else {
                            setDisabled(false)
                            toast.error('No se puede agregar la mano de obra, llena los campos.', {
                                className: "Toastify__toast--error"
                            })
                        }
                    }  else {
                        if (json.id) {
                            setDisabled(false)
                            toast.success('Mano de obra creada exitosamente', {
                                className: "Toastify__toast--success"
                            })
                            setTimeout(() => {
                                history.push('/supplies')
                            }, 800)
                        } else {
                            setDisabled(false)
                            toast.error('No se puede agregar la mano de obra, llena los campos.', {
                                className: "Toastify__toast--error"
                            })
                        }
                    }
                })
        }
        setSupplyWorkforceAdded(state)
    }

    return (
        <>
            {
                <Modal show={addVendors} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                    </Modal.Body>
                </Modal>
            }
            <FormSuppliesWorkforce
                title={"Agregar mano de obra"}
                state={state}
                setState={setState}
                errors={errors}
                back={'/supplies'}
                onSubmit={onSubmit}
                vendors={vendors}
                setAddVendors={setAddVendors}
                addVendors={addVendors}
                idVendor={idVendor}
                setDisabled={setDisabled}
                disabled={disabled}
            />
        </>
    )
}

export default CreateSuppliesWorkforce;