const initialState = {
    profile: {},
    providers: {},
    supplies: {},
    expenses: {
        results: []
    },
    products: {},
    clients: {},
    commercial_expenses: {
        results: []
    },
    packaging: {
        results: []
    },
    marginWholesalerSurcharge: 0,
    wholesaler: false,
    marginSurcharge: 0,
    suppliesProduct: [],
    premium: {},
    userPremiumPay: {},
    supplyConfig: undefined
}

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_PROVIDERS":
            return {
                ...state,
                providers: action.payload
            }
        case "SEARCH_PROVIDERS":
            return {
                ...state,
                providers: action.payload
            }
        case "PAGE_PROVIDERS":
            return {
                ...state,
                providers: action.payload
            }
        case "GET_SUPPLIES":
            return {
                ...state,
                supplies: action.payload
            }
        case "GET_WORKFORCE":
            return {
                ...state,
                workforce: action.payload
            }
        case "SEARCH_SUPPLIES":
            return {
                ...state,
                supplies: action.payload
            }
        case "PAGE_SUPPLIES":
            return {
                ...state,
                supplies: action.payload
            }
        case "GET_EXPENSES":
            return {
                ...state,
                expenses: action.payload
            }
        case "PAGE_EXPENSES":
            return {
                ...state,
                expenses: action.payload
            }
        case "GET_PRODUCTS":
            return {
                ...state,
                products: action.payload
            }
        case "SEARCH_PRODUCTS":
            return {
                ...state,
                products: action.payload
            }
        case "PAGE_PRODUCTS":
            return {
                ...state,
                products: action.payload
            }
        case "GET_CLIENTS":
            return {
                ...state,
                clients: action.payload
            }
        case "SEARCH_CLIENTS":
            return {
                ...state,
                clients: action.payload
            }
        case "PAGE_CLIENTS":
            return {
                ...state,
                clients: action.payload
            }
        case "GET_COMMERCIAL_EXPENSES":
            return {
                ...state,
                commercial_expenses: action.payload
            }
        case "PAGE_COMMERCIAL_EXPENSES":
            return {
                ...state,
                commercial_expenses: action.payload
            }
        case "GET_PACKAGING":
            return {
                ...state,
                packaging: action.payload
            }
        case "GET_MARGIN_SURCHARGE":
            return {
                ...state,
                marginSurcharge: action.payload
            }
        case "GET_SUPPLIES_PRODUCT":
            return {
                ...state,
                suppliesProduct: action.payload
            }
        case "GET_CONFIG_SUPPLY":
            return {
                ...state,
                supplyConfig: action.payload
            }
        case "GET_NEW_PRODUCT":
            return {
                ...state,
                newProduct: action.payload
            }
        case "GET_MARGIN_WHOLESALER_SURCHARGE":
            return {
                ...state,
                marginWholesalerSurcharge: action.payload
            }
        case "GET_WHOLESALER":
            return {
                ...state,
                wholesaler: action.payload
            }
        case "GET_PREMIUM":
            return {
                ...state,
                premium: action.payload
            }
        case "SET_PREMIUM_USER":
            return {
                ...state,
                userPremiumPay: action.payload
            }
        default:
            return state
    }
}