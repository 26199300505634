import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import ModalComponent from '../component/Modal';
import Loading from '../component/Loading';
import CreateVendor from './createVendor';
import Modal from 'react-bootstrap/Modal';
import FormPackaging from '../component/FormPackaging';
import { useSelector } from 'react-redux';

export default function EditPackaging() {
    const { id } = useParams();
    const packaging = useSelector(store => store.packaging)
    const [state, setState] = useState();
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const [idVendor, setId] = useState(null)
    const [addVendors, setAddVendors] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [group, setGroup] = useState([])
    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        unit_measurement: {
            bol: false,
            msg: ''
        },
        group: {
            bol: false,
            msg: ''
        },
        element_height: {
            bol: false,
            msg: ''
        },
        element_width: {
            bol: false,
            msg: ''
        },
        square_meter: {
            bol: false,
            msg: ''
        },
        cost_unit: {
            bol: false,
            msg: ''
        },
        provider: {
            bol: false,
            msg: ''
        },
        current_stock_in_units: {
            bol: false,
            msg: ''
        },
        current_stock_in_cost: {
            bol: false,
            msg: ''
        },
        ideal_stock_in_units: {
            bol: false,
            msg: ''
        },
        stock_replenish: {
            bol: false,
            msg: ''
        },
        stock_replenish_in_cost: {
            bol: false,
            msg: ''
        }
    })

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/packaging/${id}/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                setState(json)
                if (json?.packaging_type === "bundled") {
                    setGroup(json.group)
                } else {
                    return
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onSubmit = (e) => {
        e.preventDefault();

        let err = {
            margin_surcharge: {
                bol: false,
                msg: ''
            },
            sale_actual_stock: {
                bol: false,
                msg: ''
            }
        }

        if (state.packaging_type === "simple") {
            Object.keys(state).map(x => {
                if (
                    x === "element_height" ||
                    x === "element_width" ||
                    x === "square_meter" ||
                    x === "stock_replenish" ||
                    x === "stock_replenish_in_cost" ||
                    x === "current_stock_in_cost"
                ) {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                } else {
                    if (
                        x === "cost_unit" ||
                        x === "unit_measurement" ||
                        x === "name" ||
                        x === "provider" 

                    ) {
                        if (state[x] === '') {
                            return err[x] = {
                                bol: true,
                                msg: 'Este campo no puede estar vacío'
                            };
                        } else {
                            return err[x] = {
                                bol: false,
                                msg: ''
                            };
                        }
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                }
            })
        } else {
            Object.keys(state).map(x => {
                if (
                    x === "name"
                ) {
                    if (state[x] === '') {
                        return err[x] = {
                            bol: true,
                            msg: 'Este campo no puede estar vacío'
                        };
                    } else {
                        return err[x] = {
                            bol: false,
                            msg: ''
                        };
                    }
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            })
        }

        const arr = [];

        if (state.packaging_type === "bundled") {
            if (group.length) {
                group.map(el => {
                    return arr.push(el.id)
                })
            } else {
                err = {
                    ...err,
                    group: {
                        bol: true,
                        msg: 'Debe seleccionar al menos un packaging'
                    }
                }
                toast.error(err['group']['msg'], {
                    className: "Toastify__toast--error"
                })
            }
        }

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)

            let idGroup = group?.map(a => {
                return {
                    packaging: a.packaging?.id ? a.packaging.id : a.id,
                    quantity: a.quantity
                }
            });

            const body = state.packaging_type === "simple" ?
                {
                    ...state,
                    group: idGroup,
                    amount: state?.cost_unit
                } :
                {
                    ...state,
                    group: idGroup,
                    provider: vendors.results[0].id,
                    unit_measurement: 'un',
                    cost_unit: state.amount
                }
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/packaging/${id}/`, {
                'method': "PUT",
                'body': JSON.stringify(body),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        setState([
                            {
                                json,
                            }
                        ])
                        toast.success('Insumo modificado exitosamente', {
                            className: "Toastify__toast--success"
                        })

                        history.push('/packaging');
                    } else {
                        toast.success(json.name, {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    const handleDelete = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        setDisabled(true)
        fetch(`${process.env.REACT_APP_URL_API}/api/packaging/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then((res) => {
                setDisabled(false)
                if(res.status === 204) {
                    toast.success('Packaging eliminado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                    history.push('/packaging');
                } else {
                    toast.success('No se puede eliminar este packaging', {
                        className: "Toastify__toast--error"
                    })
                }
            })
    }

    return (
        !state ? <Loading /> : (
            <>
                {
                    <Modal show={addVendors} className="modal-create">
                        <Modal.Header>
                            <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                            <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                                <span>×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                        </Modal.Body>
                    </Modal>
                }
                <ModalComponent
                    open={modal}
                    title={"Eliminar gasto"}
                    body={"¿Esta segura de eliminar este gasto?"}
                    setModal={setModal}
                    onDelete={handleDelete}
                />
                <FormPackaging
                    title={'Editar gasto de packaging'}
                    state={state}
                    setState={setState}
                    errors={errors}
                    back={'/packaging'}
                    onSubmit={onSubmit}
                    id={id}
                    vendors={vendors}
                    setAddVendors={setAddVendors}
                    addVendors={addVendors}
                    setModal={setModal}
                    idVendor={idVendor}
                    setDisabled={setDisabled}
                    disabled={disabled}
                    group={group}
                    setGroup={setGroup}
                    packaging={packaging}
                />
            </>
        )
    )
}