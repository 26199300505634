import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { FormInvested } from '../component/FormInvested';
import Loading from '../component/Loading';

export const EditInvested = () => {
    const history = useHistory();
    const { id } = useParams();
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        date: '',
        concept: '',
        amount: '',
        details: ''
    })

    const [errors, setErrors] = useState({
        date: {
            bol: false,
            msg: ''
        },
        concept: {
            bol: false,
            msg: ''
        },
        amount: {
            bol: false,
            msg: ''
        },
        details: {
            bol: false,
            msg: ''
        }
    })

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        setLoading(true)
        fetch(`${process.env.REACT_APP_URL_API}/api/inverted/${id}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                setState(json)
                setLoading(false)
            })
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (
                x === "date" ||
                x === "amount" ||
                x === "concept"
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/inverted/${id}/`, {
                'method': "PUT",
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        history.push(`/invested`)
                        setTimeout(() => {
                            toast.success('Ingreso editado exitosamente', {
                                className: "Toastify__toast--success"
                            })
                        })
                    } else {
                        toast.error('Hubo un error al intentar editar el ingreso.', {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }
    return (
        <>
            <FormInvested
                title={'Editar inversion'}
                state={state}
                setState={setState}
                back={'/invested'}
                errors={errors}
                onSubmit={onSubmit}
                setDisabled={setDisabled}
                disabled={disabled}
            />

            {loading ? <Loading /> : null}
        </>
    )
}
