import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

import Logo from '../assets/img/logo_lg.png'
import Button from '../component/Button';
import LogoTXT from '../component/LogoTXT';
import { FacebookLogin as Facebook } from '@capacitor-community/facebook-login';

/* import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'; */


export default function Login() {
    const history = useHistory();

    const [state, setState] = useState({
        username: '',
        password: ''
    })

    const [secret, setSecret] = useState(true);

    const [errors, setErrors] = useState({
        username: {
            bol: false,
            msg: ''
        },
        password: {
            bol: false,
            msg: ''
        }
    })

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).map(x => {
            if (x === "username") {
                if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(state[x])) {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    }
                } else {
                    return err[x] = {
                        bol: true,
                        msg: 'Verifique el formato de su email'
                    }
                }
            }
            if (!state[x]) {
                return err[x] = {
                    bol: true,
                    msg: 'Este campo no puede estar vacío'
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                }
            }
        })

        setErrors(err)

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            fetch(`${process.env.REACT_APP_URL_API}/api/auth/login/`,
            {
                method: 'POST',
                body: JSON.stringify(state),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(resp => resp.json())
            .then(json => {
                if (json.id) {
                    localStorage.setItem("flor_emprendedora", json.token);
                    if(localStorage.flor_emprendedora) {
                        toast.success("Has iniciado sesión correctamente", {
                            className: "Toastify__toast--success"
                        })
                        return history.push("/dashboard")
                    }
                } else {
                    toast.error(json.detail, {
                        className: "Toastify__toast--error"
                    })
                }
            })
            .catch(err => console.log(err))
        }
    }

    const responseFacebook = async (response) => {

        console.log(response);
        const loginFacebook = {
            access_token:response.accessToken,
            provider:'facebook'
        }

        fetch(`${process.env.REACT_APP_URL_API}/api/auth/login/social/`,
            {
                method: 'POST',
                body: JSON.stringify(loginFacebook),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(resp => resp.json())
            .then(json => {
                if (json.id) {
                    localStorage.setItem("flor_emprendedora", json.token);
                    if(localStorage.flor_emprendedora) {
                        toast.success("Has iniciado sesión correctamente", {
                            className: "Toastify__toast--success"
                        })
                        return history.push("/dashboard")
                    }
                } else {
                    toast.error(json.detail, {
                        className: "Toastify__toast--error"
                    })
                }
            })
            .catch(err => console.log(err))
    }

    window.responeFacebookAndroid = (token) => {
        // const result = await Facebook.login({ permissions: [] });
        // console.log(result)

        if (token) {
        // Login successful.
            const loginFacebook = {
                access_token: token,
                provider: 'facebook'
            }
    
            fetch(`${process.env.REACT_APP_URL_API}/api/auth/login/social/`,
                {
                    method: 'POST',
                    body: JSON.stringify(loginFacebook),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        localStorage.setItem("flor_emprendedora", json.token);
                        if(localStorage.flor_emprendedora) {
                            toast.success("Has iniciado sesión correctamente", {
                                className: "Toastify__toast--success"
                            })
                            return history.push("/dashboard")
                        }
                    } else {
                        toast.error(json.detail, {
                            className: "Toastify__toast--error"
                        })
                    }
                })
                .catch(err => console.log(err))
        } else { 
            toast.error(token, {
                className: "Toastify__toast--error"
            })
        }
    }

    window.responseGoogleAndroid = (tokenId) => {
        /* const result = await GoogleAuth.init()
        console.log(result) */
        responseGoogle({tokenId: tokenId})
    }

    const responseGoogle = (response) => {
        console.log(response);
        const loginGoogle = {
            access_token:response.tokenId,
            provider: 'google',
        }

        fetch(`${process.env.REACT_APP_URL_API}/api/auth/login/social/`,
            {
                method: 'POST',
                body: JSON.stringify(loginGoogle),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(resp => resp.json())
            .then(json => {
                console.log(json)
                if (json.id) {
                    localStorage.setItem("flor_emprendedora", json.token);
                    if(localStorage.flor_emprendedora) {
                        toast.success("Has iniciado sesión correctamente", {
                            className: "Toastify__toast--success"
                        })
                        return history.push("/dashboard")
                    }
                } else {
                    toast.error(json.detail, {
                        className: "Toastify__toast--error"
                    })
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <div className="container brand-container">
                <Row>
                    <Col sm="12" className="d-flex">
                        <div className="card" style={{ margin: "auto", borderRadius: ".5rem" }}>
                            <div className="card-body" style={{ minHeight: "475px" }}>
                                <div className="d-flex justify-content-evenly mb-1 align-items-center">
                                    <LogoTXT />
                                    {/* <img src={Logo} alt="logo" className="login-logo" /> */}
                                    {/* <h2 className="brand-text text-sm-start ta-center fs-1 color-primary font-primary" style={{ height: "10%" }}>Flor de Emprendedora</h2> */}
                                </div>
                                <div>
                                    {/* <h4 className="card-title mb-1 text-sm-start fs-4">Gracias por visitar</h4> */}
                                    <p className="card-text mb-2 text-sm-start fs-6">Inicia sesión y ¡mejoremos tu emprendimiento!</p>
                                </div>
                                <form noValidate onSubmit={e => onSubmit(e)} style={{ height: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                    <div className="form-group">
                                        <label htmlFor="login-email" className="form-label fs-6">Correo electrónico</label>
                                        <Button
                                            type={'email'}
                                            name={'username'}
                                            style={errors.username.bol ? "form-control p-form btn-height-3 br-btn-t error-input" : "form-control p-form btn-height-3 br-btn-t"}
                                            value={state.username.toLocaleLowerCase()}
                                            setValue={setState}
                                            state={state}
                                        />
                                        <div className={errors.username.bol ? "invalid-input" : "valid-input"}>
                                            {errors.username.msg}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="login-password" className="form-label br-btn-tl br-btn-bl fs-6">Contraseña</label>
                                            <Link to="/forgot-password">
                                                <small className="fs-6 color-primary">Olvidé mi contraseña</small>
                                            </Link>
                                        </div>
                                        <div className="input-group-merge input-group">
                                            <Button
                                                type={secret ? "password" : "text"}
                                                name={'password'}
                                                style={
                                                    errors.password.bol ?
                                                        "form-control p-form btn-height-3 border-radius-btn-top-left border-radius-btn-btm-left border-right-none error-input" :
                                                        "form-control p-form btn-height-3 border-radius-btn-top-left border-radius-btn-btm-left border-right-none"
                                                }
                                                value={state.password}
                                                setValue={setState}
                                                state={state}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="cursor-pointer input-group-text border-left-none border-radius-btn-btm-right border-radius-btn-top-right border-left-none"
                                                    style={{ height: "100%", background: "transparent" }}
                                                    onClick={() => setSecret(!secret)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                        <circle cx="12" cy="12" r="3"></circle>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={errors.password.bol ? "invalid-input" : "valid-input"}>
                                            {errors.password.msg}
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block btn-height-3 br-btn-t">Ingresar</button>
                                    <hr />
                                    <p className="ta-center mt-2">
                                        <span className="mr-25 fs-6">¿Nuevo en la plataforma?</span>
                                        <Link to="/register">
                                            <span className="color-primary fs-6">Crear cuenta</span>
                                        </Link>
                                    </p>
                                </form>
                                <div className="divider my-2">
                                    <div className="divider-text">ó</div>
                                </div>
                                {window.isNativeApp ?
                                <>
                                    <button onClick={() => window.ReactNativeWebView.postMessage('loginFacebook()')} className="waves-effect btn btn-facebook d-flex align-items-center mb-1 padding-75 metro">FB ANDROID</button>
                                    <button onClick={() => window.ReactNativeWebView.postMessage('loginGoogle()')} className="waves-effect btn btn-google d-flex align-items-center mb-1 padding-75 btn-google">GOOGLE ANDROID</button>
                                </>
                                : 
                                <>
                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_ID}
                                        callback={responseFacebook}
                                        cssClass='waves-effect btn btn-facebook d-flex align-items-center mb-1 padding-75'
                                        textButton='Ingresar con Facebook'
                                        scope="email"
                                        icon={
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="14" 
                                                height="14" 
                                                viewBox="0 0 24 24" 
                                                fill="none" 
                                                stroke="currentColor" 
                                                strokeWidth="2" 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round"
                                                className={"mr-1"}
                                            >
                                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                            </svg>
                                        }
                                    />
                                    <GoogleLogin
                                        clientId={process.env.REACT_APP_GOOGLE_ID}
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                        className='waves-effect btn btn-google d-flex align-items-center mb-1 padding-75 btn-google'
                                        buttonText='Ingresar con Google'
                                        icon='true'
                                    />
                                </>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}