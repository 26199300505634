import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { FormInvested } from '../component/FormInvested';

export const CreateInvested = () => {
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);
    const date = new Date();
    let dateForState;
    if (date?.getMonth() < 10) {
        dateForState = `${date?.getFullYear()}-0${date.getMonth()+1}-${date.getDate()}`;
    } if (date?.getDate() < 10) {
        dateForState = `${date.getFullYear()}-${date.getMonth()+1}-0${date.getDate()}`;
    } else {
        dateForState = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    }
    const [state, setState] = useState({
        date: `${dateForState}`,
        concept: '',
        amount: '',
        details: ''
    })

    const [errors, setErrors] = useState({
        date: {
            bol: false,
            msg: ''
        },
        concept: {
            bol: false,
            msg: ''
        },
        amount: {
            bol: false,
            msg: ''
        },
        details: {
            bol: false,
            msg: ''
        }
    })

    /* useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setExpensesCommercial(json.results)
            })
    }, [reload]) */

    /* React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors]) */

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (
                x === "date" ||
                x === "amount" ||
                x === "concept"
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')

            fetch(`${process.env.REACT_APP_URL_API}/api/inverted/`, {
                'method': "POST",
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    if (json.id) {
                        history.push(`/invested`)
                        setTimeout(() => {
                            toast.success('Ingreso creado exitosamente', {
                                className: "Toastify__toast--success"
                            })
                        })
                    } else {
                        toast.success(json.amount, {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    return (
        <>
            <FormInvested
                title={'Agregar inversion'}
                state={state}
                setState={setState}
                back={'/invested'}
                errors={errors}
                onSubmit={onSubmit}
                setDisabled={setDisabled}
                disabled={disabled}
            />
        </>
    )
}
