import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import CreateVendor from './createVendor';
import FormWithdrawals from '../component/FormWithdrawals';
import ModalComponent from '../component/Modal';
import Loading from '../component/Loading';

export default function EditWithdrawals() {
    const { id } = useParams();
    const history = useHistory();
    const [disabled, setDisabled] = React.useState(false);
    const [vendors, setVendors] = React.useState([]);
    const [modal, setModal] = React.useState(false);
    const [expensesCommercial, setExpensesCommercial] = React.useState([])
    const [idVendor, setId] = useState(null)
    const [addVendors, setAddVendors] = React.useState(false)
    const [state, setState] = useState()
    const [loading, setLoading] = useState(null)

    const [errors, setErrors] = useState({
        date: {
            bol: false,
            msg: ''
        },
        concept: {
            bol: false,
            msg: ''
        },
        amount: {
            bol: false,
            msg: ''
        },
        method_payment: {
            bol: false,
            msg: ''
        },
        status_payment: {
            bol: false,
            msg: ''
        },
        client: {
            bol: false,
            msg: ''
        },
        status_order: {
            bol: false,
            msg: ''
        },
        details: {
            bol: false,
            msg: ''
        }
    })

    React.useEffect(() => {
        setState({
            ...state,
            provider: idVendor
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idVendor])

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/commertial-expenses?pagination=false`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setExpensesCommercial(json)
            })
    }, [])

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        setLoading(true)
        fetch(`${process.env.REACT_APP_URL_API}/api/expenses/${id}/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setState(json)
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                setVendors(json)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVendors])

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (
                x === "date" ||
                x === "amount"
            ) {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            fetch(`${process.env.REACT_APP_URL_API}/api/expenses/${id}/`, {
                'method': "PUT",
                'body': JSON.stringify(state),
                'headers': myHeaders
            })
                .then(() => {
                    toast.success('Egreso modificado exitosamente', {
                        className: "Toastify__toast--success"
                    })
                    history.push('/withdrawals')
                })
        }
    }

    const handleDelete = (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        fetch(`${process.env.REACT_APP_URL_API}/api/expenses/${id}/`, {
            'method': "DELETE",
            'headers': myHeaders
        })
            .then(() => {
                toast.success('Cliente eliminado exitosamente', {
                    className: "Toastify__toast--success"
                })
                history.push('/vendors')
            })
    }

    return (
        <>
            {
                <ModalComponent
                    open={modal}
                    title={"Eliminar gasto"}
                    body={"¿Esta segura de eliminar este gasto?"}
                    setModal={setModal}
                    onDelete={handleDelete}
                />
            }
            {
                <Modal show={addVendors} className="modal-create">
                    <Modal.Header>
                        <h5 className="modal-title color-primary">{'Agregar proveedor'}</h5>
                        <button type="button" className="close color-primary" onClick={() => setAddVendors(false)}>
                            <span>×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateVendor setAddVendors={setAddVendors} addVendors={addVendors} setId={setId} />
                    </Modal.Body>
                </Modal>
            }
            
            {
                state ? (
                    <FormWithdrawals
                        title={'Editar egreso'}
                        state={state}
                        setState={setState}
                        back={'/withdrawals'}
                        errors={errors}
                        vendors={vendors}
                        setAddVendors={setAddVendors}
                        addVendors={addVendors}
                        idVendor={idVendor}
                        onSubmit={onSubmit}
                        setModal={setModal}
                        setDisabled={setDisabled}
                        disabled={disabled}
                        expensesCommercial={expensesCommercial}
                    />
                ) : null
            }

            { loading ? <Loading /> : null }
        </>
    )
}