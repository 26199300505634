import React, { useState } from 'react'
import { ButtonGroup, Table, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete'


export const TableIncomesSell = ({ dataAdding, handleDeleteProduct, setDataAdding}) => {

    const [msgStockToProduction, setMsgStockToProduction] = useState({
        packaging: {},
        supplies: {}
    });

    /* let autoComplete = (inp, arr) => {
        let currentFocus;
        
    } */

    const handleValueDiscount = (e, dataAdding, data) => {
        e.preventDefault()

        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                value_discount: (e.target.value.length <= 0)?0:e.target.value
            }
        }

        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const handleTypeDiscount = (e, dataAdding, data) => {
        e.preventDefault()

        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)
        
        let newData;
        if (dataChange) {
            newData = {
                ...data,
                type_discount: e.target.value
            }
        }

        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const checksIfThereIsStockAvailableToProduce = (quantityToProduce, data) => {
        let msg = {...msgStockToProduction}

        if(data.product_type === 'production' && !data.use_stock) {
            data.supplies.forEach((supply) => {
                delete msg.supplies[supply.product]
                let stockSupply = supply.supply_id.current_stock_in_units
                let amountToReduce = quantityToProduce / data.quantity_per_recipe * supply.quantity
                if(amountToReduce > stockSupply) {
                    console.log('supply.id', supply.id)
                    msg.supplies[supply.product] = {
                        name: supply.supply_id.name,
                        msg: 'No hay suficiente stock del insumos',
                        url: `/supplies/edit/${supply.supply_id.id}`
                    }
                }
            })
    
            data.packaging.forEach((pack) => {
                delete msg.packaging[pack.product]
                let stockPackaging = pack.packaging_id.current_stock_in_units
                if(quantityToProduce > stockPackaging) {
                    console.log('pack.id', pack)
                    msg.packaging[pack.product] = {
                        name: pack.packaging_id.name,
                        msg: 'No hay suficiente stock del packaging',
                        url: `/packaging/edit/${pack.packaging_id.id}`
                    }
                }
            })
        }

        setMsgStockToProduction(msg)

        return msg;
    }

    const handleDecrement = (e, dataAdding, data) => {
        e.preventDefault();
        
        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let haveStock = checksIfThereIsStockAvailableToProduce(data.quantity-1, data)

        if(data.product_type === 'production' && !data.use_stock && haveStock[data.id]) {
            return;
        }

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                quantity: data.quantity-1,
                sub_total: (data.quantity*data.cost_price)-data.cost_price
            }
        }
        
        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const handleIncrement = (e, dataAdding, data) => {
        e.preventDefault();

        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let haveStock = checksIfThereIsStockAvailableToProduce(data.quantity+1, data)

        if(data.product_type === 'production' && !data.use_stock && haveStock[data.id]) {
            return;
        }

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                quantity: data.quantity+1,
                sub_total: (data.quantity*data.cost_price)+data.cost_price
            }
        }
        
        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    const handleUseStock = (e, dataAdding, data, checked) => {        
        const dataChange = dataAdding.filter(e => e.id === data.id)
        const dataFiltred = dataAdding.filter(e => e.id !== data.id)

        let newData;
        if (dataChange) {
            newData = {
                ...data,
                use_stock: checked,
                quantity: 0
            }
        }
        
        setDataAdding([
            ...dataFiltred,
            newData,
        ])
    }

    dataAdding.sort(function (a, b) {
        return a.id - b.id;
    })

    return (
        <div className='table-incomes-sell'>
            {
                (dataAdding?.length <= 0)?null:(
                    <>
                        <Table responsive className="align-middle">
                            <thead>
                                <tr>
                                    {
                                        window.innerWidth > 991 ? (
                                            null
                                        ) : 
                                            <>
                                                <th>Producto</th>
                                                <th>Cantidad</th>
                                                <th>Descuento</th>
                                                <th>Total</th>
                                            </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataAdding && dataAdding?.map((data) => {
                                        return (
                                            <>
                                                <tr key={data.id}>
                                                    <td className='pl-0 pr-3 pt-4 pb-0 w-100'>
                                                        {data.name}
                                                    </td>
                                                    <td className='pl-3 pr-3 pt-4 pb-0'>  
                                                        <div style={{marginTop: -27}}>
                                                            <label className="d-flex" style={{fontSize: '.7rem', marginLeft: 2}}>
                                                                {data.product_type === 'production' ?
                                                                <span className="d-flex">
                                                                    Usar stock
                                                                    <input type="checkbox" className="ml-1" checked={data.use_stock} onChange={(e) => handleUseStock(e, dataAdding, data, e.target.checked)}/>
                                                                </span>
                                                                : 
                                                                    <span className="d-flex">
                                                                        &nbsp;
                                                                        <input type="checkbox" className="ml-1" style={{visibility: 'hidden'}}/>
                                                                    </span>
                                                                }
                                                            </label>
                                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                                <button className="btn btn-light btn-height br-btn-t" style={{borderColor: '#ced4da'}} disabled={(data.quantity <= 0)?"disabled":""} onClick={(e)=>handleDecrement(e, dataAdding, data)}>-</button>
                                                                <button className="btn btn-light btn-height br-btn-t" style={{borderColor: '#ced4da'}} disabled>{data.quantity}</button>
                                                                <button className="btn btn-light btn-height br-btn-t" style={{borderColor: '#ced4da'}} disabled={(data.use_stock && data.quantity >= data.available_quantity) ? "disabled" : ""} onClick={(e)=>handleIncrement(e, dataAdding, data)}>+</button>
                                                            </ButtonGroup>
                                                        </div>

                                                    </td>
                                                    {
                                                        window.innerWidth < 992 ? (
                                                            null
                                                        ) : (
                                                            <>
                                                                <td className='pl-3 pr-3 pt-4 pb-0'>
                                                                    ${
                                                                        parseFloat(data.sale_price).toFixed(2)
                                                                    }
                                                                </td>
                                                            </>
                                                        )
                                                    }
                                                    <td className='pl-3 pr-3 pt-4 pb-0' style={{width: 150}}>
                                                        <div style={{marginTop: -30}}>
                                                            <label htmlFor="floatingSelect" style={{fontSize: '.7rem', marginLeft: 2}}>Descuento a realizar</label>
                                                            <div className='d-flex'>
                                                                <div className='w-50'>
                                                                    <input
                                                                        type='number'
                                                                        name={'details'}
                                                                        className={"d-flex br-btn-t resize-none border-radius form-control"}
                                                                        style={{
                                                                            width: '100%', 
                                                                            display:'flex', 
                                                                            flexDirection:'column', 
                                                                            height:'100%',
                                                                            border:'1px solid #d8d6de',
                                                                            borderTopLeftRadius: '.25rem',
                                                                            borderBottomLeftRadius: '.25rem',
                                                                            borderTopRightRadius: 0,
                                                                            borderBottomRightRadius: 0,
                                                                            outline:'none',
                                                                        }}
                                                                        value={data.value_discount}
                                                                        onChange={(e)=>handleValueDiscount(e, dataAdding, data)}
                                                                    />
                                                                </div>
                                                                <div className='w-50'>
                                                                    <select
                                                                        onChange={(e)=>handleTypeDiscount(e, dataAdding, data)}
                                                                        value={data.type_discount}
                                                                        className={
                                                                            "custom-select form-floating"
                                                                        }
                                                                        style={{
                                                                            width: '100%', 
                                                                            height: '100%', 
                                                                            color:'black',
                                                                            borderLeft: 'none',
                                                                            borderTopLeftRadius: 0,
                                                                            borderBottomLeftRadius: 0,
                                                                            borderTopRightRadius: '.25rem',
                                                                            borderBottomRightRadius: '.25rem',
                                                                        }}
                                                                    >
                                                                        <option value={'$'}>$</option>
                                                                        <option value={'%'}>%</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='pl-3 pr-0 pt-4 pb-0'>
                                                        <button type="submit" className="btn btn-height-1 color-black" onClick={(e)=>handleDeleteProduct(e, data.id)}>
                                                            <DeleteIcon className="color-primary-light" />
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className='pl-0 pt-0'>
                                                        {msgStockToProduction.supplies[data.id] ?
                                                            <p className='text-primary mb-0'><small>{msgStockToProduction.supplies[data.id].msg} <Link to={msgStockToProduction.supplies[data.id].url} className="fw-bold">{msgStockToProduction.supplies[data.id].name}</Link></small></p>
                                                        : null}
                                                        {msgStockToProduction.packaging[data.id] ?
                                                            <p className='text-primary mb-0'><small>{msgStockToProduction.packaging[data.id].msg} <Link to={msgStockToProduction.packaging[data.id].url} className="fw-bold">{msgStockToProduction.packaging[data.id].name}</Link></small></p>
                                                        : null}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </>
                )
            }
        </div>
    )
}
