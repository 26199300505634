import React, { useEffect, useState } from 'react'
import ModalComponent from '../component/Modal';
import TableResponsive from '../component/Table';
import { useDispatch } from 'react-redux';
import { deleteAllProvider, deleteProvider, getPayUser, getProviders } from '../redux/action';

export default function Vendors() {
    const [search, setSearch] = useState('');
    const [id, setId] = useState();
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const [page, setPage] = useState(1);
    const [reload, setReload] = useState(false);
    const [modal, setModal] = useState(false);
    const [deleteAll, setDeleteAll] = useState();
    const [modalDelete, setModalDelete] = useState(false)
    const [disabled, setDisabled] = React.useState(false);

    useEffect(() => {
        // dispatch(getProviders())
        dispatch(getPayUser())
        if(localStorage.search_vendors) {
            setSearch(localStorage.search_vendors)
        }
    }, [dispatch])

    useEffect(() => {
        localStorage.search_vendors = search

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        
        fetch(`${process.env.REACT_APP_URL_API}/api/providers/?page=${page}${search ? '&search=' + search : ''}`, {
            method: 'GET',
            headers: myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                if(json.detail) {
                    setPage(page-1)
                } else {
                    setData(json)
                }
            })       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, disabled, page, search]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        localStorage.search_vendors = e.target.value

        return (
            fetch(`${process.env.REACT_APP_URL_API}/api/providers/?search=${localStorage.search_vendors}`, {
                method: 'GET',
                headers: myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    setData(json)
                })
        )
    }

    const onDelete = (e) => {
        // setDisabled(true);
        // setTimeout(() => {
        //     setDisabled(false);
        // }, 1500)
        // dispatch(deleteProvider(id))
        // setReload(!reload)

        onDeleteAll(e)
    }


    const onDeleteAll = (e) => {
        e.preventDefault();

        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')

        dispatch(deleteAllProvider(deleteAll))

        setReload(!reload)
        setDeleteAll([])
    }

    const pageData = (e, url) => {
        e.preventDefault();
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 1500)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        fetch(`${url}`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: "PAGE_PROVIDER",
                    payload: json
                })
            })
    }

    return (
        <>
            {
                modalDelete ? (
                    <ModalComponent
                        open={modalDelete}
                        title={deleteAll && deleteAll.length === 1 ? "Eliminar proveedor" : "Eliminar proveedores"}
                        body={deleteAll && deleteAll.length === 1 ? "¿Esta segura de eliminar este proveedor?" : `¿Esta segura de eliminar ${deleteAll.length} proveedores?`}
                        setModal={setModalDelete}
                        onDelete={onDeleteAll}
                    />
                ) : null
            }
            {
                modal ? (
                    <ModalComponent
                        open={modal}
                        title={"Eliminar proveedor"}
                        body={"¿Esta segura de eliminar este proveedor?"}
                        setModal={setModal}
                        onDelete={onDelete}
                    />
                ) : null
            }
            <TableResponsive
                data={data}
                search={search}
                setSearch={setSearch}
                handleSubmit={handleSubmit}
                toCreate={"/vendors/create"}
                toEdit={'/vendors/edit'}
                modal={modal}
                setModal={setModal}
                setId={(e) => setDeleteAll([String(e)])}
                page={page}
                setPage={setPage}
                next={data?.next}
                previous={data?.previous}
                pageData={pageData}
                reload={reload}
                setReload={setReload}
                id={id}
                setDeleteAll={setDeleteAll}
                setModalDelete={setModalDelete}
                disabled={disabled}
            />
        </>
    )
}