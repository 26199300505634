export function incomesSum(arr) {
    let total = 0;
    arr.map(x => total = total + parseFloat(x.amount))
    return total
}

export function withdrawalsSum(arr) {
    let total = 0;
    arr.map(x => total = total + parseFloat(x.amount))
    return total
}

export function gastosPersonales(state) {
    let total = 0
    for (const x in state) {
        if (x.includes("gastos_personales") || x.includes("transporte_privado") || x.includes("transporte_publico") || x.includes("educacion") || x.includes("consumo") || x.includes("extras")) {
            if (state[x] && !isNaN(parseFloat(state[x])) && !x.includes('_label')) {
                if (state[x].includes('.')) {
                    total = total + parseFloat(state[x]);
                } else {
                    total = total + parseFloat(state[x]);
                }
            }
        }
    }
    return total
}

export function gastosTotales(state) {
    let total = parseFloat(state.sueldo_final)
    let sueldo_adicional = parseFloat(state.sueldo_adicional)
    for (const x in state) {
        if (x.includes("gastos_comerciales")) {
            if (state[x]) {
                total = total + sueldo_adicional + parseFloat(state[x]);
            }
        }
    }
    return total
}

export function gastosComerciales(state) {
    let total = 0
    for (const x in state) {
        if (x.includes("gastos_comerciales")) {
            if (state[x]) {
                total = total + parseFloat(state[x]);
            }
        }
    }
    return total
}


export function priceSale(obj) {
    const priceCost = !obj.cost_price ? 0 : parseFloat(obj.cost_price);
    const costPackaging = !obj.packaging_cost ? 0 : parseFloat(obj.packaging_cost);
    const marginSucharge = !obj.margin_surcharge ? 0 : parseFloat(obj.margin_surcharge);

    return (priceCost + costPackaging) + ((priceCost + costPackaging) * marginSucharge)
}

export function unitsSale(obj) {
    const minimalSale = !obj.minimal_sale ? 0 : parseFloat(obj.minimal_sale);
    const countPriceSale = !obj.sale_price ? 1 : obj.sale_price;

    return minimalSale / countPriceSale
}

export function marginContribution(obj) {
    const priceCost = !obj.cost_price ? 0 : parseFloat(obj.cost_price);
    const costPackaging = !obj.packaging_cost ? 0 : parseFloat(obj.packaging_cost);
    const countPriceSale = !obj.sale_price ? 0 : parseFloat(obj.sale_price);

    return countPriceSale - (priceCost + costPackaging)
}

export function costStockActual(obj) {
    const unitsActualStock = !obj.current_stock_in_units ? 0 : parseFloat(obj.current_stock_in_units);
    const priceCost = !obj.cost_price ? 0 : parseFloat(obj.cost_price);

    return unitsActualStock * priceCost
}

export function costStockActualSupplies(obj) {
    const unitsActualStock = !obj.current_stock_in_units ? 0 : parseFloat(obj.current_stock_in_units);
    const priceCost = !obj.cost_unit ? 0 : parseFloat(obj.cost_unit);

    return unitsActualStock * priceCost
}

export function saleStockActual(obj) {
    const unitsActualStock = !obj.current_stock_in_units ? 0 : parseFloat(obj.current_stock_in_units);
    const countPriceSale = !obj.sale_price ? 0 : parseFloat(obj.sale_price);
    
    return unitsActualStock * countPriceSale
}


export function unitsReplenishStock(obj) {
    const idealStock = !obj.ideal_stock_in_units ? 0 : parseFloat(obj.ideal_stock_in_units);
    const unitsActualStock = !obj.current_stock_in_units ? 0 : parseFloat(obj.current_stock_in_units);

    return idealStock - unitsActualStock
}

export function costReplenishStock(obj) {
    const priceCost = !obj.cost_unit ? 0 : parseFloat(obj.cost_unit);
    const countUnitsReplenishStock = unitsReplenishStock(obj) ? unitsReplenishStock(obj) : 0;

    return priceCost * countUnitsReplenishStock
}

export function saleReplenishStock(obj) {
    const countUnitsReplenishStock = unitsReplenishStock(obj) ? unitsReplenishStock(obj) : 0;
    const countPriceSale = !obj.sale_price ? 0 : obj.sale_price;

    return countUnitsReplenishStock * countPriceSale
}

export function priceSaleProductionWithoutTax(obj, arr) {
    const margin_surcharge = !obj.margin_surcharge ? 0 : obj.margin_surcharge;
    const percentage = margin_surcharge

    return ((percentage / 100) * priceCostProduction(obj, arr)) + priceCostProduction(obj, arr)
}

export function priceSaleProduction(obj, arr) {
    const margin_surcharge = !obj.margin_surcharge ? 0 : obj.margin_surcharge;
    const tax = obj.tax;
    const percentage = margin_surcharge + tax

    return ((percentage / 100) * priceCostProduction(obj, arr)) + priceCostProduction(obj, arr)
}

export function priceCostProduction(obj, arr) {
    const packaging_cost = !obj.amount_packaging ? 0 : obj.amount_packaging
    const workforce_cost = !obj.workforce_cost ? 0 : obj.workforce_cost
    let total = 0

    arr.length && arr.map(el => {
        return total = total + parseFloat(el.cost_product)
    })

    return (total + packaging_cost + workforce_cost) / obj.quantity_per_recipe
}

export function priceRetails(obj) {
    const cost_price = !obj.cost_price ? 0 : parseFloat(obj.cost_price)
    const surcharge_wholesale = !obj.surcharge_wholesale ? 0 : parseFloat(obj.surcharge_wholesale)
    const result = cost_price + (cost_price * (surcharge_wholesale / 100))

    return result.toFixed(2)
}


export function totalPackaging(arr) {
    let total = 0

    arr.length && arr.map(pack => {
        const amount = (!pack.amount ? 0 : parseFloat(pack.amount)) * pack.quantity_group
        return total = total + amount
    })

    return total
}

export function subtotalWorkforce(groupWorkforce) {
    let subtotalWorkforce = 0;
    groupWorkforce?.forEach(element => {
        const sellPrice = element.cost_unit;
        const quantity = element.quantity;

        subtotalWorkforce += sellPrice*quantity
    });
    return subtotalWorkforce;
}