import React, { useState } from 'react';
import FormVendors from '../component/FormVendors';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function CreateVendor({setAddVendors, setId}) {
    const history = useHistory();
    const [disabled, setDisabled] = useState(false)
    const [state, setState] = useState({
        name: '',
        material: '',
        address: '',
        neighborhood: '',
        city: '',
        telephone: '',
        method_shipping: '',
        method_payment: '',
        method_order: '',
        details: ''
    })

    const [errors, setErrors] = useState({
        name: {
            bol: false,
            msg: ''
        },
        material: {
            bol: false,
            msg: ''
        },
        address: {
            bol: false,
            msg: ''
        },
        neighborhood: {
            bol: false,
            msg: ''
        },
        city: {
            bol: false,
            msg: ''
        },
        telephone: {
            bol: false,
            msg: ''
        },
        method_shipping: {
            bol: false,
            msg: ''
        },
        method_payment: {
            bol: false,
            msg: ''
        },
        method_order: {
            bol: false,
            msg: ''
        }
    })

    const onSubmit = (e) => {
        e.preventDefault();

        const err = {}

        Object.keys(state).filter(x => x !== "details").map(x => {
            if (x === "name") {
                if (!state[x]) {
                    return err[x] = {
                        bol: true,
                        msg: 'Este campo no puede estar vacío'
                    };
                } else {
                    return err[x] = {
                        bol: false,
                        msg: ''
                    };
                }
            } else {
                return err[x] = {
                    bol: false,
                    msg: ''
                };
            }
        })

        setErrors(err);

        if (Object.values(err).filter(x => x.bol === true).length === 0) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 1500)
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
            myHeaders.append("Content-Type", 'application/json')
            fetch(`${process.env.REACT_APP_URL_API}/api/orders/?search=${state.name}`, {
                'method': "GET",
                'headers': myHeaders
            })
                .then(resp => resp.json())
                .then(json => {
                    // Verifico si el nombre ya existe
                    if(!json.results.filter(x => x.name === state.name).length) {
                        fetch(`${process.env.REACT_APP_URL_API}/api/providers/`, {
                            'method': "POST",
                            'body': JSON.stringify(state),
                            'headers': myHeaders
                        })
                            .then(resp => resp.json())
                            .then(json => {
                                if(json?.id) {
                                    if(history.location.pathname.includes('/vendors')) {
                                        toast.success('Proveedor creado exitosamente', {
                                            className: "Toastify__toast--success"
                                        })
                                        history.push(`/vendors`)
                                    } else {
                                        setAddVendors(false)
                                        toast.success('Proveedor creado exitosamente', {
                                            className: "Toastify__toast--success"
                                        })
                                        setId(json.id)
                                    }
                                } else{
                                    toast.success(json.name, {
                                        className: "Toastify__toast--error"
                                    })
                                }
                            })
                    } else {
                        toast.error('Ya existe un proveedor con este nombre', {
                            className: "Toastify__toast--error"
                        })
                    }
                })
        }
    }

    return (
        <FormVendors 
            title={"Agregar proveedor"} 
            state={state} 
            setState={setState} 
            errors={errors} 
            back={'/vendors'}
            setAddVendors={setAddVendors}
            onSubmit={onSubmit}
            disabled={disabled}
        />
    )
}